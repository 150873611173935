import { Col, Row, Spin } from 'antd';
import styled from 'styled-components';
import { useScreenAnimation } from '../Hooks';
import { ScrollableScreenContainer, SwipeBackContainer, TransactionsList } from '../Components';
import { getUpcomingTransactions } from '../Utils';
import { motion } from 'framer-motion';
import { useTransactionsContext } from '../Contexts';

export const PaymentsListPage: React.FC = () => {
  const { allTransactions, isLoading } = useTransactionsContext();

  const upcomingPayments = getUpcomingTransactions(allTransactions, 'Payment');

  const animation = useScreenAnimation();

  return (
    <StyledAnimatedContainer {...animation}>
      <SwipeBackContainer>
        <ScrollableScreenContainer smallPadding>
          <StyledPaymentsListPage>
            {isLoading ? (
              <Spin />
            ) : (
              <Col span={24}>
                <TransactionsList transactions={upcomingPayments} upcoming showDue />
              </Col>
            )}
          </StyledPaymentsListPage>
        </ScrollableScreenContainer>
      </SwipeBackContainer>
    </StyledAnimatedContainer>
  );
};

const StyledPaymentsListPage = styled(Row)`
  margin: 0px !important;
  padding: 0px 6px;
`;
const StyledAnimatedContainer = styled(motion.div)`
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
`;

export default PaymentsListPage;
