import React, { useContext, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  UserCredential,
  User,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from "@firebase/auth";
import { auth } from "../Api/firebase";

interface AuthProps {
  currentUser: User | null;
  login: (email: string, password: string) => Promise<UserCredential>;
  rememberMeLogin: (email: string, password: string) => Promise<UserCredential>;
  failed: boolean;
  loginFailSetter: (failed: boolean) => void;
  logout: () => Promise<void>;
}

const AuthContext = React.createContext<AuthProps>({} as AuthProps);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [failedLogin, setFailedLogin] = useState(false);
  const login = async (email: string, password: string) => {
    return setPersistence(auth, browserSessionPersistence).then(() => {
      return signInWithEmailAndPassword(auth, email, password);
    });
  };

  const rememberMeLogin = async (email: string, password: string) => {
    return setPersistence(auth, browserLocalPersistence).then(() => {
      return signInWithEmailAndPassword(auth, email, password);
    });
  };

  const logout = async () => auth.signOut();

  const loginFailSetter = (failed: boolean) => setFailedLogin(failed);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
      setFailedLogin(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    failed: failedLogin,
    loginFailSetter,
    logout,
    rememberMeLogin,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
