import styled from 'styled-components';
import { theme } from '../Theme';

type Color = 'default' | 'primary' | 'active';
interface Props {
  color?: Color;
}

const FillColors: Record<Color, string> = {
  active: theme.colors.primary,
  default: '#F4F5F9',
  primary: theme.colors.primaryShades['primary-1'],
};

const ContrastColors: Record<Color, string> = {
  active: '#fff',
  default: '#020B36',
  primary: theme.colors.primary,
};

export const IncomeStale: React.FC<Props> = ({ color = 'default' }) => {
  const fillColor = FillColors[color];
  const contrastColor = ContrastColors[color];
  return (
    <StyledIncomeStale>
      <svg width='61' height='61' viewBox='0 0 61 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.977036' y='0.42041' width='60' height='60' rx='16' fill={fillColor} />
        <path
          d='M30.977 14.6948C22.2902 14.6948 15.24 21.7398 15.24 30.4203C15.24 39.1008 22.2902 46.1458 30.977 46.1458C39.6639 46.1458 46.7141 39.1008 46.7141 30.4203C46.7141 21.7398 39.6639 14.6948 30.977 14.6948ZM30.977 43.0007C24.037 43.0007 18.3874 37.3553 18.3874 30.4203C18.3874 23.4854 24.037 17.8399 30.977 17.8399C37.9171 17.8399 43.5667 23.4854 43.5667 30.4203C43.5667 37.3553 37.9171 43.0007 30.977 43.0007ZM31.4649 29.0679C28.6794 28.3603 27.7824 27.5897 27.7824 26.4418C27.7824 25.1208 29.0256 24.193 31.0872 24.193C33.2589 24.193 34.0772 25.2309 34.1402 26.772H36.8312C36.7525 24.6648 35.4621 22.7306 32.9127 22.1015V19.4125H29.246V22.0701C26.8697 22.5733 24.9655 24.1144 24.9655 26.4889C24.9655 29.3038 27.3103 30.7191 30.7252 31.5368C33.794 32.2602 34.4077 33.3453 34.4077 34.4775C34.4077 35.311 33.794 36.6633 31.1029 36.6633C28.585 36.6633 27.5936 35.5311 27.4519 34.0844H24.7452C24.9025 36.7577 26.8854 38.2673 29.246 38.7548V41.4282H32.9284V38.802C35.3205 38.346 37.2089 36.9779 37.2246 34.4461C37.2089 30.9864 34.2346 29.7913 31.4649 29.0679Z'
          fill={contrastColor}
        />
      </svg>
    </StyledIncomeStale>
  );
};

const StyledIncomeStale = styled.div``;
