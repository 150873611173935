import { Col, Row, Typography } from 'antd';
import { Card } from 'antd-mobile';
import styled from 'styled-components';
import { useRouteContext } from '../../Contexts';
import { CommonIcons, theme } from '../../Design';
import { AppRoutes } from '../../Model';

interface Props {
  title: string;
  redirectTo: AppRoutes;
}

export const RedirectCard: React.FC<Props> = ({ redirectTo, title }) => {
  const { ForwardIcon, ListIcon } = CommonIcons;
  const { handleNavigation } = useRouteContext();

  const handleNavigate = () => handleNavigation(redirectTo);

  return (
    <StyledRedirectCard onClick={handleNavigate}>
      <Row align='middle'>
        <StyledTitleCol flex='auto'>
          <ListIcon />
          <Typography.Title level={4}>{title}</Typography.Title>
        </StyledTitleCol>
        <Col flex='36px'>
          <ForwardIcon />
        </Col>
      </Row>
    </StyledRedirectCard>
  );
};

const StyledRedirectCard = styled(Card)`
  h4 {
    color: ${theme.colors.primary}!important;
    margin: 0px;
  }
  svg {
    color: ${theme.colors.primary};
    font-size: 36px;
    margin-bottom: -4px;
  }
  :active {
    opacity: 0.5;
  }
`;

const StyledTitleCol = styled(Col)`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;
