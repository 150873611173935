import { Col, Row } from 'antd';
import styled from 'styled-components';
import { useScreenAnimation } from '../Hooks';
import { ScrollableScreenContainer, TopCategories, TotalPayments, TransactionsList } from '../Components';
import { getPastTransactions, getUpcomingTransactions } from '../Utils';
import { CardWithTitle } from '../Components/Cards';
import { motion } from 'framer-motion';
import { AppRoutes } from '../Model';
import { useTransactionsContext } from '../Contexts';

export const PaymentsPage: React.FC = () => {
  const { allTransactions, isLoading } = useTransactionsContext();

  const upcomingPayments = getUpcomingTransactions(allTransactions, 'Payment');
  const pastPayments = getPastTransactions(allTransactions, 'Payment');

  const animation = useScreenAnimation();

  return (
    <StyledAnimatedContainer {...animation}>
      <ScrollableScreenContainer>
        <StyledPaymentsPage gutter={[16, 24]}>
          <Col span={24}>
            <TotalPayments upcomingPayments={upcomingPayments} pastPayments={pastPayments} isLoading={isLoading} />
          </Col>
          <Col span={24}>
            <TopCategories />
          </Col>
          <Col style={{ marginTop: -32 }} span={24}>
            <CardWithTitle redirectUrl={AppRoutes.SpendingsList} title='Latest Spendings' isLoading={isLoading}>
              <TransactionsList transactions={pastPayments} redirectUrl={`${AppRoutes.Payments}/spendings`} limit />
            </CardWithTitle>
          </Col>
          {Boolean(upcomingPayments.length) && (
            <Col span={24}>
              <CardWithTitle redirectUrl={AppRoutes.UpcomingPaymentsList} title='Upcoming' isLoading={isLoading}>
                <TransactionsList showDue upcoming transactions={upcomingPayments} redirectUrl={`${AppRoutes.Payments}`} limit />
              </CardWithTitle>
            </Col>
          )}
        </StyledPaymentsPage>
      </ScrollableScreenContainer>
    </StyledAnimatedContainer>
  );
};

const StyledPaymentsPage = styled(Row)`
  margin: 0px !important;
`;
const StyledAnimatedContainer = styled(motion.div)`
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
`;

export default PaymentsPage;
