import { AnimationProps } from 'framer-motion';
import { useRouteContext } from '../Contexts';

export const useScreenAnimation = () => {
  const { animationDirection } = useRouteContext();

  const animation: AnimationProps =
    animationDirection === 'left'
      ? {
          initial: { x: window.innerWidth, WebkitTransform: 'translateZ(0px)', opacity: 0 },
          animate: { x: 0, opacity: 1 },
          exit: { x: -window.innerWidth, position: 'absolute', opacity: 0 },
          transition: { ease: 'easeInOut', duration: 0.2, delay: 0.1 },
        }
      : {
          initial: { x: -window.innerWidth, WebkitTransform: 'translateZ(0px)', opacity: 0 },
          animate: { x: 0, opacity: 1 },
          exit: { x: window.innerWidth, position: 'absolute', opacity: 0 },
          transition: { ease: 'easeInOut', duration: 0.2, delay: 0.1 },
        };

  return animation;
};
