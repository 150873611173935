import { Col, Row, Typography } from 'antd';
import { Button, Card, Dialog } from 'antd-mobile';
import styled from 'styled-components';
import { Playground } from '../../Model';
import { CommonIcons, theme } from '../../Design';

interface Props {
  playground: Playground;
  onClick?: () => void;
  onDelete: (id: string) => void;
}

const { DeleteOutlineIcon } = CommonIcons;

export const PlaygroundCard: React.FC<Props> = ({ playground, onClick, onDelete }) => {
  const handleOnDelete = () => {
    const { name, id } = playground;
    Dialog.show({
      content: (
        <StyledDeleteModalContent>
          <div>
            <Typography.Title level={5}>Confirm delete</Typography.Title>
          </div>
          <Typography.Text>{name}</Typography.Text>
        </StyledDeleteModalContent>
      ),
      closeOnMaskClick: true,
      closeOnAction: true,
      actions: [
        [
          { key: 'cancel', text: 'Cancel' },
          {
            key: 'delete',
            text: 'Delete',
            danger: true,
            onClick: () => {
              onDelete(id);
            },
          },
        ],
      ],
    });
  };

  return (
    <StyledPlaygroundCard onClick={onClick}>
      <Row align='middle' justify='space-between'>
        <Col>
          <Typography.Title level={4}>{playground.name}</Typography.Title>
          <Typography.Text type='secondary'>Created on: {playground?.createdAt?.toDate().toLocaleString()}</Typography.Text>
        </Col>
        <Col>
          <Button
            shape='rounded'
            onClick={e => {
              e.stopPropagation();
              handleOnDelete();
            }}
          >
            <DeleteOutlineIcon size={24} style={{ marginTop: 4 }} />
          </Button>
        </Col>
      </Row>
    </StyledPlaygroundCard>
  );
};

const StyledPlaygroundCard = styled(Card)`
  padding: 4px 16px 4px 16px;
  h4 {
    margin-bottom: 2px;
  }
  .adm-button {
    svg {
      color: ${theme.colors.primary};
    }
    border: none;
    background-color: ${theme.colors.primaryShades['primary-1']};
  }
  :active {
    cursor: pointer;
    background-color: ${theme.colors.primaryShades['primary-1']};
  }
`;

const StyledDeleteModalContent = styled.div`
  text-align: center;
`;
