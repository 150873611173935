import styled from 'styled-components';

interface Props {}

export const IncomeDown: React.FC<Props> = () => {
  return (
    <StyledIncomeDown>
      <svg width='61' height='61' viewBox='0 0 61 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.10051' y='0.803711' width='60' height='60' rx='16' fill='#FFF2F0' />
        <path
          d='M30.1005 15.0781C21.4137 15.0781 14.3635 22.1231 14.3635 30.8036C14.3635 39.4841 21.4137 46.5291 30.1005 46.5291C38.7873 46.5291 45.8375 39.4841 45.8375 30.8036C45.8375 22.1231 38.7873 15.0781 30.1005 15.0781ZM30.1005 43.384C23.1605 43.384 17.5109 37.7386 17.5109 30.8036C17.5109 23.8687 23.1605 18.2232 30.1005 18.2232C37.0405 18.2232 42.6901 23.8687 42.6901 30.8036C42.6901 37.7386 37.0405 43.384 30.1005 43.384ZM30.5884 29.4512C27.8029 28.7436 26.9059 27.973 26.9059 26.8251C26.9059 25.5041 28.1491 24.5763 30.2107 24.5763C32.3824 24.5763 33.2007 25.6142 33.2637 27.1553H35.9547C35.876 25.0481 34.5856 23.1139 32.0362 22.4848V19.7958H28.3694V22.4534C25.9931 22.9566 24.089 24.4977 24.089 26.8722C24.089 29.6871 26.4338 31.1024 29.8487 31.9201C32.9174 32.6435 33.5312 33.7286 33.5312 34.8608C33.5312 35.6943 32.9174 37.0466 30.2264 37.0466C27.7085 37.0466 26.7171 35.9144 26.5754 34.4677H23.8687C24.026 37.141 26.0089 38.6506 28.3694 39.1381V41.8115H32.0519V39.1853C34.4439 38.7293 36.3324 37.3612 36.3481 34.8294C36.3324 31.3697 33.3581 30.1746 30.5884 29.4512Z'
          fill='#DF2E16'
        />
        <path d='M19.8166 20.8477L40.7431 41.228' stroke='#E03729' strokeWidth='3' />
        <path
          d='M30.1005 15.0781C21.4137 15.0781 14.3635 22.1231 14.3635 30.8036C14.3635 39.4841 21.4137 46.5291 30.1005 46.5291C38.7873 46.5291 45.8375 39.4841 45.8375 30.8036C45.8375 22.1231 38.7873 15.0781 30.1005 15.0781ZM30.1005 43.384C23.1605 43.384 17.5109 37.7386 17.5109 30.8036C17.5109 23.8687 23.1605 18.2232 30.1005 18.2232C37.0405 18.2232 42.6901 23.8687 42.6901 30.8036C42.6901 37.7386 37.0405 43.384 30.1005 43.384ZM30.5884 29.4512C27.8029 28.7436 26.9059 27.973 26.9059 26.8251C26.9059 25.5041 28.1491 24.5763 30.2107 24.5763C32.3824 24.5763 33.2007 25.6142 33.2637 27.1553H35.9547C35.876 25.0481 34.5856 23.1139 32.0362 22.4848V19.7958H28.3694V22.4534C25.9931 22.9566 24.089 24.4977 24.089 26.8722C24.089 29.6871 26.4338 31.1024 29.8487 31.9201C32.9174 32.6435 33.5312 33.7286 33.5312 34.8608C33.5312 35.6943 32.9174 37.0466 30.2264 37.0466C27.7085 37.0466 26.7171 35.9144 26.5754 34.4677H23.8687C24.026 37.141 26.0089 38.6506 28.3694 39.1381V41.8115H32.0519V39.1853C34.4439 38.7293 36.3324 37.3612 36.3481 34.8294C36.3324 31.3697 33.3581 30.1746 30.5884 29.4512Z'
          fill='#DF2E16'
        />
        <path d='M19.8166 20.8477L40.7431 41.228' stroke='#E03729' strokeWidth='3' />
      </svg>
    </StyledIncomeDown>
  );
};

const StyledIncomeDown = styled.div``;
