import { Col, Row, Typography } from 'antd';
import { Loading } from 'antd-mobile';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, Tooltip } from 'recharts';
import styled from 'styled-components';
import { ScrollableScreenContainer, SwipeBackContainer } from '../Components';
import { useTransactionsContext } from '../Contexts';
import { useScreenAnimation, useTransactionsList } from '../Hooks';
import { Transaction } from '../Model';
import { getCurrentMonth, getCurrentYear, getTransactionsTotal, getYearMonthDateRange, MoneyFormatter } from '../Utils';

interface Props {}

interface TransactionsByType {
  income?: Transaction[];
  payments?: Transaction[];
}

interface BarGraphData {
  date: string;
  payments: number;
  income: number;
}

const TooltipLabel = ({
  date,
  label,
  amount,
  noDate,
  color,
}: {
  date: string;
  label: string;
  amount: number;
  noDate?: boolean;
  color: string;
}) => (
  <StyledTooltipLabel>
    <Typography.Title level={4}>{label}</Typography.Title>
    <Typography.Title style={{ color: color }} level={2}>
      {MoneyFormatter.format(amount)}
    </Typography.Title>
    {!noDate && (
      <Typography.Text style={{ fontSize: 14 }} type='secondary'>
        {date}
      </Typography.Text>
    )}
  </StyledTooltipLabel>
);

export const Insights: React.FC<Props> = () => {
  const animation = useScreenAnimation();
  const dateRange = getYearMonthDateRange(getCurrentMonth(), getCurrentYear(), 4);
  const { transactionsList, isLoading } = useTransactionsList({
    type: 'ALL',
    yearMonth: dateRange.startMonth,
    endYearMonth: dateRange.endMonth,
  });
  const { flagsRecurrentTransactions } = useTransactionsContext();
  const filteredRecurrents = flagsRecurrentTransactions.filter(t => t.date.slice(0, 7) === dateRange.endMonth);

  const dataByMonth = [...transactionsList, ...filteredRecurrents].reduce((acc, curr) => {
    const currDate = curr.date.slice(0, 7);

    if (!acc[currDate]) {
      if (curr.type === 'Income') {
        acc = { ...acc, [currDate]: { income: [curr] } as any };
      } else {
        acc = { ...acc, [currDate]: { payments: [curr] } as any };
      }
    } else {
      if (curr.type === 'Income') {
        if (!acc[currDate].income) {
          acc = { ...acc, [currDate]: { ...acc[currDate], income: [curr] } };
        } else {
          acc[currDate].income?.push(curr);
        }
      } else {
        if (!acc[currDate].payments) {
          acc = { ...acc, [currDate]: { ...acc[currDate], payments: [curr] } };
        } else {
          acc[currDate].payments?.push(curr);
        }
      }
    }
    return acc;
  }, {} as Record<string, TransactionsByType>);

  const chartData = Object.keys(dataByMonth).reduce((acc, curr) => {
    const data: BarGraphData = {
      date: curr,
      payments: getTransactionsTotal(dataByMonth[curr].payments || []),
      income: getTransactionsTotal(dataByMonth[curr].income || []),
    };
    return [...acc, data];
  }, [] as BarGraphData[]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <StyledCustomTooltip>
          <Row>
            <Col span={12}>
              <TooltipLabel
                color={payload[0].color}
                amount={payload[0].value}
                date={dayjs(`${label}-01`).format('MMMM YYYY')}
                label='Income'
              />
            </Col>
            <Col span={12}>
              <TooltipLabel
                color={payload[1].color}
                noDate
                amount={payload[1].value}
                date={dayjs(`${label}-01`).format('MMMM YYYY')}
                label='Payments'
              />
            </Col>
          </Row>
        </StyledCustomTooltip>
      );
    }

    return null;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledAnimatedContainer {...animation}>
      <SwipeBackContainer>
        <ScrollableScreenContainer smallPadding>
          <ResponsiveContainer width={window.screen.width - 24} height={350}>
            <BarChart
              width={500}
              height={300}
              data={chartData.reverse()}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='10' />
              <Tooltip cursor={{ fillOpacity: 0.2 }} position={{ x: 0, y: 360 }} content={CustomTooltip} />
              <Legend margin={{ top: 16 }} />
              <XAxis dataKey='date' />
              <Bar radius={[12, 12, 8, 8]} dataKey='income' stackId='b' fill='#13C2C2' />
              <Bar radius={[12, 12, 8, 8]} dataKey='payments' stackId='a' fill='#FF614B' />
            </BarChart>
          </ResponsiveContainer>
          <StyledCustomTooltip style={{ marginTop: 12, height: 110, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography.Text type='secondary'>Touch the graph for more details 🔍</Typography.Text>
          </StyledCustomTooltip>
        </ScrollableScreenContainer>
      </SwipeBackContainer>
    </StyledAnimatedContainer>
  );
};

const StyledAnimatedContainer = styled(motion.div)`
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
`;

const StyledCustomTooltip = styled.div`
  background-color: white;
  width: calc(${window.screen.width}px - 24px);
  padding: 12px;
  -webkit-box-shadow: 1px 5px 14px 2px rgba(0, 0, 0, 0.52);
  box-shadow: 1px 5px 14px 2px rgba(0, 0, 0, 0.06);
  border-radius: 24px;
`;

const StyledTooltipLabel = styled.div`
  .ant-typography,
  h4 {
    margin: 0px !important;
  }
`;
