import styled from 'styled-components';
import { Layout } from 'antd';
import { useMenuItems } from './Menu';
import React, { useState } from 'react';
import { MobileAddDrawer, NavigationItem } from '../';
import { AddButtonIcon } from '../../Design';
import { useLocation } from 'react-router';

export const FOOTER_HEIGHT = 65;

export const Footer = () => {
  const [showAdd, setShowAdd] = useState(false);
  const location = useLocation();

  const { menuItems } = useMenuItems();

  const toggleAdd = () => setShowAdd(prev => !prev);

  const closeAdd = () => setShowAdd(false);

  const playgroundScreensRegex = /\/more\/playgrounds\/*/;
  const isPlaygroundPage = location.pathname.match(playgroundScreensRegex);
  const insightScreenRegex = /\/more\/insights/;
  const isInsightsScreen = location.pathname.match(insightScreenRegex);

  const isNoFooterScreen = isPlaygroundPage || isInsightsScreen;

  if (isNoFooterScreen) return null;

  return (
    <>
      <StyledFooter>
        {menuItems.map((item, i) => {
          return (
            <React.Fragment key={item.key}>
              <NavigationItem item={item} />
              {i === 1 && (
                <StyledAddButtonContainer
                  style={{
                    transform: !showAdd ? ` translateY(-24px) rotateZ(0deg)` : ` translateY(-24px) rotateZ(135deg)`,
                  }}
                  onClick={toggleAdd}
                >
                  <AddButtonIcon />
                </StyledAddButtonContainer>
              )}
            </React.Fragment>
          );
        })}
      </StyledFooter>
      <MobileAddDrawer visible={showAdd} onClose={closeAdd} />
    </>
  );
};

const StyledAddButtonContainer = styled.div`
  border-radius: 50%;
  height: 72px;
  width: 72px;
  box-shadow: 1px 4px 10px 2px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
`;

const StyledFooter = styled(Layout.Footer)`
  background-color: #fff;
  padding: 0px 24px;
  padding-bottom: env(safe-area-inset-bottom);
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-col {
    max-height: 50px;
  }
  svg {
    color: #224fdf;
  }
  height: calc(${`${FOOTER_HEIGHT}px`} + env(safe-area-inset-bottom));
  border-radius: 4px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
