import { MemoryRouter } from 'react-router-dom';
import { MobileLoginCard } from '../Login';
import { RouteProvier, TransactionsProvider, useAuth } from '../../Contexts';
import { ScreenContainer } from '../ScreenContainer';
import { AnimatedRoutes } from './AnimatedRoutes';
import { ScrollProvider } from '../../Contexts';
import { BetaLaunchingSoon } from '../../Pages';
import { useState } from 'react';

export const AppRouter = () => {
  const { currentUser } = useAuth();
  const [showBeta, setShowBeta] = useState(true || !(process.env.REACT_APP_FIREBASE_PROJECT_ID === 'viktoria-dev'));
  if (showBeta) {
    return <BetaLaunchingSoon setShowBeta={setShowBeta} />;
  }
  if (!currentUser) {
    return <MobileLoginCard />;
  }
  return (
    <MemoryRouter initialEntries={['/']}>
      <ScrollProvider>
        <RouteProvier>
          <TransactionsProvider>
            <ScreenContainer>
              <AnimatedRoutes />
            </ScreenContainer>
          </TransactionsProvider>
        </RouteProvier>
      </ScrollProvider>
    </MemoryRouter>
  );
};
