import { theme } from '../../Design';
import { Button, Input, Form } from 'antd-mobile';
import styled from 'styled-components';
import { useState } from 'react';
import { useAuth } from '../../Contexts/AuthContext';
import firebase from 'firebase/compat/app';
import { LoginIllustration } from '../../Design/Illustrations/';
import { useMobileView } from '../../Hooks';
import { useIonToast } from '@ionic/react';

interface LoginFormValues {
  Email: string;
  Password: string;
}

export const MobileLoginCard = () => {
  const { login, loginFailSetter, rememberMeLogin } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [rememberMe] = useState<boolean>(false);
  const [present, dismiss] = useIonToast();
  const { isPWA } = useMobileView();

  const onFinish = async (values: unknown) => {
    const v: LoginFormValues = values as LoginFormValues;

    try {
      loginFailSetter(false);
      setLoading(true);
      if (!rememberMe) {
        await login(v.Email, v.Password);
      } else {
        await rememberMeLogin(v.Email, v.Password);
      }
      setLoading(false);
      dismiss();
    } catch (error) {
      setLoading(false);
      loginFailSetter(true);
      present({
        message: `Wrong credentials', ${(error as firebase.FirebaseError).message}`,
        duration: 3000,
        position: 'top',
        color: 'danger',
      });
    }
  };
  return (
    <StyledLoginContainer $isPWA={isPWA}>
      <StyledLogo $isPWA={isPWA}>
        <LoginIllustration />
        <StyledTitle>Welcome back !</StyledTitle>
      </StyledLogo>
      <StyledMobileLoginCard>
        <Form
          validateMessages={{
            // eslint-disable-next-line no-template-curly-in-string
            required: '${name} is required',
          }}
          onFinish={onFinish}
          name='login'
          footer={
            <div>
              <StyledButton style={{ backgroundColor: theme.colors.primary }} loading={loading} type='submit' size='large' color='primary'>
                Login
              </StyledButton>
              <StyledButton disabled style={{ marginTop: 8 }} size='large' color='default' type='button'>
                Create Account
              </StyledButton>
            </div>
          }
        >
          <Form.Item label='Email Address' rules={[{ required: true }]} name='Email'>
            <StyledInput placeholder='Email' type='email' />
          </Form.Item>
          <Form.Item label='Password' rules={[{ required: true }]} name='Password'>
            <StyledInput autoComplete='current-password' placeholder='Password' type='password' />
          </Form.Item>
          <StyledCheckboxFormItem></StyledCheckboxFormItem>
        </Form>
      </StyledMobileLoginCard>
    </StyledLoginContainer>
  );
};

const StyledLoginContainer = styled.div<{ $isPWA: boolean }>`
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  height: 100vh;
`;

const StyledMobileLoginCard = styled.div`
  margin-top: 15%;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: block;
  padding: 0px 12px 0px 12px;
  .adm-list-body-inner {
    margin: 0;
  }
  .adm-list-default .adm-list-body {
    border: none;
  }
  .adm-form-item-feedback-error {
    text-align: start;
    padding-left: 8px;
  }
  .adm-list-item-content {
    border-top: none;
  }
  .adm-form-item-label {
    margin-bottom: 8px !important;
    margin-left: 4px !important;
    font-weight: 450 !important;
    font-size: 16px !important;
    color: ${theme.colors.primaryShades['primary-9']};
  }
  .adm-form-item-label .adm-form-item-required-asterisk {
    display: none;
  }
`;

const StyledCheckboxFormItem = styled(Form.Item)`
  .adm-list-item-content-main {
    padding-bottom: 0px;
    padding-top: 0px;
    width: 100%;
  }
  .adm-list-item-content {
    width: 100%;
  }
  .adm-form-item-child-inner {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const StyledInput = styled(Input)`
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 36px;
  width: auto;
`;

const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 36px;
`;

const StyledTitle = styled.h3`
  display: flex;
  color: #fff !important;
  font-weight: 600;
  font-size: 36px !important;
  margin: 0px;
  margin-left: 44px;
  margin-top: -8px;
  padding-bottom: 24px;
`;

const StyledLogo = styled.div<{ $isPWA: boolean }>`
  padding-top: env(safe-area-inset-top);
  svg {
    height: 200px;
    width: 170px;
    margin-left: 38px;
  }
  background-color: #224fdf;
  border-radius: 0px 0px 59px 59px;
`;
