import styled from 'styled-components';
import { Form, Input, Row, Col } from 'antd';
import { DATE_FORMAT, Frequency, Transaction } from '../../Model';
import { useState } from 'react';
import { Categories, Category } from '../../Model';
import { EditPageFooter, FrequencyRadios } from '.';
import { CommonIcons } from '../../Design';
import { TransactionTypeRadio } from './TransactionTypeRadio';
import dayjs from 'dayjs';

interface BaseProps {
  onSave: (values: Transaction) => void;
  isLoading: boolean;
  onCancel?: () => void;
}
interface EditProps extends BaseProps {
  initialValues: Transaction;
  mode: 'edit';
}

interface AddProps extends BaseProps {
  mode: 'create';
  initialValues?: never;
}

interface ContentProps extends BaseProps {
  mode: 'create' | 'edit';
  initialValues?: Transaction;
}

type Props = AddProps | EditProps;

const { ChevronDown } = CommonIcons;

const newTransaction: Transaction = {
  amount: 0,
  id: '',
  name: '',
  recurrent: false,
  type: 'Payment',
  category: Category.Other,
  frequency: Frequency.Onetime,
  date: dayjs().format(DATE_FORMAT),
};

export const EditTransactionForm: React.FC<Props> = props => {
  const getInitialValues = props.initialValues || newTransaction;
  return <EditTransactionFormContent {...props} initialValues={getInitialValues} />;
};

export const EditTransactionFormContent: React.FC<ContentProps> = ({ initialValues, onSave, isLoading, mode, onCancel }) => {
  const { IncomeIcon } = CommonIcons;

  const [selectedFrequency, setSelectedFrequency] = useState(initialValues!.frequency);
  const [selectedTransactionType, setSelectedTransactionType] = useState<Transaction['type']>(initialValues?.type || 'Payment');
  const [formState, setFormState] = useState(initialValues);

  const handleFormChange = (e: Record<keyof Transaction, string>) => {
    const key = Object.keys(e)[0] as keyof Transaction;
    if (key === 'amount') {
      setFormState(prevState => ({ ...prevState!, amount: Number(e[key]) }));
    } else {
      setFormState(prevState => ({ ...prevState!, [key]: e[key] }));
    }
  };

  const onFinish = (formValues: any) => {
    if (!initialValues?.frequency) {
      onSave({ ...initialValues, ...(formValues as Transaction), amount: Number(formValues.amount), type: selectedTransactionType });
    } else if (selectedFrequency !== Frequency.Onetime && !initialValues.paid) {
      // AKA creating a recurrent
      onSave({
        ...initialValues,
        ...(formValues as Transaction),
        frequency: selectedFrequency,
        type: selectedTransactionType,
        recurrent: true,
        amount: Number(formValues.amount),
        paid: false,
      });
    } else {
      onSave({ ...initialValues, ...(formValues as Transaction), amount: Number(formValues.amount), type: selectedTransactionType });
    }
  };

  const isDirty = () => {
    if (mode === 'create') {
      return true;
    }
    if (initialValues) {
      const dirty =
        formState?.amount !== initialValues.amount ||
        formState.category !== initialValues.category ||
        formState.name.trim() !== initialValues.name ||
        initialValues.frequency !== selectedFrequency ||
        initialValues.type !== selectedTransactionType ||
        initialValues.date !== formState.date;
      return mode === 'edit' && dirty;
    }
    return true;
  };

  const isOnetimeTransaction = Boolean(initialValues?.id.length && (!initialValues.recurrent || initialValues?.paid));

  return (
    <StyledEditTransactionForm align='middle' justify='center'>
      <Row align='middle' justify='center' gutter={[16, 24]}>
        <Col>
          <TransactionTypeRadio value={selectedTransactionType} onChange={setSelectedTransactionType} />
        </Col>
        {!isOnetimeTransaction && (
          <Col span={24}>
            <FrequencyRadios
              primary
              withOnetime={initialValues?.recurrent === false}
              selectedFrequency={selectedFrequency}
              handleClickFrequency={frequency => setSelectedFrequency(frequency)}
            />
          </Col>
        )}
        <Col span={24}>
          <StyledForm
            name='basic'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete='off'
            onValuesChange={handleFormChange}
          >
            <StyledFormContainer $transactionType={selectedTransactionType}>
              <div>
                <StyledAmountFormItem
                  initialValue={initialValues?.amount || ''}
                  label='How much?'
                  name='amount'
                  rules={[{ required: true, message: 'Amount is required' }]}
                >
                  <Input addonAfter={<IncomeIcon />} placeholder='200.00' type='number' size='large' />
                </StyledAmountFormItem>
                <Form.Item
                  initialValue={initialValues?.name}
                  label='What is it?'
                  name='name'
                  rules={[{ required: true, message: 'Name is required' }]}
                >
                  <Input placeholder='Sold a kidney..' type='search' size='large' />
                </Form.Item>
                <Form.Item initialValue={initialValues?.date ? initialValues?.date : new Date().getTime()} label='When?' name='date'>
                  <StyledDateInput required type='date' />
                </Form.Item>
                {selectedTransactionType !== 'Income' && (
                  <>
                    <Form.Item initialValue={initialValues?.category || Category.Other} name='category' label='Category'>
                      <StyledSelect unselectable='on' value={initialValues?.category} autoFocus={false} style={{ width: '100%' }}>
                        {Categories.map((category, i) => (
                          <option key={category} value={category}>
                            {category}
                          </option>
                        ))}
                      </StyledSelect>
                    </Form.Item>
                    <ChevronDown style={{ float: 'right', transform: 'translateY(-38px) translateX(-12px)', opacity: 0.3 }} />
                  </>
                )}
              </div>
              <div>
                <StyledActionItemForm>
                  <EditPageFooter onCancel={onCancel} disabled={!isDirty()} loading={isLoading} mode={mode} />
                </StyledActionItemForm>
              </div>
            </StyledFormContainer>
          </StyledForm>
        </Col>
      </Row>
    </StyledEditTransactionForm>
  );
};

const StyledFormContainer = styled.div<{ $transactionType: 'Income' | 'Payment' }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50vh;
  @media only screen and (max-height: 775px) {
    height: 40vh;
  }
`;

const StyledAmountFormItem = styled(Form.Item)`
  svg {
    fill: rgba(0, 0, 0, 0.25);
    transform: translateY(3px);
  }
`;

const StyledEditTransactionForm = styled(Row)`
  input {
    border-radius: 36px;
  }
  .ant-input-group-addon {
    border-radius: 0px 36px 36px 0px !important;
  }
  input::-webkit-date-and-time-value {
    text-align: left;
  }

  height: 70%;
  font-weight: 600;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-item {
    margin-bottom: 12px;
  }
  form {
    margin: 0px;
  }
  .ant-input-group-addon {
    background-color: transparent;
  }
`;

const StyledSelect = styled.select`
  font-size: 16px;
  padding: 4px 11px 4px;
  border: 1px solid #d9d9d9;
  background-color: white;
  height: 42px;
  border-radius: 36px;
  -moz-appearance: none; /*Firefox*/
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`;

const StyledForm = styled(Form)`
  margin-top: 24px;
  label {
    font-size: 18px;
  }
  height: 80%;
  padding-bottom: calc(68px + env(safe-area-inset-bottom));
`;

const StyledActionItemForm = styled(Form.Item)``;

const StyledDateInput = styled.input`
  padding: 6.5px 11px;
  background-color: #fff;
  border: 1px solid rgba(217, 217, 217);
  width: 100%;
  text-align: left;
  appearance: textfield !important;
  font-size: 16px;
  ::-webkit-calendar-picker-indicator {
    opacity: 0.3;
  }
  border-radius: 36px;
`;
