import styled from 'styled-components';
import { useScreenAnimation } from '../Hooks';
import { MOBILE_HEADER_HEIGHT_SHRINKED, PlaygroundCard, ScrollableScreenContainer, SwipeBackContainer } from '../Components';
import { motion } from 'framer-motion';
import { AppRoutes } from '../Model';
import { usePlayground } from '../Hooks/usePlayground';
import { Button, Dialog, Empty, Input } from 'antd-mobile';
import { useRouteContext } from '../Contexts';
import { Col, Row, Typography } from 'antd';
import { CommonIcons, CrystalBallIllustration, theme } from '../Design';
import { useState } from 'react';

const { AddIcon } = CommonIcons;

export const Playgrounds: React.FC = () => {
  const animation = useScreenAnimation();
  const { handleNavigation } = useRouteContext();
  const { playgrounds, handleAddPlayground, handleDeletePlayground } = usePlayground();
  const [newProjectName, setNewProjectName] = useState<string>();
  const [isNewProjectModalVisible, setIsNewProjetModalVisible] = useState(false);

  const handleShowPlaygroundModal = () => setIsNewProjetModalVisible(true);
  const handleClosePlaygroundModal = () => {
    setIsNewProjetModalVisible(false);
    setNewProjectName('');
  };

  const addPlayground = (name: string) => {
    handleAddPlayground(name.trim(), { withImport: true, projectName: newProjectName || '' });
    setNewProjectName('');
  };

  const createPlaygroundButton = (withoutButton: boolean) => (
    <StyledButtonRow justify='space-between' align='middle'>
      <Col>
        <Typography.Title level={3}>My Projects</Typography.Title>
      </Col>
      <Col>
        {!withoutButton && (
          <Button
            style={{ background: theme.colors.primaryShades['primary-6'], borderRadius: 24 }}
            color={'primary'}
            onClick={handleShowPlaygroundModal}
          >
            <AddIcon size={24} /> Create
          </Button>
        )}
      </Col>
    </StyledButtonRow>
  );

  return (
    <SwipeBackContainer>
      <StyledAnimatedContainer {...animation}>
        {Boolean(playgrounds.length) && <StyledFixedButtonRow>{createPlaygroundButton(!playgrounds.length)}</StyledFixedButtonRow>}
        <ScrollableScreenContainer noTopPadding smallPadding>
          {playgrounds.length ? (
            <>
              <Row gutter={[0, 12]}>
                {playgrounds.map(playground => (
                  <Col key={playground.id} span={24}>
                    <PlaygroundCard
                      onDelete={handleDeletePlayground}
                      playground={playground}
                      onClick={() => handleNavigation(`${AppRoutes.Playgrounds}/${playground.id}` as AppRoutes, playground.name)}
                      key={playground.id}
                    />
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <>
              <Empty
                style={{ marginTop: 120 }}
                image={<CrystalBallIllustration disabled />}
                description={
                  <StyledEmptyContent>
                    <div>You have no playgrounds!</div>
                    <div> Start by creating a new one</div>
                    <Button
                      style={{ background: theme.colors.primaryShades['primary-6'], borderRadius: 24 }}
                      color={'primary'}
                      onClick={handleShowPlaygroundModal}
                    >
                      <AddIcon size={24} /> Create
                    </Button>
                  </StyledEmptyContent>
                }
              />
            </>
          )}
        </ScrollableScreenContainer>
        <Dialog
          title="What's your playground project name?"
          onClose={handleClosePlaygroundModal}
          closeOnMaskClick
          visible={isNewProjectModalVisible}
          content={<NewProjectModalContent onChange={setNewProjectName} value={newProjectName} />}
          actions={[{ key: 'create', text: 'Create', disabled: !newProjectName?.trim().length }]}
          onAction={() => addPlayground(newProjectName || '')}
        />
      </StyledAnimatedContainer>
    </SwipeBackContainer>
  );
};

const StyledFixedButtonRow = styled.div`
  padding-top: ${props => `calc(${MOBILE_HEADER_HEIGHT_SHRINKED}px + env(safe-area-inset-top) + 16px)`};
  padding-right: 16px;
  padding-bottom: 4px;
  background: transparent;
`;

const NewProjectModalContent = ({ onChange, value }: { onChange: (val: string) => void; value?: string }) => {
  return <StyledNewProjectInput placeholder='eg: Car Budget 🚗 ' onChange={val => onChange(val)} value={value} />;
};

const StyledNewProjectInput = styled(Input)`
  margin-top: 12px;
  .adm-input-element {
    text-align: center;
  }
`;

const StyledAnimatedContainer = styled(motion.div)`
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
`;

const StyledButtonRow = styled(Row)`
  padding-left: 16px;
  margin-bottom: 12px;
  button > span {
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
    padding-right: 4px;
  }
  h3 {
    margin: 0px;
  }
`;

const StyledEmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 12px;
    width: 120px;
    svg {
      margin-bottom: -6px;
    }
  }
`;

export default Playgrounds;
