import { Empty } from 'antd-mobile';
import { useTransactions } from '../Hooks';
import { Transaction } from '../Model';
import { app_config, getMonthlyOccurences } from '../Utils';
import { SwipeActionList } from './SwipeActionList';

interface Props {
  transactions: Transaction[];
  redirectUrl?: string;
  upcoming?: boolean;
  limit?: boolean;
  showDue?: boolean;
}

export const TransactionsList: React.FC<Props> = ({ transactions, showDue, upcoming, limit }) => {
  const { deleteTransaction, updateTransaction } = useTransactions();
  const { DASHBOARD_LISTS_ITEM_COUNT } = app_config;
  const formattedTransactions = upcoming ? getMonthlyOccurences(transactions) : transactions;
  const renderedTransactions = limit ? formattedTransactions.slice(0, DASHBOARD_LISTS_ITEM_COUNT) : formattedTransactions;

  if (transactions.length === 0) {
    return <Empty />;
  }

  return (
    <SwipeActionList
      showDue={showDue}
      onEditSave={t => updateTransaction(t)}
      onDelete={transaction => deleteTransaction(transaction)}
      data={renderedTransactions}
    />
  );
};
