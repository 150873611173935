import { Divider, Typography } from 'antd';
import { Dialog, SwipeAction, List } from 'antd-mobile';
import { Action } from 'antd-mobile/es/components/swipe-action';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import styled from 'styled-components';
import { CommonIcons, theme } from '../../Design';
import { useTransactions } from '../../Hooks';
import { Frequency, Category, Transaction, DATE_FORMAT } from '../../Model';
import { MoneyFormatter, getNextDate, isToday, isPastToday } from '../../Utils';
import { EditPageTitle, EditTransactionForm, StyledEditDrawer } from '../EditPage';
import { SwipeActionItem } from './SwipeActionItem';

export interface ActionListData {
  amount: number;
  name: string;
  date: string;
  id: string;
  frequency?: Frequency;
  category?: Category;
}

interface Props {
  data: Array<Transaction>;
  onDelete: (transaction: Transaction) => void;
  length?: number;
  showDue?: boolean;
  onEditSave: (transaction: Transaction) => void;
}

export const SwipeActionList: React.FC<Props> = ({ data, onDelete, onEditSave, showDue }) => {
  const { EditOutlineIcon, DeleteOutlineIcon, DueIcon } = CommonIcons;
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [inEditId, setInEditId] = useState('');
  const { addTransaction, updateTransaction } = useTransactions();

  const handleEdit = (id: string) => {
    setInEditId(id);
    setIsEditDrawerOpen(true);
  };

  const transactionInEdition = data.find(datum => datum.id === inEditId);

  const handleClose = () => {
    setIsEditDrawerOpen(false);
    setTimeout(() => {
      setInEditId('');
    }, 200);
  };

  const rightActions: Action[] = [
    {
      key: 'edit',
      text: <StyledIcon $color={theme.colors.primaryShades['primary-9']}>{<EditOutlineIcon />}</StyledIcon>,
      color: theme.colors.neutralPalette['gray-4'],
    },
    {
      key: 'delete',
      text: <StyledIcon>{<DeleteOutlineIcon />}</StyledIcon>,
      color: 'danger',
    },
  ];

  const leftActions: Action[] = [
    {
      key: 'paid',
      text: <StyledIcon>Paid</StyledIcon>,
      color: 'success',
    },
  ];

  const handleOnSave = (transaction: Transaction) => {
    onEditSave(transaction);
    setIsEditDrawerOpen(false);
  };

  const handleOnPaid = (transaction: Transaction) => {
    Dialog.show({
      content: (
        <StyledDeleteModalContent>
          <div>
            <Typography.Title level={5}>Confirm Payment</Typography.Title>
          </div>
          <StyledNameAndPrice>
            <Typography.Text>{transaction.name} -</Typography.Text>
            <Typography.Text>{MoneyFormatter.format(transaction.amount)}</Typography.Text>
          </StyledNameAndPrice>
        </StyledDeleteModalContent>
      ),
      closeOnMaskClick: true,
      closeOnAction: true,
      actions: [
        [
          { key: 'cancel', text: 'Cancel' },
          {
            key: 'confirm',
            text: 'Confirm',
            danger: false,
            bold: true,
            onClick: () => {
              const { createdAt, lastUpdatedAt, ...newTransaction } = transaction;
              const isPast = isPastToday(newTransaction.date);
              const transactionToAdd = isPast ? newTransaction : { ...newTransaction, date: dayjs().format(DATE_FORMAT) };
              updateTransaction({ ...transaction, date: getNextDate(transaction.date, transaction.frequency!).format('YYYY-MM-DD') });
              addTransaction({ ...transactionToAdd, paid: true, originalTransactionId: transaction.id });
            },
          },
        ],
      ],
    });
  };

  const handleOnDelete = (transaction: Transaction) => {
    const { name, amount } = transaction;
    Dialog.show({
      content: (
        <StyledDeleteModalContent>
          <div>
            <Typography.Title level={5}>Confirm delete</Typography.Title>
          </div>
          <StyledNameAndPrice>
            <Typography.Text>{name} -</Typography.Text>
            <Typography.Text>{MoneyFormatter.format(amount)}</Typography.Text>
          </StyledNameAndPrice>
        </StyledDeleteModalContent>
      ),
      closeOnMaskClick: true,
      closeOnAction: true,
      actions: [
        [
          { key: 'cancel', text: 'Cancel' },
          {
            key: 'delete',
            text: 'Delete',
            danger: true,
            onClick: () => {
              onDelete(transaction);
            },
          },
        ],
      ],
    });
  };

  const dataByDate = data.reduce((acc: Record<string, any>, curr) => {
    if (!Object.keys(acc).includes(curr.date)) {
      return {
        ...acc,
        [curr.date]: [curr],
      };
    } else {
      return {
        ...acc,
        [curr.date]: [...acc[curr.date], curr],
      };
    }
  }, {});

  const dates = Object.keys(dataByDate).filter(d => dataByDate[d as keyof typeof dataByDate].length) as Array<keyof typeof dataByDate>;

  return (
    <div>
      {dates.map((date, i) => {
        const isDue = Boolean(showDue) && isPastToday(date as string);
        return (
          <div key={i + ''}>
            <StyledDate $isDue={isDue} $first={i !== 0}>
              {isDue && <DueIcon />}
              <Typography.Text type='secondary'>
                {isDue && (
                  <>
                    <span style={{ fontWeight: 750 }}>Due</span> <Divider type='vertical' />{' '}
                  </>
                )}
                {isToday(date as string) ? 'Today' : dayjs(date).format('MMM D, YYYY')}{' '}
              </Typography.Text>
            </StyledDate>
            <StyledSwipeActionList>
              {dataByDate[date].map((transaction: Transaction) => {
                return (
                  <React.Fragment key={transaction.id}>
                    <StyledSwipeAction
                      closeOnTouchOutside
                      onAction={action =>
                        action.key === 'edit'
                          ? handleEdit(transaction.id)
                          : action.key === 'delete'
                          ? handleOnDelete(transaction)
                          : handleOnPaid(transaction)
                      }
                      rightActions={rightActions}
                      leftActions={transaction.recurrent && !transaction.paid ? leftActions : []}
                    >
                      {
                        <List.Item>
                          <SwipeActionItem transaction={transaction} />
                        </List.Item>
                      }
                    </StyledSwipeAction>
                    {transaction.id === transactionInEdition?.id && (
                      <StyledEditDrawer
                        title={<EditPageTitle title={<>Edit {transactionInEdition?.type}</>} />}
                        destroyOnClose
                        height='90%'
                        visible={isEditDrawerOpen}
                        onClose={handleClose}
                        placement='bottom'
                      >
                        <EditTransactionForm
                          onCancel={handleClose}
                          isLoading={false}
                          mode='edit'
                          onSave={handleOnSave}
                          initialValues={data.find(x => x.id === inEditId)!}
                        />
                      </StyledEditDrawer>
                    )}
                  </React.Fragment>
                );
              })}
            </StyledSwipeActionList>
          </div>
        );
      })}
    </div>
  );
};

const StyledSwipeAction = styled(SwipeAction)``;

const StyledDate = styled.div<{ $first: boolean; $isDue: boolean }>`
  padding: 8px;
  margin-top: ${({ $first }) => $first && '12px'};
  display: flex;
  align-items: end;
  line-height: 1.3;
  .ant-typography,
  svg {
    color: ${({ $isDue }) => $isDue && theme.colors.danger};
  }
  svg {
    font-size: 18px;
    margin-right: 2px;
  }
  .ant-divider {
    border-left-color: ${({ $isDue }) => $isDue && theme.colors.danger};
    margin: 0px 2px;
  }
`;

const StyledNameAndPrice = styled.div`
  span:nth-of-type(1) {
    margin-right: 4px;
  }
`;

export const StyledSwipeActionList = styled(List)`
  .adm-list-item-content,
  .adm-list-item {
    border-top: none !important;
    border-bottom: none !important;
  }
  .adm-list-body {
    border-radius: 12px;
    border-bottom: none !important;
    border-top: none !important;
    box-shadow: 1px 5px 14px 2px rgba(0, 0, 0, 0.06);
  }
  .adm-swipe-action-action {
  }
  .adm-list-item {
    padding-left: 8px;
  }
  .adm-swipe-action-actions-left > button:first-of-type {
    border-radius: 12px 0px 0px 12px;
  }
  button:nth-of-type(2) {
    border-radius: 0px 12px 12px 0px;
  }
`;

const StyledIcon = styled.div<{ $color?: string }>`
  width: 45px;
  svg {
    font-size: 28px;
    transform: translateY(3px);
    fill: ${({ $color }) => $color || 'white'};
  }
`;

const StyledDeleteModalContent = styled.div`
  text-align: center;
`;
