import { IoLogoUsd, IoReceiptOutline, IoCardOutline, IoCard, IoTimeOutline, IoChevronBackOutline, IoArrowForward, IoChevronForwardOutline, IoCalendarClearOutline, IoChevronDownOutline, IoAdd } from 'react-icons/io5';
import { MdSpaceDashboard, MdOutlineEdit, MdOutlineDelete, MdOutlineAvTimer, MdOutlineMonetizationOn } from 'react-icons/md';
import { CgList } from 'react-icons/cg';
import { IoIosMore } from 'react-icons/io';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri'


export const CommonIcons = {
  AddIcon: IoAdd,
  IncomeIcon: IoLogoUsd,
  IncomeOutlinedIcon: MdOutlineMonetizationOn,
  LiabilityIcon: IoReceiptOutline,
  Dashboard: MdSpaceDashboard,
  PaymentOutlineIcon: IoCardOutline,
  PaymentIcon: IoCard,
  EditOutlineIcon: MdOutlineEdit,
  DeleteOutlineIcon: MdOutlineDelete,
  DueIcon: IoTimeOutline,
  BackIcon: IoChevronBackOutline,
  ForwardIcon: IoArrowForward,
  ListIcon: CgList,
  UpcomingIcon: MdOutlineAvTimer,
  MoreIcon: IoIosMore,
  ForwardChevron: IoChevronForwardOutline,
  DateIcon: IoCalendarClearOutline,
  ChevronDown: IoChevronDownOutline,
  ChevronLeft: FaChevronLeft,
  ChevronRight: FaChevronRight,
  HiddenIcon: RiEyeCloseLine,
  VisibleIcon: RiEyeLine,
};
