import { addDoc, collection, deleteDoc, doc, Timestamp, updateDoc } from "firebase/firestore";
import { Playground } from "../Model";
import { PlaygroundTransaction } from "../Model/Transaction";
import { db } from "./firebase";

export const playgroundPath = (uid: string) => `accounts/${uid}/playground`
const playgroundRef = (uid: string) => collection(db, playgroundPath(uid));

export const playgroundTransactionsPath = (uid: string, projectId: string) => `accounts/${uid}/playground/${projectId}/transactions`
const playgroundTransactionsRef = (uid: string, projectId: string) => collection(db, playgroundTransactionsPath(uid, projectId));

// PLAYGROUND DOC

export const addPlayground = async (uid: string, name: string) => {
    return await addDoc(playgroundRef(uid), {
        name,
        createdAt: Timestamp.now(),
        lastUpdatedAt: Timestamp.now(),
    } as Playground);
}

export const deletePlayground = async (uid: string, playgroundId: string) => {
    return await deleteDoc(doc(db, `${playgroundPath(uid)}/${playgroundId}`));
}


// TRANSACTIONS COLLECTION

export const addPlaygroundTransaction = async (transaction: PlaygroundTransaction, uid: string, projectId: string) => {
    return await addDoc(playgroundTransactionsRef(uid, projectId), {
        ...transaction,
        createdAt: Timestamp.now(),
        lastUpdatedAt: Timestamp.now(),
    });
};

export const deletePlaygroundTransaction = async (transaction: PlaygroundTransaction, uid: string, projectId: string) => {
    return await deleteDoc(doc(db, `${playgroundTransactionsPath(uid, projectId)}/${transaction.id}`));
};

export const updatePlaygroundTransaction = async (transaction: PlaygroundTransaction, uid: string, projectId: string) => {
    const ref = doc(db, `${playgroundTransactionsPath(uid, projectId)}/${transaction.id}`);
    return await updateDoc(ref, {
        ...transaction,
        lastUpdatedAt: Timestamp.now(),
    });
};
