import { Button } from 'antd-mobile';
import { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../Design';

interface Props extends React.PropsWithChildren {
  initialHeight?: number;
  hideExtender?: boolean;
}

export const ExpandableCard: React.FC<Props> = ({ initialHeight, children, hideExtender }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <>
      <StyledExpandableCard $hideExtender={hideExtender} $initialHeight={initialHeight} $collapsed={collapsed}>
        {children}
      </StyledExpandableCard>
      {hideExtender && (
        <StyledFooter $collapsed={collapsed}>
          <Button onClick={() => setCollapsed(prev => !prev)}>
            {collapsed ? <StyledButtonContent>Show less</StyledButtonContent> : <StyledButtonContent>Show All</StyledButtonContent>}{' '}
          </Button>
        </StyledFooter>
      )}
    </>
  );
};

const StyledButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.primaryShades['primary-9']};
  svg {
    margin-left: 4px;
  }
  font-size: 16px;
  font-weight: 550 !important;
`;

const StyledFooter = styled.div<{ $collapsed: boolean }>`
  button {
    width: 100% !important;
    border-radius: 0px;
    border: none;
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid ${theme.colors.neutralPalette['gray-4']};
    padding: 16px;
  }
  svg {
    transition: transform 0.3s linear;
    transform: ${({ $collapsed }) => ($collapsed ? 'rotate(-180deg)' : '')};
  }
`;

const StyledExpandableCard = styled.div<{ $collapsed: boolean; $initialHeight?: number; $hideExtender?: boolean }>`
  background-color: white;
  height: ${({ $collapsed, $initialHeight }) => ($collapsed ? '95%' : $initialHeight ? `${$initialHeight}px` : '200px')};
  transition: height 0.3s linear;
  overflow: hidden;
  border-radius: ${({ $hideExtender }) => ($hideExtender ? '8px 8px 0px 0px' : '8px 8px 8px 8px')};
`;
