import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import { PaymentsStale, IncomeStale, theme } from '../../Design';
import { PlaygroundTransaction } from '../../Model';

interface Props {
  value: PlaygroundTransaction['type'];
  onChange: (transactionType: PlaygroundTransaction['type']) => void;
}

export const TransactionTypeRadio: React.FC<Props> = ({ value, onChange }) => {
  const isPaymentActive = value === 'Payment';
  return (
    <StyledTransactionTypeRadio>
      <Row gutter={[24, 16]}>
        <StyledIconColumn $isActive={isPaymentActive} onClick={() => onChange('Payment')}>
          <PaymentsStale color={isPaymentActive ? 'active' : 'primary'} />
          <Typography.Paragraph type='secondary'>Payment</Typography.Paragraph>
        </StyledIconColumn>
        <StyledIconColumn $isActive={!isPaymentActive} onClick={() => onChange('Income')}>
          <IncomeStale color={isPaymentActive ? 'primary' : 'active'} />
          <Typography.Paragraph type='secondary'>Income</Typography.Paragraph>
        </StyledIconColumn>
      </Row>
    </StyledTransactionTypeRadio>
  );
};

const StyledTransactionTypeRadio = styled.div`
  padding-top: 40px;
  text-align: center;
`;

const StyledIconColumn = styled(Col)<{ $isActive: boolean }>`
  transition: transform 400ms;
  transform: ${({ $isActive }) => ($isActive ? `scale(1.3)` : 'scale(1)')};
  .ant-typography {
    font-size: 12px;
    color: ${({ $isActive }) => ($isActive ? theme.colors.primary : theme.colors.primary)};
  }
  svg {
    margin-bottom: -4px;
  }
`;
