import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { addPlayground, addPlaygroundTransaction, auth, db, deletePlayground, playgroundPath } from '../Api';
import { collection, limit, orderBy, query } from 'firebase/firestore';
import { useMutation } from 'react-query';
import { useRouteContext, useTransactionsContext } from '../Contexts';
import { AppRoutes, Playground } from '../Model';
import { Toast } from 'antd-mobile';

export const usePlayground = () => {
  const { handleNavigation } = useRouteContext();
  const { flagsRecurrentTransactions } = useTransactionsContext();
  const [playgrounds, setPlaygrounds] = useState<Playground[]>([]);
  const [isLoading, setLoading] = useState(true);
  // const [isLoadingCreating, setIsLoadingCreating] = useState(false);
  const uid = auth.currentUser?.uid;
  const tQuery = query(collection(db, playgroundPath(uid!)), limit(999), orderBy('createdAt', 'desc'));

  const handleAddPlaygroundMutation = useMutation({
    mutationFn: (name: string) => addPlayground(uid!, name),
  });
  const handleDeletePlaygroundMutation = useMutation({
    mutationFn: (id: string) => deletePlayground(uid!, id),
  });

  const handleAddPlayground = (name: string, config: { withImport: boolean; projectName: string }) => {
    if (config.withImport) {
      handleAddPlaygroundMutation.mutate(name, {
        onSuccess: data => {
          flagsRecurrentTransactions.map(transaction => addPlaygroundTransaction(transaction, uid!, data.id));
          handleNavigation(`${AppRoutes.Playgrounds}/${data.id}` as AppRoutes, config.projectName);
        },
      });
    } else {
      handleAddPlaygroundMutation.mutate(name);
    }
  };
  const handleDeletePlayground = (playgroundId: string) => {
    handleDeletePlaygroundMutation.mutate(playgroundId, {
      onSuccess: () => {
        Toast.show({
          icon: 'success',
          content: 'Deleted',
        });
      },
    });
  };

  useEffect(() => {
    const unsub = onSnapshot(
      query(tQuery),
      documents => {
        console.log(`%cFetched Playground Projects`, 'color:green;font-weight:bold');
        let TransactionsData: Array<Playground> = [];
        documents.forEach(doc => {
          TransactionsData.push({
            ...(doc.data() as Playground),
            id: doc.id,
          });
        });
        setPlaygrounds(TransactionsData);
        setLoading(false);
      },
      error => {
        console.error(error.message);
      },
    );
    return () => {
      unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return { playgrounds, isLoading, handleAddPlayground, handleDeletePlayground };
};
