import { theme } from './Theme';

export const PrimaryGradient = [
  theme.colors.primaryShades['primary-3'],
  theme.colors.primaryShades['primary-4'],
  theme.colors.primaryShades['primary-5'],
  theme.colors.primaryShades['primary-6'],
  theme.colors.primaryShades['primary-7'],
  theme.colors.primaryShades['primary-8'],
];
