import React from 'react';
import { Category } from '../Model';
import { theme } from './Theme';
import { SiUbereats, SiUber } from 'react-icons/si';
import {
  IoRestaurantOutline,
  IoCloudOutline,
  IoBagHandleOutline,
  IoBusOutline,
  IoFitnessSharp,
  IoHappyOutline,
  IoHomeOutline,
  IoPersonOutline,
  IoPricetagsOutline,
  IoHelpCircleOutline,
  IoGiftOutline,
  IoSunnyOutline,
} from 'react-icons/io5';
import { GiReceiveMoney } from 'react-icons/gi';

type ColorCodes<T extends string> = Record<
  T,
  {
    backgroundColor: string;
    contrastColor: string;
    chartColor: string;
    lightColor: string;
    icon?: React.ReactNode;
  }
>;

export const Categories_Design: ColorCodes<Category> = {
  'Gift & Donations': {
    backgroundColor: '#994e43',
    contrastColor: theme.colors.primaryShades['primary-1'],
    chartColor: '#FF8270',
    icon: <IoGiftOutline />,
    lightColor: '#ffe9e5',
  },
  Entertainment: {
    backgroundColor: '#3a1477',
    contrastColor: 'Secondary/Green/Green 0 (Semantic)',
    chartColor: '#531DAB',
    icon: <IoHappyOutline />,
    lightColor: '#F9F0FF',
  },
  Food: {
    backgroundColor: '#573285',
    contrastColor: theme.colors.primaryShades['primary-10'],
    chartColor: '#9254DE',
    icon: <IoRestaurantOutline />,
    lightColor: '#F9F0FF',
  },
  Health: {
    backgroundColor: '#E03729',
    contrastColor: theme.colors.primaryShades['primary-10'],
    chartColor: '#E03729',
    icon: <IoFitnessSharp />,
    lightColor: '#FFF2F1',
  },
  Other: {
    backgroundColor: '#081C7D',
    contrastColor: theme.colors.primaryShades['primary-10'],
    chartColor: '#081C7D',
    icon: <IoHelpCircleOutline />,
    lightColor: '#e7ebfd',
  },
  Personal: {
    backgroundColor: '#1890FF',
    contrastColor: theme.colors.primaryShades['primary-10'],
    chartColor: '#1890FF',
    icon: <IoPersonOutline />,
    lightColor: '#E6F7FF',
  },
  Shopping: {
    backgroundColor: '#EB2F96',
    contrastColor: theme.colors.primaryShades['primary-10'],
    chartColor: '#EB2F96',
    icon: <IoBagHandleOutline />,
    lightColor: '#FFF0F6',
  },
  Transportation: {
    backgroundColor: '#b28649',
    contrastColor: theme.colors.primaryShades['primary-10'],
    chartColor: '#FFC069',
    icon: <IoBusOutline />,
    lightColor: '#fff4e5',
  },
  Vacations: {
    backgroundColor: '#ab911c',
    contrastColor: theme.colors.primaryShades['primary-10'],
    chartColor: '#F5D029',
    icon: <IoSunnyOutline />,
    lightColor: '#fefae7',
  },
  'Uber Eats': {
    backgroundColor: '#50922a',
    contrastColor: theme.colors.primaryShades['primary-10'],
    icon: <SiUbereats />,
    chartColor: '#73D13D',
    lightColor: '#f0faea',
  },
  Uber: {
    backgroundColor: '#000',
    contrastColor: theme.colors.primaryShades['primary-10'],
    icon: <SiUber />,
    chartColor: '#000',
    lightColor: '#f0faea',
  },
  Debt: {
    backgroundColor: '#A11409',
    contrastColor: theme.colors.primaryShades['primary-10'],
    icon: <GiReceiveMoney />,
    chartColor: '#A11409',
    lightColor: '#fee9e7',
  },

  Housing: {
    backgroundColor: '#1064b2',
    contrastColor: theme.colors.primaryShades['primary-10'],
    icon: <IoHomeOutline />,
    chartColor: '#1890FF',
    lightColor: '#E6F7FF',
  },
  Utilities: {
    backgroundColor: '#25908c',
    contrastColor: theme.colors.primaryShades['primary-10'],
    icon: <IoCloudOutline />,
    chartColor: '#36CFC9',
    lightColor: '#E6FFFB',
  },

  Subscriptions: {
    backgroundColor: '#536BE4',
    contrastColor: theme.colors.primaryShades['primary-2'],
    icon: <IoPricetagsOutline />,
    chartColor: '#536BE4',
    lightColor: '#E6F7FF',
  },
  'Dine out': {
    backgroundColor: '#536BE4',
    contrastColor: theme.colors.primaryShades['primary-2'],
    icon: <IoPricetagsOutline />,
    chartColor: '#536BE4',
    lightColor: '#E6F7FF',
  },
  'Fitness & Sports': {
    backgroundColor: '#536BE4',
    contrastColor: theme.colors.primaryShades['primary-2'],
    icon: <IoPricetagsOutline />,
    chartColor: '#536BE4',
    lightColor: '#E6F7FF',
  },
  'Legal/Professional Fees': {
    backgroundColor: '#536BE4',
    contrastColor: theme.colors.primaryShades['primary-2'],
    icon: <IoPricetagsOutline />,
    chartColor: '#536BE4',
    lightColor: '#E6F7FF',
  },
  'Take out': {
    backgroundColor: '#536BE4',
    contrastColor: theme.colors.primaryShades['primary-2'],
    icon: <IoPricetagsOutline />,
    chartColor: '#536BE4',
    lightColor: '#E6F7FF',
  },
  Childcare: {
    backgroundColor: '#536BE4',
    contrastColor: theme.colors.primaryShades['primary-2'],
    icon: <IoPricetagsOutline />,
    chartColor: '#536BE4',
    lightColor: '#E6F7FF',
  },
  Education: {
    backgroundColor: '#536BE4',
    contrastColor: theme.colors.primaryShades['primary-2'],
    icon: <IoPricetagsOutline />,
    chartColor: '#536BE4',
    lightColor: '#E6F7FF',
  },
  Pets: {
    backgroundColor: '#536BE4',
    contrastColor: theme.colors.primaryShades['primary-2'],
    icon: <IoPricetagsOutline />,
    chartColor: '#536BE4',
    lightColor: '#E6F7FF',
  },
  Taxes: {
    backgroundColor: '#536BE4',
    contrastColor: theme.colors.primaryShades['primary-2'],
    icon: <IoPricetagsOutline />,
    chartColor: '#536BE4',
    lightColor: '#E6F7FF',
  },
  Travel: {
    backgroundColor: '#536BE4',
    contrastColor: theme.colors.primaryShades['primary-2'],
    icon: <IoPricetagsOutline />,
    chartColor: '#536BE4',
    lightColor: '#E6F7FF',
  },
};
