import { TimeStamps } from './Constants';
import { Category } from './Category';

export enum Frequency {
  Weekly = 'WEEKLY',
  BiWeekly = 'BI-WEEKLY',
  Monthly = 'MONTHLY',
  Annually = 'ANNUALLY',
  Onetime = 'ONE TIME',
}

export const Frequencies = [Frequency.Weekly, Frequency.BiWeekly, Frequency.Monthly, Frequency.Annually];

export type TransactionType = 'Income' | 'Payment';

export interface Transaction extends TimeStamps {
  id: string;
  name: string;
  amount: number;
  date: string; //yyyy-mm-dd
  type: TransactionType;
  recurrent: boolean;
  category?: Category;
  frequency: Frequency;
  paid?: boolean /* Applies to recurrent */;
  originalTransactionId?: string;
}

export interface PlaygroundTransaction {
  id: string;
  name: string;
  amount: number;
  type: TransactionType;
  recurrent: boolean;
  category?: Category;
  frequency?: Frequency;
}
