import { Col, Row, Spin, Typography } from 'antd';
import styled from 'styled-components';
import { useRouteContext } from '../../Contexts';
import { CommonIcons } from '../../Design';
import { AppRoutes } from '../../Model';
import { StyledSeeAllButton } from '../Widgets';

interface Props extends React.PropsWithChildren {
  title: string;
  isLoading?: boolean;
  redirectUrl?: AppRoutes;
}

const { ForwardChevron } = CommonIcons;

export const CardWithTitle: React.FC<Props> = ({ children, title, isLoading, redirectUrl }) => {
  const { handleNavigation } = useRouteContext();
  if (isLoading) {
    return (
      <StyledCardLoading>
        <Spin />
      </StyledCardLoading>
    );
  }
  return (
    <StyledCardWithTitle>
      <Row style={{ marginBottom: 16 }} align='middle' justify='space-between'>
        <Col>
          <Typography.Title style={{ marginBottom: 18 }} ellipsis level={3}>
            {title}
          </Typography.Title>
        </Col>
        {redirectUrl && (
          <Col>
            <StyledSeeAllButton onClick={() => handleNavigation(redirectUrl)} fill='none'>
              see all <ForwardChevron />
            </StyledSeeAllButton>
          </Col>
        )}
      </Row>
      {children}
    </StyledCardWithTitle>
  );
};

const StyledCardWithTitle = styled.div`
  max-width: 100%;
  h3 {
    margin: 0px !important;
  }
`;

const StyledCardLoading = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
