export const theme = {
    size: {
      xxsmall: '4px',
      xsmall: '8px',
      small: '12px',
      medium: '16px',
      large: '24px',
      xlarge: '32px',
    },
    colors: {
      success : '#389E0D',
      warning : '#E2B104',
      primary: '#224FDF',
      secondary: '#FF614B',
      danger : '#CD2113',
      neutralPalette: {
        'gray-1': '#ffffff',
        'gray-2': '#fafafa',
        'gray-3': '#f5f5f5',
        'gray-4': '#f0f0f0',
        'gray-5': '#d9d9d9',
        'gray-6': '#bfbfbf',
        'gray-7': '#8c8c8c',
        'gray-8': '#595959',
        'gray-9': '#434343',
        'gray-10': '#262626',
        'gray-11': '#1f1f1f',
        'gray-12': '#141414',
        'gray-13': '#000000',
      },
      primaryShades: {
        'primary-1': '#F0F2FF',
        'primary-2': '#DBE1FF',
        'primary-3': '#B8C4FF',
        'primary-4': '#7D93FD',
        'primary-5': '#536BE4',
        'primary-6': '#224FDF',
        'primary-7': '#1441B4',
        'primary-8': '#081C7D',
        'primary-9': '#041257',
        'primary-10': '#020B36',
      },
      primaryGray: {
        'primary-gray-1': '#F4F5F9',
        'primary-gray-2': '#EAECF4',
        'primary-gray-3': '#D8DBEB',
        'primary-gray-4': '#9EA4C7',
        'primary-gray-5': '#8088B0',
        'primary-gray-6': '#4D5894',
        'primary-gray-7': '#384587',
        'primary-gray-8': '#3B4269',
        'primary-gray-9': '#232C54',
        'primary-gray-10': '#1F2542',
      },
      secodaryShades: {
        'secondary-1': '#FFF2F0',
        'secondary-2': '#FFDBD6',
        'secondary-3': '#FFA194',
        'secondary-4': '#FF8270',
        'secondary-5': '#FF614B',
        'secondary-6': '#EB4D37',
        'secondary-7': '#DF2E16',
        'secondary-8': '#C82A14',
        'secondary-9': '#A41B09',
        'secondary-10': '#8A1100',
      },
    },
  };
  