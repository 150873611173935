import { Col, Row, Typography } from 'antd';
import { Card, Tag } from 'antd-mobile';
import styled from 'styled-components';
import { CashflowData } from '../../Contexts';
import { CommonIcons, theme } from '../../Design';
import { MoneyFormatter } from '../../Utils';

interface Props {
  data: CashflowData;
  title: string;
  disabled?: boolean;
}

enum DiffType {
  negative = 'negative',
  positive = 'positive',
  flat = 'flat',
  weak = 'weak',
}

const TrendsColors: Record<DiffType, string> = {
  positive: '#389E0D',
  negative: theme.colors.danger,
  flat: theme.colors.primaryGray['primary-gray-10'],
  weak: '#D46B08',
};

const { PaymentIcon, IncomeOutlinedIcon } = CommonIcons;

export const PlaygroundCashflowCard: React.FC<Props> = ({ data, title, disabled }) => {
  const { totalIncome, totalPayments } = data;
  const cashflow = totalIncome - totalPayments;
  const percentage = (cashflow / totalIncome) * 100;

  const diffType =
    percentage > 30 ? DiffType.positive : percentage < 0 ? DiffType.negative : percentage > 0 ? DiffType.weak : DiffType.flat;

  return (
    <StyledPlaygroundCashflowCard $disabled={disabled}>
      <Row gutter={[0, 0]} align='top' justify='space-between'>
        <Col>
          <Typography.Title level={4}>{title}</Typography.Title>
        </Col>
        <Col>
          <StyledTag color={TrendsColors[diffType]}>{totalIncome ? `${percentage.toFixed(1)}%` : '-'}</StyledTag>
        </Col>
      </Row>
      <Typography.Title level={4}>{MoneyFormatter.format(cashflow)}</Typography.Title>
      <StyledCashflowDetails>
        <Row align='middle'>
          <IncomeOutlinedIcon /> {MoneyFormatter.format(data.totalIncome)}
        </Row>
      </StyledCashflowDetails>
      <StyledCashflowDetails>
        <Row align='middle'>
          <PaymentIcon /> {MoneyFormatter.format(data.totalPayments)}
        </Row>
      </StyledCashflowDetails>
    </StyledPlaygroundCashflowCard>
  );
};

const StyledCashflowDetails = styled(Typography.Text)`
  line-height: 1.2;
`;

const StyledPlaygroundCashflowCard = styled(Card)<{ $disabled?: boolean }>`
  width: 45vw;
  text-align: left;
  background-color: ${({ $disabled }) => ($disabled ? theme.colors.primaryGray['primary-gray-2'] : '')};
  h4:nth-of-type(1) {
    margin-bottom: 4px;
  }
  svg {
    margin-right: 4px;
  }
`;

const StyledTag = styled(Tag)`
  font-size: 12px;
  font-weight: 600;
  border-radius: 32px;
  padding: 8px;
  width: 100%;
  text-align: center;
  color: #fff;
  border: none;
`;
