import { Col, Row, Spin, Typography } from 'antd';
import { Card, Divider } from 'antd-mobile';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useTransactionsContext } from '../../Contexts';
import { CommonIcons, theme } from '../../Design';
import { MONTHS_LABEL, Transaction } from '../../Model';
import { getMonthlyOccurencesCount, isPast, MoneyFormatter } from '../../Utils';

interface Props {
  upcomingPayments: Transaction[];
  pastPayments: Transaction[];
  isLoading?: boolean;
}

export const TotalPayments: React.FC<Props> = ({ upcomingPayments, pastPayments, isLoading }) => {
  const { UpcomingIcon, PaymentIcon } = CommonIcons;
  const yearMonth = dayjs().format('YYYY-MM');
  const { selectedMonth } = useTransactionsContext();

  const lastDayOfMonth = new Date(Number(yearMonth.slice(0, 4)), Number(yearMonth.slice(5, 7)), 0).getDate();
  const allMonthlyTransactions = [...upcomingPayments, ...pastPayments].filter(
    t => t.date >= `${yearMonth}-01` && t.date <= `${yearMonth}-${lastDayOfMonth}`,
  );

  const totalUpcoming = allMonthlyTransactions.reduce((acc, curr) => {
    if (curr.recurrent && !curr.paid && !isPast(curr)) {
      return acc + curr.amount * getMonthlyOccurencesCount(curr.date, curr.frequency!);
    }
    if (!isPast(curr)) {
      return acc + curr.amount;
    }
    return acc;
  }, 0);

  const totalSpendings = pastPayments.reduce((acc, curr) => {
    return acc + curr.amount;
  }, 0);

  if (isLoading) {
    return (
      <StyledLoading>
        <Spin />
      </StyledLoading>
    );
  }

  return (
    <StyledTotalPayments>
      <Row justify='space-between' align='middle'>
        <Col>
          <Typography.Title level={4}>Monthly Payments</Typography.Title>
        </Col>
        <Col>
          <Typography.Text type='secondary'>{MONTHS_LABEL[selectedMonth]}</Typography.Text>
        </Col>
      </Row>
      <Row justify='space-between' align='middle'>
        <Col>
          <StyledIconTitle>
            <UpcomingIcon />
            Upcoming
          </StyledIconTitle>
        </Col>
        <Col>{MoneyFormatter.format(-totalUpcoming)}</Col>
      </Row>
      <Row justify='space-between' align='middle'>
        <Col>
          <StyledIconTitle>
            <PaymentIcon />
            Spent
          </StyledIconTitle>
        </Col>
        <Col>{MoneyFormatter.format(-totalSpendings)}</Col>
      </Row>
      <Divider />
      <Row justify='end' align='middle'>
        <Col>
          <Typography.Title level={2}>-{MoneyFormatter.format(totalUpcoming + totalSpendings)}</Typography.Title>
        </Col>
      </Row>
    </StyledTotalPayments>
  );
};

const StyledLoading = styled(Card)`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconTitle = styled(Typography.Text)`
  margin-top: 4px;
  display: flex;
  align-items: center;
  font-size: 16px;
  svg {
    margin-right: 4px;
    font-size: 20px;
  }
`;

const StyledTotalPayments = styled(Card)`
  padding: 8px 16px;
  font-size: 16px;
  .ant-row:first-of-type {
    margin-bottom: 8px;
  }
  .ant-row:nth-of-type(2) {
    margin-bottom: 2px;
  }
  .adm-divider {
    margin: 12px 0px 12px 0px;
  }
  h4,
  h2,
  h5 {
    margin: 0px;
  }
  svg {
    font-size: 24px;
  }
  color: ${theme.colors.primaryShades['primary-9']}!important;
`;
