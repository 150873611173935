interface Props {}

export const AddButtonIcon: React.FC<Props> = () => {
  return (
    <svg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='36' cy='36' r='36' fill='#224FDF' />
      <path d='M36 18.439V53.561' stroke='white' strokeWidth='6' strokeLinecap='round' />
      <path d='M53.561 36L18.4391 36' stroke='white' strokeWidth='6' strokeLinecap='round' />
    </svg>
  );
};
