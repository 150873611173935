import { Col, Row } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { useRouteContext } from '../../Contexts';
import { AppRoutes } from '../../Model';
import { MenuItem } from './Menu';

interface Props {
  item: MenuItem;
}

export const NavigationItem: React.FC<Props> = ({ item }) => {
  const [activeAnimation, setActiveAnimation] = useState<String | null>(null);
  const { handleNavigation } = useRouteContext();
  const location = useLocation();

  const handleClickNavigation = (path: AppRoutes) => {
    handleNavigation(path);
    setActiveAnimation(path);
    setTimeout(() => {
      setActiveAnimation(null);
    }, 250);
  };

  const isActiveTab = (url: MenuItem['url']) => {
    if (location.pathname !== '/') {
      if (url === '/') return false;
      return location.pathname.includes(url.slice(1, location.pathname.length));
    }
    return url === '/';
  };

  return (
    <StyledIconContainer
      onClick={() => handleClickNavigation(item.url)}
      $active={isActiveTab(item.url)}
      activeAnimation={Boolean(activeAnimation)}
    >
      <Row style={{ textAlign: 'center' }} align='middle' justify='center'>
        <Col span={24}>{isActiveTab(item.url) ? item.activeIcon : item.icon}</Col>
        <Col span={24}>
          <span>{item.title}</span>
        </Col>
      </Row>
    </StyledIconContainer>
  );
};

const StyledIconContainer = styled.div<{ $active?: boolean; activeAnimation?: boolean }>`
  width: 60px;
  svg {
    font-size: 32px;
    fill: ${({ $active }) => (!$active ? '#9ea4c7' : '#224fdf')};
  }
  .ant-col:nth-of-type(2) {
    color: ${({ $active }) => (!$active ? '#9ea4c7' : '#224fdf')};
    transform: translateY(-4px);
    font-size: 10px;
    font-weight: 600;
  }
  .ant-drawer-mask {
    touch-action: none;
  }
`;
