import styled from 'styled-components';

interface Props {}

export const InsightsIllustration: React.FC<Props> = () => {
  return (
    <StyledInsightsIllustration>
      <svg width='80' height='71' viewBox='0 0 100 81' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M71.54 10.8741C65.849 10.8741 61.22 15.504 61.22 21.1941C61.22 26.8843 65.849 31.5142 71.54 31.5142C77.2311 31.5142 81.8601 26.8843 81.8601 21.1941C81.8592 15.5031 77.2302 10.8741 71.54 10.8741ZM71.54 29.0137C67.2285 29.0137 63.7207 25.5059 63.7207 21.1944C63.7207 16.8819 67.2294 13.375 71.54 13.375C75.8516 13.375 79.3594 16.8828 79.3594 21.1944C79.3594 25.5059 75.8507 29.0137 71.54 29.0137Z'
          fill='#224FDF'
        />
        <path
          d='M99.6581 20.3339C98.413 19.0147 97.1139 17.787 95.8041 16.6866C94.3515 15.4572 92.8247 14.3297 91.2702 13.3384C85.1232 9.38686 78.4844 7.38397 71.5402 7.38397C64.5961 7.38397 57.9582 9.38679 51.8156 13.3357C50.2592 14.328 48.7326 15.4554 47.2817 16.683C45.9668 17.7869 44.6685 19.0154 43.4235 20.3337C42.9683 20.815 42.9683 21.5683 43.4235 22.0505C44.6694 23.3697 45.9685 24.5973 47.2775 25.6969C48.7004 26.9019 50.1948 28.0067 51.7183 28.9848C51.7488 29.0092 51.7802 29.0319 51.8133 29.0528C57.963 32.9992 64.6018 35.0003 71.5415 35.0003C78.4812 35.0003 85.1191 32.9993 91.2706 29.0537C91.3038 29.0328 91.3351 29.0101 91.3648 28.9866C92.8906 28.0074 94.3851 26.9009 95.8011 25.7011C97.1143 24.5981 98.4134 23.3705 99.6594 22.0511C100.114 21.5681 100.114 20.8156 99.6585 20.3334L99.6581 20.3339ZM94.1885 23.7892C92.8222 24.9463 91.3879 26.0065 89.9222 26.9403C89.8891 26.9612 89.856 26.9848 89.8255 27.0092C84.1101 30.6529 77.9595 32.4998 71.5412 32.4998C65.1229 32.4998 58.9732 30.653 53.2587 27.0101C53.2274 26.9857 53.1951 26.9621 53.1611 26.9403C51.6971 26.0074 50.2611 24.9471 48.8886 23.7857C47.9373 22.987 46.9939 22.1168 46.0723 21.1917C46.9931 20.2657 47.9374 19.3964 48.8921 18.5951C50.2619 17.4363 51.697 16.3769 53.1647 15.4413C58.901 11.7538 65.0838 9.88375 71.5413 9.88375C77.9989 9.88375 84.1816 11.754 89.9224 15.444C91.3873 16.3769 92.8224 17.4372 94.1941 18.5986C95.1453 19.3973 96.0896 20.2675 97.0103 21.1926C96.0878 22.1177 95.1435 22.9879 94.1887 23.7892L94.1885 23.7892Z'
          fill='#224FDF'
        />
        <path
          d='M49.933 11.9612C50.9671 11.2375 52.0335 10.5626 53.1093 9.95399C58.8589 6.66853 65.0608 5.00224 71.5418 5.00224C78.0228 5.00224 84.2248 6.66848 89.9796 9.95645C91.0512 10.5633 92.1185 11.2382 93.1508 11.961C93.3679 12.1136 93.6182 12.1868 93.8658 12.1868C94.2599 12.1868 94.6479 12.0011 94.8912 11.6541C95.287 11.0882 95.1501 10.3087 94.5834 9.91286C93.4874 9.14469 92.3521 8.42798 91.2151 7.78361C85.0812 4.27939 78.4632 2.5024 71.5418 2.5024C64.6204 2.5024 58.0017 4.27937 51.8739 7.78093C50.7334 8.42615 49.5982 9.14288 48.4996 9.91192C47.9328 10.3078 47.7968 11.0873 48.1918 11.6532C48.5876 12.219 49.3697 12.3559 49.933 11.9609L49.933 11.9612Z'
          fill='#224FDF'
        />
        <path
          d='M6.25035 73.7509H16.2505C16.9411 73.7509 17.5008 73.1911 17.5008 72.5006V40.7374C17.5008 40.0468 16.9419 39.487 16.2505 39.487H6.25035C5.55977 39.487 5 40.0468 5 40.7374L5.00087 72.5028C5.00087 73.1925 5.55977 73.7523 6.25035 73.7523V73.7509ZM7.50069 41.9877H15.0011L15.0002 71.2532H7.49982L7.50069 41.9877Z'
          fill='#224FDF'
        />
        <path
          d='M22.5002 72.5009C22.5002 73.1915 23.0591 73.7513 23.7505 73.7513H33.7507C34.4413 73.7513 35.001 73.1915 35.001 72.5009L35.0002 32.5012C35.0002 31.8106 34.4413 31.2509 33.7498 31.2509H23.7497C23.0591 31.2509 22.4993 31.8106 22.4993 32.5012L22.5002 72.5009ZM25 33.7512H32.5004V71.2509H25V33.7512Z'
          fill='#224FDF'
        />
        <path
          d='M38.7501 40.0011V72.5009C38.7501 73.1915 39.309 73.7513 40.0004 73.7513H50.0006C50.6911 73.7513 51.2509 73.1915 51.2509 72.5009L51.25 40.0011C51.25 39.3106 50.6911 38.7508 49.9997 38.7508H39.9995C39.309 38.7508 38.7501 39.3106 38.7501 40.0011V40.0011ZM41.2498 41.2515H48.7502V71.2513H41.2498V41.2515Z'
          fill='#224FDF'
        />
        <path
          d='M61.2499 77.5009H1.25035C0.559775 77.5009 0 78.0607 0 78.7512C0 79.4418 0.558904 80.0016 1.25035 80.0016H61.2499C61.9405 80.0016 62.5003 79.4418 62.5003 78.7512C62.4994 78.0607 61.9405 77.5009 61.2499 77.5009Z'
          fill='#224FDF'
        />
        <path
          d='M68.7499 48.7511C69.4405 48.7511 70.0002 48.1913 70.0002 47.5007C70.0002 46.8102 69.4413 46.2504 68.7499 46.2504H67.4995V45C67.4995 44.3095 66.9406 43.7497 66.2492 43.7497C65.5586 43.7497 64.9988 44.3095 64.9988 45V46.2504H63.7485C63.0579 46.2504 62.4981 46.8102 62.4981 47.5007C62.4981 48.1913 63.057 48.7511 63.7485 48.7511H64.9988V50.0014C64.9988 50.692 65.5577 51.2518 66.2492 51.2518C66.9398 51.2518 67.4995 50.692 67.4995 50.0014V48.7511H68.7499Z'
          fill='#224FDF'
        />
        <path
          d='M91.2497 66.251H89.9994V65.0006C89.9994 64.31 89.4404 63.7503 88.749 63.7503C88.0584 63.7503 87.4986 64.31 87.4986 65.0006V66.251H86.2483C85.5577 66.251 84.998 66.8107 84.998 67.5013C84.998 68.1919 85.5569 68.7517 86.2483 68.7517H87.4986V70.002C87.4986 70.6926 88.0576 71.2524 88.749 71.2524C89.4396 71.2524 89.9994 70.6926 89.9994 70.002V68.7517H91.2497C91.9403 68.7517 92.5 68.1919 92.5 67.5013C92.5 66.8099 91.9403 66.251 91.2497 66.251Z'
          fill='#224FDF'
        />
        <path
          d='M33.7501 11.2513H35.0005V12.5017C35.0005 13.1923 35.5594 13.752 36.2508 13.752C36.9414 13.752 37.5011 13.1923 37.5011 12.5017V11.2513H38.7515C39.4421 11.2513 40.0019 10.6916 40.0019 10.001C40.0019 9.31043 39.4429 8.75065 38.7515 8.75065H37.5011V7.5003C37.5011 6.80973 36.9422 6.24995 36.2508 6.24995C35.5602 6.24995 35.0005 6.80973 35.0005 7.5003V8.75065H33.7501C33.0595 8.75065 32.4998 9.31043 32.4998 10.001C32.5006 10.6916 33.0595 11.2513 33.7501 11.2513Z'
          fill='#224FDF'
        />
        <path
          d='M7.50032 5.00139H8.75067V6.25174C8.75067 6.94232 9.30957 7.50209 10.001 7.50209C10.6916 7.50209 11.2514 6.94232 11.2514 6.25174V5.00139H12.5017C13.1923 5.00139 13.7521 4.44162 13.7521 3.75104C13.7521 3.06047 13.1932 2.5007 12.5017 2.5007H11.2514V1.25035C11.2514 0.559773 10.6925 0 10.001 0C9.31044 0 8.75067 0.559773 8.75067 1.25035V2.5007H7.50032C6.80974 2.5007 6.24997 3.06047 6.24997 3.75104C6.24997 4.44162 6.80887 5.00139 7.50032 5.00139Z'
          fill='#224FDF'
        />
        <path
          d='M73.7498 57.501C70.9921 57.501 68.7503 59.7445 68.7503 62.5005C68.7503 65.2577 70.9929 67.5001 73.7498 67.5001C76.5076 67.5001 78.7494 65.2566 78.7494 62.5005C78.7494 59.7445 76.5067 57.501 73.7498 57.501ZM73.7498 65.0014C72.3713 65.0014 71.2501 63.8801 71.2501 62.5017C71.2501 61.1232 72.3722 60.0019 73.7498 60.0019C75.1284 60.0019 76.2496 61.1232 76.2496 62.5017C76.2496 63.8801 75.1275 65.0014 73.7498 65.0014Z'
          fill='#224FDF'
        />
        <path
          d='M17.5002 27.5012C20.258 27.5012 22.4997 25.2577 22.4997 22.5017C22.4997 19.7446 20.2571 17.5022 17.5002 17.5022C14.7424 17.5022 12.5007 19.7457 12.5007 22.5017C12.4998 25.2579 14.7424 27.5012 17.5002 27.5012ZM17.5002 20.0008C18.8787 20.0008 20 21.1221 20 22.5006C20 23.8791 18.8778 25.0004 17.5002 25.0004C16.1217 25.0004 15.0005 23.8791 15.0005 22.5006C14.9996 21.1221 16.1217 20.0008 17.5002 20.0008Z'
          fill='#224FDF'
        />
      </svg>
    </StyledInsightsIllustration>
  );
};

const StyledInsightsIllustration = styled.div``;
