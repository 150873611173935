import { Typography } from 'antd';
import { Card } from 'antd-mobile';
import styled from 'styled-components';
import { MoneyFormatter } from '../../Utils';
import {
  CashflowStale,
  IncomeStale,
  PaymentsStale,
  PaymentsDown,
  PaymentsUp,
  IncomeDown,
  IncomeUp,
  CashflowDown,
  CashflowUp,
} from '../../Design';

export enum TrendType {
  Payment = 'Payments',
  Cashflow = 'Cashflow',
  Income = 'Income',
}

interface Props {
  amount: number;
  type: TrendType;
}

const IconRenderer = (amount: number) => ({
  [TrendType.Cashflow]: Math.round(amount) === 0 ? <CashflowStale /> : amount > 0 ? <CashflowUp /> : <CashflowDown />,
  [TrendType.Income]: Math.round(amount) === 0 ? <IncomeStale /> : amount > 0 ? <IncomeUp /> : <IncomeDown />,
  [TrendType.Payment]: Math.round(amount) === 0 ? <PaymentsStale /> : amount > 0 ? <PaymentsDown /> : <PaymentsUp />,
});

export const PlaygroundTrend: React.FC<Props> = ({ amount, type }) => {
  return (
    <StyledPlaygroundTrend>
      <div style={{ textAlign: 'center' }}>
        <div>{IconRenderer(amount)[type]}</div>
        <div style={{ marginBottom: -4 }}>{<AmountRenderer amount={amount} />}</div>
        <Typography.Text type='secondary'>{type}</Typography.Text>
      </div>
    </StyledPlaygroundTrend>
  );
};

const AmountRenderer = ({ amount }: { amount: number }) => {
  const isPositive = amount > 0;
  return (
    <StyledAmount>
      {isPositive ? '+' : null}
      {MoneyFormatter.format(amount)}
    </StyledAmount>
  );
};

const StyledAmount = styled(Typography.Paragraph)`
  margin: 0px !important;
  font-weight: bold;
`;

const StyledPlaygroundTrend = styled(Card)`
  width: 29vw;
  h5 {
    margin: 0px;
    margin-bottom: -4px;
  }
`;
