import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import { Categories_Design, CommonIcons, theme } from '../../Design';
import { Category, Transaction } from '../../Model';
import { MoneyFormatter } from '../../Utils';

interface Props {
  transaction: Transaction;
  isDue?: boolean;
}

export const SwipeActionItem: React.FC<Props> = ({ transaction, isDue }) => {
  const { IncomeIcon } = CommonIcons;
  return (
    <StyledSwipeActionItem align='middle'>
      <Col flex='1 150px '>
        <Row gutter={[8, 8]} wrap={false} align='middle'>
          <Col>
            {transaction.category && transaction.type !== 'Income' ? (
              <StyledIcon $color={Categories_Design[transaction.category! as Category].chartColor}>
                {Categories_Design[transaction.category! as Category].icon}
              </StyledIcon>
            ) : transaction.recurrent ? (
              <StyledIcon $color='#237804'>{<IncomeIcon />}</StyledIcon>
            ) : (
              <StyledIcon $color='#237804'>{<IncomeIcon />}</StyledIcon>
            )}
          </Col>
          <Col>
            <StyledTitle ellipsis>{transaction.name}</StyledTitle>
            {transaction.category && <StyledCategoryTypo type='secondary'>{transaction.category}</StyledCategoryTypo>}
          </Col>
        </Row>
      </Col>
      <Col flex='min-content'>
        <Row justify='end' align='middle'>
          <Col style={{ textAlign: 'right', color: theme.colors.primaryShades['primary-9'] }} span={24}>
            <Typography.Text style={{ maxWidth: 110 }} ellipsis>
              {transaction.type === 'Payment' && !transaction.recurrent ? '-' : transaction.type === 'Income' ? '+' : ''}
              {MoneyFormatter.format(transaction.amount)}
            </Typography.Text>
          </Col>
          {isDue ? (
            <StyledDue>
              <Typography.Text italic>Due</Typography.Text>
            </StyledDue>
          ) : transaction.frequency && transaction.recurrent ? (
            <Col style={{ textAlign: 'right' }} span={24}>
              <StyledCategoryTypo type='secondary'>{transaction.frequency}</StyledCategoryTypo>
            </Col>
          ) : null}
        </Row>
      </Col>
    </StyledSwipeActionItem>
  );
};

const StyledDue = styled.div`
  display: flex;
  align-items: center;
  svg {
    fill: #cd2113 !important;
    font-size: 16px !important;
    margin: 0px !important;
  }
  .ant-typography {
    color: #cd2113;
    font-weight: 450;
  }
`;

const StyledTitle = styled(Typography.Text)`
  display: block !important;
  max-width: 140px !important;
  margin-bottom: -4px;
`;

const StyledCategoryTypo = styled(Typography.Text)`
  font-size: 14px;
`;

const StyledSwipeActionItem = styled(Row)<{ $iconColor?: string }>`
  .ant-row {
    min-height: 54px;
  }
`;

const StyledIcon = styled.div<{ $color?: string }>`
  height: 28px;
  svg {
    color: ${({ $color }) => $color};
    margin: 0px;
    font-size: 28px;
  }
`;
