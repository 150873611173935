import styled from 'styled-components';

interface Props {}

export const PaymentsUp: React.FC<Props> = () => {
  return (
    <StyledPaymentsUp>
      <svg width='61' height='61' viewBox='0 0 61 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.964508' y='0.288574' width='60' height='60' rx='16' fill='#F6FFED' />
        <path
          d='M14.8596 29.8215C14.3666 27.6681 15.7127 25.5227 17.8662 25.0298L38.8613 20.2238C41.0147 19.7308 43.16 21.0769 43.653 23.2303L43.7489 23.6495L14.9555 30.2407L14.8596 29.8215Z'
          fill='#389E0D'
        />
        <path
          d='M15.4952 32.5984L44.2886 26.0072L46.5517 35.8932C47.0446 38.0467 45.6985 40.192 43.5451 40.6849L22.55 45.491C20.3966 45.9839 18.2512 44.6378 17.7583 42.4844L15.4952 32.5984Z'
          fill='#389E0D'
        />
        <rect x='17.6218' y='34.415' width='6.44046' height='4.38508' rx='2' transform='rotate(-12.8936 17.6218 34.415)' fill='#F6FFED' />
        <path d='M39.384 12.5938V16.6019' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M11.7528 45.374L14.3348 44.6822' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M14.9949 50.989L16.5039 48.1995' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M20.9828 51.1714L20.291 48.5894' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M41.3881 14.5979L37.3799 14.5979' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M45.8621 7.40649V11.4146' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M47.8662 9.41064L43.858 9.41064' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M47.8662 13.3291V19.8748' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M51.1391 16.6021L44.5933 16.6021' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
      </svg>
    </StyledPaymentsUp>
  );
};

const StyledPaymentsUp = styled.div``;
