import { useState } from 'react';
import { Transaction } from '../Model/Transaction';
import {
  addTransaction as addTransactionApi,
  updateTransaction as updateTransactionApi,
  deleteTransaction as deleteTransactionApi,
  auth,
} from '../Api';
import { useMutation } from 'react-query';
import { useIonToast } from '@ionic/react';
import firebase from 'firebase/compat/app';
import { Toast } from 'antd-mobile';

interface Config {
  onCreateSuccessCallback?: () => void;
  onUpdateSuccessCallback?: () => void;
  onDeleteSuccessCallback?: () => void;
}

export const useTransactions = (config?: Config) => {
  const uid = auth.currentUser?.uid;
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [present] = useIonToast();

  const addTransactionMutation = useMutation({
    mutationFn: ({ transaction }: { transaction: Transaction }) => {
      return addTransactionApi(transaction, uid!);
    },
    onSuccess: async () => {
      Toast.show({
        icon: 'success',
        content: 'Created',
      });
      config?.onCreateSuccessCallback && config.onCreateSuccessCallback();
    },
    onMutate: () => {
      Toast.show({
        icon: 'loading',
        content: 'Loading',
      });
      setIsLoadingCreate(true);
    },
    onSettled: () => setIsLoadingCreate(false),
    onError: error => {
      present({
        message: `Error while creating', ${(error as firebase.FirebaseError).message}`,
        duration: 3000,
        position: 'top',
        color: 'danger',
      });
    },
  });

  const updateTransactionMutation = useMutation({
    mutationFn: ({ transaction }: { transaction: Transaction }) => {
      return updateTransactionApi(transaction, uid!);
    },
    onSuccess: async () => {
      config?.onUpdateSuccessCallback && config.onUpdateSuccessCallback();
      Toast.show({
        icon: 'success',
        content: 'Updated',
      });
    },
    onMutate: () => {
      Toast.show({
        icon: 'loading',
        content: 'Loading',
      });
      setIsLoadingUpdate(true);
    },
    onSettled: () => setIsLoadingUpdate(false),
    onError: error => {
      present({
        message: `Error while updating', ${(error as firebase.FirebaseError).message}`,
        duration: 3000,
        position: 'top',
        color: 'danger',
      });
    },
  });

  const deleteTransactionMutation = useMutation({
    mutationFn: ({ transaction }: { transaction: Transaction }) => {
      return deleteTransactionApi(transaction, uid!);
    },
    onMutate: () => {
      Toast.show({
        icon: 'loading',
        content: 'Loading',
      });
    },
    onSuccess: () => {
      Toast.show({
        icon: 'success',
        content: 'Deleted',
      });
    },
    onError: error => {
      present({
        message: `Error while deleting', ${(error as firebase.FirebaseError).message}`,
        duration: 3000,
        position: 'top',
        color: 'danger',
      });
    },
  });

  const deleteTransaction = (transaction: Transaction) => deleteTransactionMutation.mutate({ transaction });
  const updateTransaction = (transaction: Transaction) => {
    updateTransactionMutation.mutate({ transaction });
  };
  const addTransaction = (transaction: Transaction) => {
    addTransactionMutation.mutate({ transaction });
  };

  return {
    addTransaction,
    isLoadingCreate,
    updateTransaction,
    isLoadingUpdate,
    deleteTransaction,
  };
};
