import styled from 'styled-components';
import { useScreenAnimation } from '../Hooks';
import { PaymentsOverview as PaymentsOverviewList, ScrollableScreenContainer, SwipeBackContainer } from '../Components';
import { getPastTransactions } from '../Utils';
import { motion } from 'framer-motion';
import { useTransactionsContext } from '../Contexts';

export const PaymentsOverviewPage: React.FC = () => {
  const { transactionsList } = useTransactionsContext();

  const pastPayments = getPastTransactions(transactionsList, 'Payment');

  const animation = useScreenAnimation();

  return (
    <StyledAnimatedContainer {...animation}>
      <SwipeBackContainer>
        <ScrollableScreenContainer smallPadding>
          <PaymentsOverviewList pastPayments={pastPayments} />
        </ScrollableScreenContainer>
      </SwipeBackContainer>
    </StyledAnimatedContainer>
  );
};

const StyledAnimatedContainer = styled(motion.div)`
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
`;

export default PaymentsOverviewPage;
