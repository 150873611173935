import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { theme } from '../Design';
import { FAKE_HEADER_HEIGHT } from '../Pages';

interface Props {
  headerColor: string;
}

export const FakeHeader: React.FC<PropsWithChildren & Props> = ({ children, headerColor }) => {
  return <StyledFakeHeader $color={headerColor}>{children}</StyledFakeHeader>;
};

const StyledFakeHeader = styled.div<{ $color: string }>`
  text-align: center;
  padding-top: calc(env(safe-area-inset-top) + 12px) !important;
  padding-right: calc(env(safe-area-inset-right) + 12px) !important;
  padding-left: calc(env(safe-area-inset-left) + 12px) !important;
  width: 100%;
  height: calc(${FAKE_HEADER_HEIGHT}px + env(safe-area-inset-top));
  background-color: ${({ $color }) => $color || theme.colors.primary};
  z-index: 9999;
  touch-action: none;
  font-weight: 500;
  font-size: 16px;
`;
