import './App.css';
import { AppRouter } from './Components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './Contexts';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
// import '@ionic/react/css/normalize.css';
// import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Theme variables */
import './Design/variables.css';

/* Antd CSS */
import 'antd/dist/antd.min.css';
import { useEffect } from 'react';

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log(process.env);
      document.title = 'Viktoria - Localhost';
    }
    // navigator.serviceWorker.ready.then(e => console.log(e));
  }, []);

  return (
    <div className='App'>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
