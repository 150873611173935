import styled from 'styled-components';
import background from '../Design/betaLaunchBackground.png';
import { HeaderIcon } from '../Design/Logo/HeaderIcon';
import { Typography } from 'antd';
import { ComingSoonRocket, theme } from '../Design';
import { useMobileView } from '../Hooks';

interface Props {
  setShowBeta: (show: boolean) => void;
}

export const BetaLaunchingSoon: React.FC<Props> = ({ setShowBeta }) => {
  const { showBackgroundBetaLaunch } = useMobileView();
  return (
    <StyledBetaLaunchingSoon $background={showBackgroundBetaLaunch ? background : null}>
      <StyledLogo onClick={() => setShowBeta(false)} level={4}>
        <HeaderIcon />
        Viktoria
      </StyledLogo>
      <StyledContent>
        <ComingSoonRocket />
        <Typography.Title>Beta launching</Typography.Title>
        <Typography.Title>soon</Typography.Title>
      </StyledContent>
    </StyledBetaLaunchingSoon>
  );
};

const StyledLogo = styled(Typography.Title)`
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
  svg {
    height: 18px;
  }
  padding: 2rem;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const StyledContent = styled.div`
  svg {
    height: 30vh;
    width: 30vh;
    margin-left: -2px;
    stroke-width: 100px;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
  }
  h1 {
    color: #fff !important;
    margin: 0px;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 1px;
  }
  h1:nth-of-type(2) {
    margin: 0px;
    font-size: 3rem;
  }
  h1:nth-of-type(1) {
    margin-top: 8px;
  }
  button {
    margin-top: 48px;
    border-radius: 48px;
    font-weight: 600;
    color: #041257;
  }
`;

const StyledBetaLaunchingSoon = styled.div<{ $background: string | null }>`
  padding-top: ${({ $background }) => ($background ? '25vh' : '15vh')};
  text-align: center;
  height: 100vh;
  background: -webkit-linear-gradient(90deg, #081c7d 0, #224fdf 51%, #041257 100%);
  background: linear-gradient(90deg, #041257 0, #224fdf 51%, #041257 100%);
  background-size: 250% auto;
  ::before {
    background-image: ${({ $background }) => `url(${$background || ''})`};
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;
