import { Col, Row, Typography } from 'antd';
import { Card } from 'antd-mobile';
import styled from 'styled-components';
import { theme } from '../../Design';
import { ClickIndicator } from '../ClickIndicator';

interface Props {
  icon: React.ReactElement;
  title: string;
  description: string;
  onClick?: () => void;
}

export const CardButton: React.FC<Props> = ({ icon, title, onClick, description }) => {
  return (
    <StyledCardButton onClick={onClick}>
      <Row gutter={[8, 12]} justify='space-between' align='middle'>
        <Col flex='90px'>{icon}</Col>
        <Col flex='1'>
          <StyledIndicatorContainer>
            <ClickIndicator />
          </StyledIndicatorContainer>
          <Typography.Title level={4}>{title}</Typography.Title>
          <Typography.Paragraph style={{ marginBottom: 0 }} type='secondary'>
            {description}
          </Typography.Paragraph>
        </Col>
      </Row>
    </StyledCardButton>
  );
};

const StyledIndicatorContainer = styled.div`
  float: right;
`;

const StyledCardButton = styled(Card)`
  .adm-card-body {
    padding: 16px 16px;
    width: 100%;
  }
  h4 {
    margin-bottom: 0px !important;
    color: ${theme.colors.primary}!important;
  }
  :active {
    cursor: pointer;
    background-color: ${theme.colors.primaryShades['primary-1']};
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;
