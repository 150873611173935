import dayjs from "dayjs"
import { Frequency, Category } from "../Model"
import { Transaction } from "../Model/Transaction"
import { getNextDate, isTodayOrPast } from "./date-utils"

/**
 * Returns if the transaction is upcoming
 * @param transaction 
 * @returns boolean
 */
export const isUpcoming = (transaction: Transaction) => (transaction.recurrent && !transaction.paid) || !isTodayOrPast(transaction.date)

/**
 * Returns if the transaction is past
 * @param transaction 
 * @returns boolean
 */
export const isPast = (transaction: Transaction) => !(transaction.recurrent && !transaction.paid) && isTodayOrPast(transaction.date)

/**
 * Filters transactions array and returns only the past one
 * @param transactions 
 * @param type 
 * @returns filtered transactions array sorted in descendant order
 */
export const getPastTransactions = (transactions: Transaction[], type?: Transaction['type']) => transactions.filter(t => type ? isPast(t) && type === t.type : isPast(t)).sort((a, b) => (a.date > b.date ? -1 : 1));

/**
 * Filters transactions array and returns only the upcoming one
 * @param transactions 
 * @param type 
 * @returns filtered transactions array sorted in ascendant order
 */
export const getUpcomingTransactions = (transactions: Transaction[], type?: Transaction['type']) => transactions.filter(t => type ? isUpcoming(t) && type === t.type : isUpcoming(t)).sort((a, b) => (a.date > b.date ? 1 : -1))

/**
 * Get the occurences count in a given month
 * @param date 
 * @param frequency 
 * @returns count (number)
 */
export const getMonthlyOccurencesCount = (date: string, frequency: Frequency) => {
  let nextEventDate = getNextDate(date, frequency).format('YYYY-MM-DD');
  const yearMonth = dayjs().format('YYYY-MM');
  const lastDayOfMonth = new Date(Number(yearMonth.slice(0, 4)), Number(yearMonth.slice(5, 7)), 0).getDate();
  let count = 1;
  while (`${yearMonth}-${lastDayOfMonth}` > nextEventDate) {
    count = count + 1;
    nextEventDate = getNextDate(nextEventDate, frequency).format('YYYY-MM-DD');
  }
  return count;
};

/**
 * Get all the occurences of all transactions in a given array
 * @param transactions 
 * @returns Transactions array including occurences projection
 */
export const getMonthlyOccurences = (transactions: Transaction[]) => transactions
  .reduce((acc, curr) => {
    if (curr.recurrent) {
      const repetition = getMonthlyOccurencesCount(curr.date, curr.frequency!);
      const temp = [curr];
      for (let i = 1; i < repetition; i++) {
        temp.push({ ...curr, date: getNextDate(temp[i - 1].date, curr.frequency!).format('YYYY-MM-DD') });
      }
      return [...acc, ...temp];
    }
    return [...acc, curr];
  }, [] as Transaction[]).sort((t1, t2) => t1.date > t2.date ? 1 : -1);

/**
 * Returns the total sum of the given transctions
 * @param transactions 
 * @returns sum of transctions
 */
export const getTransactionsTotal = (transactions: Transaction[]) => transactions.reduce((acc, curr) => acc + curr.amount, 0);

export interface PaymentByCategory {
  count: number;
  total: number;
  transactions: Transaction[]
  recurrent: number
}

/**
 * Get transactions details by category
 * @param transactions 
 * @returns Hashmap of categories and count/total/transactions/recurrent count
 */
export const getTransactionsByCategory = (transactions: Transaction[]) => transactions.reduce<Record<Category, PaymentByCategory>>((acc, curr) => {
  const isRecurrentCount = (recurrent: boolean): number => recurrent ? 1 : 0
  if (acc[curr.category!]) {
    return {
      ...acc,
      [curr.category!]: {
        count: acc[curr.category!].count + 1,
        total: acc[curr.category!].total + curr.amount,
        transactions: [...acc[curr.category!].transactions, curr],
        recurrent: acc[curr.category!].recurrent + isRecurrentCount(curr.recurrent)
      }
    }
  }
  return {
    ...acc,
    [curr.category!]: {
      count: 1,
      total: curr.amount,
      transactions: [curr],
      recurrent: isRecurrentCount(curr.recurrent)
    },
  };
}, {} as Record<Category, PaymentByCategory>);
