import styled from 'styled-components';

interface Props {}

export const PaymentsDown: React.FC<Props> = () => {
  return (
    <StyledPaymentsDown>
      <svg width='61' height='61' viewBox='0 0 61 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.186966' y='0.354492' width='60' height='60' rx='16' fill='#FFF2F0' />
        <path
          d='M27.8777 27.4836H12.9861L12.9862 38.3395C12.9862 40.5487 14.777 42.3395 16.9862 42.3395H30.0199L26.9356 34.4491L29.3844 29.2426L27.8777 27.4836Z'
          fill='#CD2113'
          stroke='#CD2113'
        />
        <rect x='15.1806' y='29.1923' width='7.17619' height='4.8541' rx='1.95' fill='white' stroke='#CD2113' strokeWidth='0.1' />
        <path
          d='M37.2528 31.1136L35.9291 28.636L47.7815 31.2227L45.2108 43.002C44.7398 45.1604 42.6083 46.5282 40.4499 46.0572L35.0518 44.8791L33.4305 36.443L37.2528 31.1136Z'
          fill='#CD2113'
          stroke='#CD2113'
          strokeWidth='0.1'
        />
        <path
          d='M24.8646 23.3397H13.0377V22.7769C13.0377 20.8439 14.6047 19.2769 16.5377 19.2769H21.3566L24.8646 23.3397Z'
          fill='#CD2113'
          stroke='#CD2113'
        />
        <path
          d='M34.5096 24.8365L32.0337 20.2434L45.4446 23.1702C47.3332 23.5823 48.53 25.4474 48.1179 27.336L48.0199 27.785L34.5096 24.8365Z'
          fill='#CD2113'
          stroke='#CD2113'
        />
      </svg>
    </StyledPaymentsDown>
  );
};

const StyledPaymentsDown = styled.div``;
