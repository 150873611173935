interface Props {}

export const ConnectIllustration: React.FC<Props> = () => {
  return (
    <svg width='70' height='70' viewBox='0 0 91 91' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M30.9218 54.4241C30.9218 55.9427 31.5122 57.3628 32.5809 58.4316C33.6497 59.5005 35.0699 60.0907 36.5885 60.0907C38.0928 60.0907 39.5272 59.5004 40.596 58.4316L58.6915 40.3079C60.8988 38.1007 60.8988 34.5152 58.6915 32.308C57.6228 31.2393 56.2026 30.6489 54.684 30.6489C53.1797 30.6489 51.7595 31.2392 50.6765 32.308L32.5809 50.4317C31.498 51.4861 30.9218 52.92 30.9218 54.4243V54.4241ZM34.563 52.4135L52.6734 34.3031C53.2075 33.7691 53.9247 33.4733 54.684 33.4733C55.4433 33.4733 56.1605 33.7684 56.6946 34.3031C57.8053 35.4139 57.8053 37.2133 56.6946 38.3242L38.5858 56.4347C37.5028 57.5176 35.6335 57.5034 34.5646 56.4347C34.0306 55.9006 33.7348 55.1834 33.7348 54.4241C33.7348 53.6648 34.03 52.9476 34.564 52.4129L34.563 52.4135Z'
        fill='#224FDF'
      />
      <path
        d='M83.1553 58.5436L66.2694 54.1846C65.5101 53.9879 64.7509 54.4377 64.554 55.197C64.3572 55.9563 64.8071 56.7156 65.5664 56.9125L82.4522 61.2715C82.5649 61.2993 82.6911 61.3135 82.8037 61.3135C83.4361 61.3135 83.9986 60.8915 84.1676 60.2591C84.3501 59.514 83.9003 58.7405 83.1552 58.5436H83.1553Z'
        fill='#224FDF'
      />
      <path
        d='M57.172 65.293C56.9752 64.548 56.2159 64.0839 55.4565 64.2806C54.7115 64.4774 54.2474 65.2367 54.4441 65.9961L58.8031 82.8819C58.9721 83.5143 59.5346 83.9364 60.167 83.9364C60.2797 83.9364 60.4059 83.9221 60.5185 83.8943C61.2636 83.6975 61.7277 82.9382 61.5309 82.1789L57.172 65.293Z'
        fill='#224FDF'
      />
      <path
        d='M63.3033 61.0475C62.755 60.4993 61.8688 60.4993 61.3205 61.0475C60.7723 61.5958 60.7723 62.4819 61.3205 63.0302L72.794 74.5037C73.0749 74.7846 73.4264 74.9115 73.7921 74.9115C74.1578 74.9115 74.5093 74.771 74.7902 74.5037C75.3385 73.9554 75.3385 73.0693 74.7902 72.521L63.3033 61.0475Z'
        fill='#224FDF'
      />
      <path
        d='M34.0853 25.4315C34.2542 26.0639 34.8167 26.4859 35.4491 26.4859C35.5618 26.4859 35.688 26.4717 35.8006 26.4439C36.5457 26.2471 37.0098 25.4878 36.813 24.7284L32.454 7.84259C32.2572 7.09754 31.4979 6.63342 30.7386 6.8302C29.9935 7.02698 29.5294 7.78626 29.7262 8.54563L34.0853 25.4315Z'
        fill='#224FDF'
      />
      <path
        d='M8.10191 32.1676L24.9878 36.5266C25.1004 36.5544 25.2266 36.5687 25.3392 36.5687C25.9717 36.5687 26.5342 36.1466 26.7031 35.5142C26.8999 34.7549 26.45 33.9956 25.6907 33.7988L8.80488 29.4398C8.0456 29.243 7.2863 29.6929 7.08945 30.4522C6.90691 31.2115 7.35679 31.9844 8.10184 32.1676L8.10191 32.1676Z'
        fill='#224FDF'
      />
      <path
        d='M27.954 29.6786C28.2349 29.9596 28.5864 30.0864 28.9521 30.0864C29.3178 30.0864 29.6693 29.946 29.9503 29.6786C30.4985 29.1304 30.4985 28.2442 29.9503 27.6959L18.4768 16.2225C17.9286 15.6742 17.0424 15.6742 16.4941 16.2225C15.9458 16.7707 15.9458 17.6569 16.4941 18.2052L27.954 29.6786Z'
        fill='#224FDF'
      />
      <path
        d='M47.5823 55.7332C46.8231 55.8879 46.3311 56.6187 46.4716 57.3923C46.6968 58.5031 46.8088 59.5717 46.7952 60.5842C46.7952 64.9568 45.094 69.0769 41.9863 72.1697L31.4132 82.7428C28.3197 85.8363 24.2003 87.5517 19.8277 87.5517H19.8135C15.4409 87.5517 11.3351 85.8505 8.24164 82.757C5.16241 79.6493 3.44698 75.5299 3.44698 71.1573C3.44698 66.7848 5.14816 62.6647 8.25589 59.5719L18.829 48.9988C22.6954 45.1324 28.2215 43.4591 33.6064 44.5279C34.3657 44.6826 35.1107 44.1764 35.2512 43.4171C35.4059 42.6579 34.8997 41.9128 34.1404 41.7723C27.8272 40.5211 21.3458 42.4753 16.8171 47.0025L6.24395 57.5756C2.63131 61.2025 0.634338 66.0248 0.634338 71.1574C0.634338 76.2892 2.63065 81.098 6.27229 84.7398C9.89985 88.3673 14.7086 90.3636 19.8262 90.3636H19.8404C24.9587 90.3636 29.7811 88.3673 33.4086 84.7398L43.9817 74.1667C47.6093 70.5391 49.6056 65.7168 49.6056 60.5985C49.6056 59.4172 49.4794 58.1523 49.2263 56.8584C49.0722 56.0849 48.3408 55.5787 47.5815 55.7334L47.5823 55.7332Z'
        fill='#224FDF'
      />
      <path
        d='M22.7955 52.9758C24.4403 51.331 26.5213 50.2901 28.8412 49.9529C29.6148 49.8403 30.1488 49.1231 30.0361 48.3645C29.9235 47.5909 29.2063 47.0569 28.4477 47.1695C25.5375 47.5916 22.8937 48.9128 20.827 50.9795L10.2539 61.5527C7.68023 64.1256 6.26059 67.5285 6.26059 71.1561C6.26059 74.7836 7.68077 78.1865 10.2396 80.7594C12.7983 83.3182 16.2014 84.7242 19.8288 84.7242C23.4563 84.7242 26.8592 83.304 29.4321 80.7452L40.0053 70.1714C42.0864 68.0903 43.4081 65.4609 43.8295 62.565C43.9422 61.7915 43.4075 61.0885 42.6482 60.9765C41.8889 60.8639 41.1717 61.3986 41.0597 62.1578C40.7225 64.4635 39.668 66.5447 38.0226 68.1893L27.4495 78.7625C25.4104 80.8015 22.7112 81.9123 19.8288 81.9259C16.9605 81.9259 14.2606 80.8151 12.2366 78.7761C10.1976 76.7234 9.07256 74.0242 9.07256 71.1561C9.07256 68.2878 10.1976 65.5743 12.236 63.5354L22.7955 52.9758Z'
        fill='#224FDF'
      />
      <path
        d='M68.4618 37.7487C66.8306 39.3799 64.736 40.4344 62.4303 40.7716C61.6568 40.8842 61.137 41.6014 61.249 42.3743C61.3474 43.0773 61.9519 43.5692 62.6407 43.5692C62.7112 43.5692 62.7811 43.5692 62.8517 43.555C65.7619 43.133 68.3914 41.8117 70.4581 39.745L81.0312 29.1719C83.6042 26.5989 85.0102 23.196 85.0102 19.5685C85.0245 15.9552 83.6043 12.5381 81.0455 9.97934C78.4589 7.42061 75.0559 6.00098 71.4285 6.00098C67.8009 6.00098 64.3845 7.40693 61.8251 9.98L51.252 20.5531C49.1708 22.6343 47.8491 25.2772 47.4277 28.1881C47.3151 28.9616 47.8498 29.6646 48.6226 29.7765C49.3819 29.8892 50.0992 29.3545 50.2111 28.5816C50.5484 26.2759 51.5892 24.1805 53.2483 22.5358L63.8214 11.9627C65.8469 9.93791 68.5468 8.81291 71.4285 8.81291C74.311 8.81291 77.0102 9.93793 79.0491 11.9627C81.0882 14.0018 82.199 16.701 82.199 19.5691C82.199 22.4374 81.0739 25.1509 79.0355 27.1898L68.4618 37.7487Z'
        fill='#224FDF'
      />
      <path
        d='M84.9963 6.00132C77.516 -1.479 65.3266 -1.49258 57.8464 6.00132L47.2733 16.5744C43.6457 20.202 41.6494 25.0243 41.6494 30.1569C41.6494 31.3382 41.7756 32.6031 42.043 33.8969C42.1977 34.6562 42.9285 35.1482 43.7021 34.9935C44.4613 34.8388 44.9533 34.0937 44.7986 33.3344C44.5733 32.2236 44.4613 31.1407 44.4613 30.1425C44.4613 25.7699 46.1625 21.6498 49.2703 18.557L59.8434 7.98391C66.2406 1.60076 76.6314 1.60076 83.0279 7.98391C86.1349 11.091 87.8368 15.1968 87.8225 19.5558C87.8225 23.9284 86.1213 28.0484 83.0136 31.1412L72.4405 41.7143C68.5877 45.5671 63.0623 47.2398 57.6631 46.1852C56.8896 46.0305 56.1588 46.5367 56.0183 47.296C55.8636 48.0552 56.3698 48.8003 57.1291 48.9408C58.3803 49.1796 59.6316 49.3065 60.8692 49.3065C65.9169 49.3065 70.8098 47.3238 74.4373 43.6964L85.0105 33.1233C88.638 29.4957 90.6343 24.6734 90.6343 19.5551C90.635 14.4653 88.638 9.64231 84.9964 6.00117L84.9963 6.00132Z'
        fill='#224FDF'
      />
    </svg>
  );
};
