import React from 'react';
import styled from 'styled-components';

interface Props {}

export const LoginIllustration: React.FC<Props> = () => {
  return (
    <StyledLoginIllustration>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1080'>
        <g id='Base'>
          <path
            className='cls-1'
            d='M461.57,417.49s-7.3-1.28-15.87,18.44-9,31.72-9,31.72l-9.31,199.77a4.22,4.22,0,0,1-1.56,3.11c-2.23,1.81-5.48,4.94-6.79,7.47-2.63,5.06-2.93,12.41-2.93,12.41l-2,34.9s0,5.65,6.53,5.65'
          />
          <path
            className='cls-1'
            d='M409.23,702.55H334c-2.4,0-3.42-1.41-3.39-3.81l1-6.91s-.42-6.47,9.9-16.79L396,625.67s10.55-7,14.43-25.54,25.4-112.73,25.4-112.73'
          />
          <path className='cls-1' d='M408.63,708H333.91a2.75,2.75,0,0,0-2.75,2.75h0a2.75,2.75,0,0,0,2.75,2.75h74.44' />
          <path className='cls-1' d='M407.79,719.62H334.47a2.3,2.3,0,0,0-2.31,2.31h0a2.79,2.79,0,0,0,2.16,2.73l92.48,21.67' />
          <path
            className='cls-1'
            d='M364.64,653.52l5.21-211.47s-.11-13.61,7.92-33.56c7.17-17.84,17-16.6,17-16.6s10.3-1,16.56,16.6c6.92,19.44,6,30.91,6,30.91'
          />
          <path className='cls-1' d='M367.32,734.7v15.48a7,7,0,0,1-1.2,3.91L359.4,764' />
          <path className='cls-1' d='M404.71,741.7v8.48a6.89,6.89,0,0,0,1.21,3.91l6.71,9.87' />
          <rect className='cls-1' x='354.65' y='764.69' width='62.57' height='7.19' rx='3.59' />
          <rect className='cls-1' x='422.31' y='727.37' width='36.16' height='7.19' rx='3.59' />
          <path
            className='cls-1'
            d='M461.57,417.49s7.3-1.28,15.87,18.44,9,31.72,9,31.72l9.31,199.77a4.22,4.22,0,0,0,1.56,3.11c2.23,1.81,5.48,4.94,6.79,7.47,2.63,5.06,2.93,12.41,2.93,12.41l2,34.9s0,5.65-6.53,5.65'
          />
          <path
            className='cls-1'
            d='M513.91,702.55h75.27c2.39,0,3.41-1.41,3.38-3.81l-1-6.91s.42-6.47-9.9-16.79L527.2,625.67s-10.56-7-14.44-25.54-25.4-112.73-25.4-112.73'
          />
          <path className='cls-1' d='M514.51,708h74.72a2.76,2.76,0,0,1,2.76,2.75h0a2.76,2.76,0,0,1-2.76,2.75H514.79' />
          <path className='cls-1' d='M515.35,719.62h73.32a2.3,2.3,0,0,1,2.31,2.31h0a2.79,2.79,0,0,1-2.16,2.73l-92.48,21.67' />
          <path
            className='cls-1'
            d='M558.51,653.52l-5.22-211.47s.11-13.61-7.92-33.56c-7.17-17.84-17-16.6-17-16.6s-10.3-1-16.56,16.6c-6.92,19.44-6,30.91-6,30.91'
          />
          <path
            className='cls-1'
            d='M512.76,600.13l-9.32-213.08s.13-28.12-10.94-53c-9.53-20.74-19.31-29.18-25.26-32.59a11.42,11.42,0,0,0-11.34,0c-5.95,3.41-15.73,11.85-25.25,32.59-11.08,24.86-10.95,53-10.95,53l-9.32,213.08'
          />
          <path className='cls-1' d='M555.82,734.7v15.48a7,7,0,0,0,1.2,3.91l6.72,9.87' />
          <path className='cls-1' d='M518.43,741.7v8.48a6.89,6.89,0,0,1-1.21,3.91L510.51,764' />
          <rect
            className='cls-1'
            x='505.92'
            y='764.69'
            width='62.57'
            height='7.19'
            rx='3.59'
            transform='translate(1074.41 1536.58) rotate(180)'
          />
          <rect
            className='cls-1'
            x='464.67'
            y='727.37'
            width='36.16'
            height='7.19'
            rx='3.59'
            transform='translate(965.5 1461.92) rotate(-180)'
          />
        </g>
        <g id='Traits'>
          <line className='cls-1' x1='458.47' y1='730.96' x2='464.67' y2='730.96' />
          <line className='cls-1' x1='369.66' y1='450.05' x2='416.94' y2='450.05' />
          <line className='cls-1' x1='506.2' y1='450.05' x2='553.48' y2='450.05' />
          <line className='cls-1' x1='419.7' y1='387.05' x2='503.44' y2='387.05' />
          <line className='cls-1' x1='445.7' y1='435.93' x2='477.44' y2='435.93' />
          <line className='cls-1' x1='427.39' y1='669.42' x2='495.75' y2='669.42' />
          <path className='cls-1' d='M446,469.22c2-9.68,8.62-15.71,15.71-15.71s13.83,6.29,15.71,15.71' />
        </g>
        <g id='Nuage'>
          <path className='cls-2' d='M364.64,779.36v32s-10.8-19.07-44.35-17S275,817.61,275,817.61' />
          <path
            className='cls-2'
            d='M308.43,870S275,805.18,222.17,809.6c-71.43,6-80.87,80.39-80.87,80.39s-6.13-41.44-36.67-33.89c-26.93,6.66-19.78,36.79-19.78,36.79s-6.44-12.88-20.8-7.43-12.89,20.36-12.89,20.36'
          />
          <path
            className='cls-2'
            d='M414.14,904.52s-3.9-14,8.17-18.16,18.08,9.08,18.08,9.08-.75-24.76,18.08-24.76,17.19,22.13,17.19,22.13,8.82-16.35,20.09-10.58,10.17,22.29,10.17,22.29'
          />
          <path
            className='cls-2'
            d='M246,975.29s-5.71-46.19,32.85-45.42c37.28.76,29.58,49.63,29.58,49.63s7.68-29.89,28.15-21.27c0,0,15.61,9.33,7.82,29.41,0,0,10.66-17.53,24.17-9.42,10.24,6.14,5.47,19.25,5.47,19.25'
          />
          <line className='cls-2' x1='409.63' y1='776.04' x2='409.63' y2='820.52' />
          <line className='cls-2' x1='516.43' y1='809.95' x2='516.43' y2='776.04' />
          <path className='cls-2' d='M388.47,844s17.33-28.84,51.92-37.64,61.56,2.2,61.56,2.2' />
          <path className='cls-2' d='M558.51,776v84.88s24.15-55.33,80.33-50.91,88.64,58.67,88.64,58.67' />
          <path className='cls-2' d='M514.51,816.44s-5.69-5.73-12.56-7.9' />
          <path
            className='cls-2'
            d='M563.13,799.37S566,784,576.49,786s11.12,12,11.12,12-.25-25.61,21.12-23c0,0,15.43,3.49,13.32,22.49,0,0,8.5-17.79,20.94-12.89,7.36,2.9,8.13,12.63,4.9,18.71'
          />
          <polyline className='cls-2' points='469.5 739.46 469.5 768.29 469.5 779.78' />
          <line className='cls-2' x1='496.34' y1='739.46' x2='496.34' y2='805.16' />
          <line className='cls-2' x1='453.45' y1='739.46' x2='453.45' y2='798.9' />
          <line className='cls-2' x1='426.8' y1='739.46' x2='426.8' y2='810.01' />
          <path className='cls-2' d='M488.36,791.75s-4-8.2-11.84-8.52c-3.95-.17-9,3.95-10.43,9.45,0,0-5.6-8.36-10.62-6.47' />
          <path className='cls-3' d='M518.43,937.1s24.59-66.05,95.91-55.56S700.55,956,700.55,956' />
          <path
            className='cls-2'
            d='M690.58,925.34s10.23-20.08,29.21-12.27c25.83,10.63,17.8,35.06,17.8,35.06s5.91-24.28,26.25-16.09c0,0,14.07,9.19,11.53,25.08'
          />
          <path
            className='cls-2'
            d='M823.42,872.15c-13.51-8.11-24.17,9.42-24.17,9.42,7.78-20.07-7.82-29.41-7.82-29.41-20.48-8.61-28.16,21.27-28.16,21.27s5.46-45.62-28.57-45.62c0,0-14.14.41-20.67,11'
          />
        </g>
        <g id='Grand_rond' data-name='Grand rond'>
          <path
            className='cls-4'
            d='M366.38,202.69A366.65,366.65,0,0,1,458.47,191C668.14,191,838.1,367.83,838.1,586c0,85.94-26.38,165.47-71.17,230.3'
          />
          <path className='cls-4' d='M151,817.61C105.59,752.54,78.84,672.5,78.84,586c0-114.09,46.5-216.89,120.85-289' />
        </g>
        <g id='_' data-name='+'>
          <line className='cls-5' x1='727.48' y1='550.24' x2='727.48' y2='574.22' />
          <line className='cls-5' x1='716.04' y1='562.23' x2='738.91' y2='562.23' />
          <line className='cls-5' x1='609.49' y1='417.47' x2='609.49' y2='441.46' />
          <line className='cls-5' x1='598.05' y1='429.47' x2='620.92' y2='429.47' />
          <line className='cls-2' x1='286.41' y1='544.3' x2='286.41' y2='568.28' />
          <line className='cls-2' x1='274.97' y1='556.29' x2='297.85' y2='556.29' />
          <line className='cls-2' x1='218.43' y1='421.93' x2='218.43' y2='445.91' />
          <line className='cls-2' x1='206.99' y1='433.92' x2='229.86' y2='433.92' />
          <line className='cls-2' x1='168.34' y1='729.71' x2='168.34' y2='753.69' />
          <line className='cls-2' x1='156.9' y1='741.7' x2='179.77' y2='741.7' />
          <line className='cls-6' x1='762.76' y1='227.66' x2='762.76' y2='257.13' />
          <line className='cls-6' x1='748.71' y1='242.4' x2='776.82' y2='242.4' />
          <line className='cls-2' x1='504.87' y1='232.45' x2='504.87' y2='256.43' />
          <line className='cls-2' x1='493.44' y1='244.44' x2='516.31' y2='244.44' />
          <line className='cls-2' x1='662.35' y1='734.34' x2='662.35' y2='758.32' />
          <line className='cls-2' x1='650.91' y1='746.33' x2='673.79' y2='746.33' />
          <line className='cls-6' x1='712.82' y1='150.7' x2='712.82' y2='174.68' />
          <line className='cls-6' x1='701.39' y1='162.69' x2='724.26' y2='162.69' />
          <line className='cls-6' x1='796.07' y1='98.76' x2='796.07' y2='134.66' />
          <line className='cls-6' x1='778.95' y1='116.71' x2='813.19' y2='116.71' />
          <line className='cls-2' x1='309.65' y1='568.28' x2='309.65' y2='584.33' />
          <line className='cls-2' x1='302' y1='576.3' x2='317.31' y2='576.3' />
          <line className='cls-2' x1='197.18' y1='711.59' x2='197.18' y2='727.64' />
          <line className='cls-2' x1='189.53' y1='719.62' x2='204.84' y2='719.62' />
          <line className='cls-2' x1='284.41' y1='761.94' x2='284.41' y2='777.99' />
          <line className='cls-2' x1='276.76' y1='769.96' x2='292.06' y2='769.96' />
          <line className='cls-2' x1='258.03' y1='361.58' x2='258.03' y2='377.62' />
          <line className='cls-2' x1='250.38' y1='369.6' x2='265.68' y2='369.6' />
          <line className='cls-2' x1='410.38' y1='298.4' x2='410.38' y2='314.45' />
          <line className='cls-2' x1='402.73' y1='306.42' x2='418.04' y2='306.42' />
          <line className='cls-2' x1='480.86' y1='216.4' x2='480.86' y2='232.45' />
          <line className='cls-2' x1='473.21' y1='224.43' x2='488.51' y2='224.43' />
          <line className='cls-2' x1='633.57' y1='395.56' x2='633.57' y2='411.61' />
          <line className='cls-2' x1='625.92' y1='403.59' x2='641.23' y2='403.59' />
          <line className='cls-2' x1='768.73' y1='586.95' x2='768.73' y2='603' />
          <line className='cls-2' x1='761.07' y1='594.97' x2='776.38' y2='594.97' />
          <line className='cls-2' x1='697.01' y1='586.95' x2='697.01' y2='603' />
          <line className='cls-2' x1='689.36' y1='594.97' x2='704.67' y2='594.97' />
        </g>
        <g id='Courbe'>
          <path
            className='cls-7'
            d='M687.75,482.23,748,424.93c.29-.27,3.48-3.39,3.77-3.65a9,9,0,0,1,6-2.32c3.48,0,6.31,2.55,7.64,7.27l3.32,11c1.79,4.93,9.06,5.56,12.75,2.1L841,377.9l-18.46,2'
          />
          <line className='cls-7' x1='841.14' y1='396.2' x2='840.97' y2='378.9' />
        </g>
        <g id='Calque_16' data-name='Calque 16'>
          <rect className='cls-7' x='126.96' y='551.98' width='19.54' height='40.4' />
          <rect className='cls-7' x='183.65' y='537.56' width='19.54' height='54.82' />
          <rect className='cls-7' x='155.07' y='513.69' width='19.54' height='78.69' />
          <line className='cls-7' x1='126.96' y1='599.87' x2='203.19' y2='599.87' />
          <circle className='cls-7' cx='279.36' cy='242.4' r='87.03' />
          <rect className='cls-8' x='628.3' y='639.57' width='110.56' height='38.67' rx='19.34' />
          <polyline className='cls-9' points='639.59 666.2 647.91 658.27 652.56 663.55 663.02 653 657.14 653' />
          <path
            className='cls-10'
            d='M676.19,657.49a2.63,2.63,0,0,0,.72-1.69,2,2,0,0,0-1.95-1.89,1.92,1.92,0,0,0-1.92,2H669.5a5.47,5.47,0,1,1,10.94.06,5.59,5.59,0,0,1-1,3l-3.15,4.46H681v3.5H669.54Z'
          />
          <path
            className='cls-10'
            d='M684.11,655.82a5.4,5.4,0,1,1,10.8,0v5.7a5.4,5.4,0,0,1-10.8,0Zm3.45,5.7a1.94,1.94,0,1,0,3.87,0v-5.65a1.94,1.94,0,0,0-3.87,0Z'
          />
          <path
            className='cls-10'
            d='M698.41,655.82a5.4,5.4,0,1,1,10.8,0v5.7a5.4,5.4,0,0,1-10.8,0Zm3.45,5.7a1.94,1.94,0,1,0,3.87,0v-5.65a1.94,1.94,0,0,0-3.87,0Z'
          />
          <path
            className='cls-10'
            d='M712.71,655.82a5.4,5.4,0,1,1,10.8,0v5.7a5.4,5.4,0,0,1-10.8,0Zm3.45,5.7a1.94,1.94,0,1,0,3.87,0v-5.65a1.94,1.94,0,0,0-3.87,0Z'
          />
          <line className='cls-11' x1='284.41' y1='433.92' x2='284.41' y2='403.53' />
          <line className='cls-11' x1='320.47' y1='433.98' x2='320.47' y2='403.59' />
          <polyline className='cls-7' points='327.9 408.59 303.36 390.05 302.52 390 276.98 408.54' />
          <polyline className='cls-7' points='310.83 433.77 310.83 417.53 302.31 417.53 302.57 417.48 294.04 417.48 294.04 433.71' />
          <path
            className='cls-12'
            d='M603.81,529.89a4.48,4.48,0,0,0-3.17,1.53l-3.58,4.1a6.79,6.79,0,0,0-1.4,4.6c0,5.42,3.72,13,10.67,20s14.6,10.69,20,10.69a6.71,6.71,0,0,0,4.59-1.41l4.09-3.58a4.49,4.49,0,0,0,1.55-3.16,4.36,4.36,0,0,0-1.47-3.17l-5.62-5.62a4.56,4.56,0,0,0-3.14-1.47,4.47,4.47,0,0,0-3.13,1.47l-2,2c-.33.29-.5.41-.62.32l-10.3-10.29c-.13-.21.08-.38.2-.53l2.14-2.14a4.06,4.06,0,0,0,0-6.25L607,531.36A4.33,4.33,0,0,0,603.81,529.89Zm1,3.63,5.62,5.62a1.08,1.08,0,0,1,0,1.94l-2.16,2.16a3.44,3.44,0,0,0-.39,4.6l10.74,10.74a3.51,3.51,0,0,0,4.51-.3c.07-.06,2.26-2.26,2.26-2.26a1.46,1.46,0,0,1,1-.56,1.55,1.55,0,0,1,1,.56l5.63,5.63c.84.87.68,1.47.08,1.85l-4.09,3.58a4.37,4.37,0,0,1-2.58.66c-3.87,0-11.32-3.27-17.83-9.78s-9.79-14-9.79-17.84a4.35,4.35,0,0,1,.64-2.57l3.58-4.1C603.58,532.68,604.16,532.89,604.8,533.52Z'
          />
          <path
            className='cls-13'
            d='M683.58,336.73a19,19,0,1,0,19,19h0A19,19,0,0,0,683.58,336.73Zm0,36a17,17,0,1,1,17-17A17,17,0,0,1,683.58,372.74Z'
          />
          <path
            className='cls-14'
            d='M684.64,354.84v-6.06a6.52,6.52,0,0,1,3.13,1.44.87.87,0,0,0,1.24-.05.88.88,0,0,0,0-1.24,8,8,0,0,0-4.33-1.94v-1.6a.89.89,0,0,0-1.77,0V347c-3,.31-5.12,2.3-5.12,4.9,0,2.92,3.34,3.85,5.12,4.29v6.71a10.53,10.53,0,0,1-4-1.81.88.88,0,0,0-1.24,0,.87.87,0,0,0,0,1.23,11.35,11.35,0,0,0,5.25,2.35v1.46a.89.89,0,0,0,1.77,0h0v-1.43c2.25-.27,5.12-1.59,5.12-4.6C689.83,357.76,688,355.86,684.64,354.84Zm-1.76-.47c-1.44-.4-3.34-1.06-3.34-2.48,0-1.86,1.63-2.86,3.34-3.13v5.6Zm1.76,8.58V356.7c1.59.57,3.34,1.64,3.34,3.44s-2.1,2.57-3.34,2.78Z'
          />
          <path
            className='cls-15'
            d='M274.23,664.53,272.77,666l-3.5,3.5L265,673.72l-3.65,3.65c-.6.6-1.21,1.18-1.79,1.79l0,0a1.38,1.38,0,1,0,1.94,2l1.46-1.46,3.49-3.5,4.23-4.23,3.66-3.65c.59-.59,1.2-1.18,1.78-1.79l0,0a1.38,1.38,0,1,0-1.94-2Z'
          />
          <path
            className='cls-15'
            d='M263.42,668.37l-.2.18c-.18.15.31-.21,0,0a3.28,3.28,0,0,1-.45.24l.33-.14a3.09,3.09,0,0,1-.79.22l.37-.05a3.14,3.14,0,0,1-.75,0l.37.05a3,3,0,0,1-.78-.22l.33.14a2.42,2.42,0,0,1-.45-.24c-.29-.19.19.17,0,0l-.2-.18-.18-.2c-.16-.17.21.31,0,0a2,2,0,0,1-.24-.45l.13.33a3,3,0,0,1-.21-.78c0,.12,0,.24.05.36a3.05,3.05,0,0,1,0-.74c0,.12,0,.24-.05.36a3,3,0,0,1,.21-.78l-.13.33a2,2,0,0,1,.24-.45c.19-.29-.18.19,0,0l.18-.2.2-.18c.17-.15-.31.21,0,0a3.21,3.21,0,0,1,.45-.24l-.33.14a3,3,0,0,1,.78-.22l-.37,0a3.14,3.14,0,0,1,.75,0l-.37,0a3.09,3.09,0,0,1,.79.22l-.33-.14.45.24c.29.19-.19-.17,0,0l.2.18.17.2c.16.17-.21-.31,0,0a3.21,3.21,0,0,1,.24.45l-.14-.33a3,3,0,0,1,.22.78c0-.12,0-.24,0-.36a4.57,4.57,0,0,1,0,.74c0-.12,0-.24,0-.36a3,3,0,0,1-.22.78l.14-.33a3.21,3.21,0,0,1-.24.45c-.2.29.17-.19,0,0s-.11.13-.17.2a1.37,1.37,0,0,0,1.94,1.94,4.18,4.18,0,0,0,1.17-2.21,4.55,4.55,0,0,0-.36-2.74A4.3,4.3,0,0,0,262,663a4.49,4.49,0,0,0-2.53,1.07,4.14,4.14,0,0,0-1.32,2.14,4.3,4.3,0,0,0,7.2,4.11,1.37,1.37,0,1,0-1.94-1.94Z'
          />
          <path
            className='cls-15'
            d='M274.53,679.47l-.2.18c-.17.15.31-.21,0,0a2.42,2.42,0,0,1-.45.24l.33-.14a3,3,0,0,1-.78.22l.36-.05a3.05,3.05,0,0,1-.74,0l.36.05a3,3,0,0,1-.78-.22l.33.14a2.42,2.42,0,0,1-.45-.24c-.29-.19.19.17,0,0l-.2-.18-.18-.2c-.15-.17.21.31,0,0a3.21,3.21,0,0,1-.24-.45l.14.33a3,3,0,0,1-.22-.78c0,.12,0,.24.05.36a3.05,3.05,0,0,1,0-.74c0,.12,0,.24-.05.36a3,3,0,0,1,.22-.78l-.14.33a3.21,3.21,0,0,1,.24-.45c.19-.29-.17.19,0,0l.18-.2.2-.18c.17-.15-.31.21,0,0a3.21,3.21,0,0,1,.45-.24l-.33.14a3,3,0,0,1,.78-.22l-.36.05a3.05,3.05,0,0,1,.74,0l-.36-.05a3,3,0,0,1,.78.22l-.33-.14a3.21,3.21,0,0,1,.45.24c.29.19-.19-.17,0,0l.2.18.18.2c.15.17-.21-.31,0,0a2.42,2.42,0,0,1,.24.45l-.13-.33a3.46,3.46,0,0,1,.21.78c0-.12,0-.24-.05-.36a3.05,3.05,0,0,1,0,.74c0-.12,0-.24.05-.36a3.46,3.46,0,0,1-.21.78l.13-.33a2.42,2.42,0,0,1-.24.45c-.19.29.17-.19,0,0l-.18.2a1.37,1.37,0,1,0,1.94,1.94,4.27,4.27,0,0,0,1.18-2.21,4.63,4.63,0,0,0-.37-2.74,4.3,4.3,0,0,0-4.16-2.37,4.49,4.49,0,0,0-2.53,1.07,4.14,4.14,0,0,0-1.32,2.14,4.3,4.3,0,0,0,7.2,4.11,1.37,1.37,0,1,0-1.94-1.94Z'
          />
          <path
            className='cls-15'
            d='M286.26,685.68V660.23c0-.49,0-1,0-1.48a1.54,1.54,0,0,0-.41-1l-.12-.12-.69-.71-2.67-2.74-3.4-3.48-2.92-3c-.41-.43-.81-.88-1.24-1.27a1.67,1.67,0,0,0-1.3-.41H253.26a3.9,3.9,0,0,0-1.92.47,3.61,3.61,0,0,0-1.83,3.14c0,.34,0,.68,0,1v34.94a4,4,0,0,0,.4,1.76,3.62,3.62,0,0,0,3.2,1.94h29.4a4.16,4.16,0,0,0,1.11-.13,3.68,3.68,0,0,0,2.64-3.48,1.38,1.38,0,0,0-2.75,0,1.73,1.73,0,0,1,0,.32c0-.12,0-.24,0-.37a2,2,0,0,1-.14.54l.14-.33a2.18,2.18,0,0,1-.2.37c-.08.12-.18.16.07-.09l-.14.16s-.14.15-.16.14.33-.23.09-.07a1.85,1.85,0,0,1-.37.19l.33-.13a2,2,0,0,1-.54.14l.37,0a7.37,7.37,0,0,1-1.05,0h-28.8l-.35,0,.37,0a2.15,2.15,0,0,1-.54-.14l.33.13a2.16,2.16,0,0,1-.37-.19c-.12-.08-.16-.19.09.07l-.16-.14a1.07,1.07,0,0,1-.14-.16s.24.33.08.09a1.9,1.9,0,0,1-.2-.37l.14.33a2.39,2.39,0,0,1-.15-.54c0,.13,0,.25.05.37a9.94,9.94,0,0,1,0-1.22V649.66c0-.12,0-.24,0-.36,0,.12,0,.24-.05.37a2.39,2.39,0,0,1,.15-.54l-.14.33a1.65,1.65,0,0,1,.2-.37c.07-.12.18-.16-.08.09l.14-.16s.15-.15.16-.14-.33.23-.09.07a3.35,3.35,0,0,1,.37-.2l-.33.14a2,2,0,0,1,.54-.14l-.37,0a5.49,5.49,0,0,1,.81,0h17.67c.84,0,1.7,0,2.55,0h0l-1-.4,1.1,1.12,2.63,2.7,3.19,3.27,2.76,2.84c.45.46.88.93,1.34,1.38h0c-.13-.32-.27-.64-.4-1v23.65c0,1.1,0,2.19,0,3.28v0a1.39,1.39,0,0,0,1.37,1.38A1.42,1.42,0,0,0,286.26,685.68Z'
          />
          <path
            className='cls-15'
            d='M285.85,657.74l-1.09-1.12-2.63-2.7-3.19-3.27-2.77-2.84c-.44-.46-.88-.93-1.33-1.37l0,0a1.39,1.39,0,0,0-2.36,1v9.05a3.81,3.81,0,0,0,.43,1.76,3.66,3.66,0,0,0,3.28,1.89h8.71a1.38,1.38,0,0,0,0-2.75H277a10.94,10.94,0,0,1-1.28,0l.37,0a2.39,2.39,0,0,1-.54-.15l.33.14a2.18,2.18,0,0,1-.37-.2c-.12-.08-.16-.18.09.07l-.16-.14s-.15-.14-.14-.16.23.33.07.09a2.64,2.64,0,0,1-.2-.37l.14.33a2.39,2.39,0,0,1-.15-.54l.06.37a8.09,8.09,0,0,1,0-1.1V647.4l-2.34,1c.36.38.72.75,1.09,1.12l2.63,2.7,3.19,3.27,2.76,2.84c.45.46.89.93,1.34,1.38h0a1.38,1.38,0,0,0,2-2Z'
          />
          <path
            className='cls-10'
            d='M537.15,300.27a29.26,29.26,0,0,1,19.28,7.1,13.84,13.84,0,0,1,1.18,1.12c1,1.09,1.28,2.32.16,3.43s-2.21.68-3.22-.28a23.1,23.1,0,0,0-11.49-6.29c-8.2-1.73-15.4.29-21.67,5.84a4.14,4.14,0,0,1-2.7,1.17c-2-.28-2.22-2.46-.53-4.08a27.83,27.83,0,0,1,15.08-7.57C534.75,300.47,536.27,300.36,537.15,300.27Z'
          />
          <path
            className='cls-10'
            d='M539.25,311.09a17.23,17.23,0,0,1,10.51,4.53c1.19,1,2.13,2.27.91,3.71s-2.49.75-3.73-.34a13.52,13.52,0,0,0-18.15-.2c-.18.15-.36.3-.52.45-1.11,1.08-2.33,1.25-3.39.09s-.49-2.36.53-3.37C528.4,312.93,533.51,310.94,539.25,311.09Z'
          />
          <path
            className='cls-10'
            d='M541.81,325.8a4.09,4.09,0,0,1-4,4.18,4.29,4.29,0,0,1-4.2-4.05,4.15,4.15,0,0,1,4.06-4.13,4,4,0,0,1,4.13,3.88Z'
          />
          <path
            className='cls-15'
            d='M721,751.36a1,1,0,0,0-1.13.81L715,780.23c-.09.52.28.14.83.24H716a1,1,0,0,0,1-.81l4.86-27.16A1,1,0,0,0,721,751.36Z'
          />
          <path
            className='cls-15'
            d='M722.15,755.58v23.68l-6.69.36a1,1,0,0,0,.45,1.85.84.84,0,0,0,.42-.1l6.82-.39,2.82,1.39a1,1,0,0,0,.42.1h27.55a1,1,0,0,0,.74-.36,1,1,0,0,0,.23-.77l-4.86-29.17a1,1,0,0,0-1-.81h-3.92V749.1a7.46,7.46,0,1,0-14.91,0v2.26h-3.89a1,1,0,0,0-.74.36,1,1,0,0,0-.23.78l4.7,28h-3.5l-2.5-1.27V755.58a1,1,0,1,0-1.94,0Zm10.05-6.48a5.51,5.51,0,1,1,11,0v2.26h-4.53V749.1a7.43,7.43,0,0,0-.59-2.92,1,1,0,1,0-1.78.74,5.49,5.49,0,0,1,.45,2.18v2.26H732.2Zm-4.7,4.21h20.7l4.54,27.22H732Z'
          />
          <path
            className='cls-15'
            d='M724.74,750.07a1,1,0,0,0,1-1,5.51,5.51,0,0,1,4-5.29,1,1,0,1,0-.55-1.84,7.39,7.39,0,0,0-5.38,7.13A1,1,0,0,0,724.74,750.07Z'
          />
          <path
            className='cls-15'
            d='M733.65,769.12A1,1,0,0,0,735,769c.36-.46.68-.91,1-1.3s.87-1.2,1.1-1.42c.39.09,1,.87,1.46,1.42.87,1.07,1.84,2.3,3.27,2.3h.26a4.26,4.26,0,0,0,2.4-1.42c.48-.49,1-.94,1.32-.91a1,1,0,0,1,.39.13A1,1,0,1,0,747,766a3.44,3.44,0,0,0-1.07-.32,3.74,3.74,0,0,0-2.89,1.42c-.45.42-.9.88-1.26.91-.52.06-1.23-.84-1.82-1.59-.87-1.1-1.87-2.33-3.3-2.14-.81.1-1.33.84-2.33,2.21-.23.38-.55.81-.88,1.26A1,1,0,0,0,733.65,769.12Z'
          />
          <line className='cls-9' x1='663.02' y1='658.04' x2='663.02' y2='653' />
        </g>
      </svg>
    </StyledLoginIllustration>
  );
};

const StyledLoginIllustration = styled.div`
  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9 {
    fill: none;
  }
  .cls-1,
  .cls-11,
  .cls-12,
  .cls-13,
  .cls-14,
  .cls-15,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9 {
    stroke: #dbe1ff;
  }
  .cls-1,
  .cls-11,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9 {
    stroke-linecap: round;
  }
  .cls-1,
  .cls-12,
  .cls-13,
  .cls-14,
  .cls-15 {
    stroke-miterlimit: 10;
  }
  .cls-1,
  .cls-2,
  .cls-5 {
    stroke-width: 8px;
  }
  .cls-11,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9 {
    stroke-linejoin: round;
  }
  .cls-3 {
    stroke-width: 12px;
  }
  .cls-4 {
    stroke-width: 12px;
  }
  .cls-10,
  .cls-12,
  .cls-13,
  .cls-14,
  .cls-15,
  .cls-5 {
    fill: #dbe1ff;
  }
  .cls-6 {
    stroke-width: 12px;
  }
  .cls-11,
  .cls-7 {
    stroke-width: 12px;
  }
  .cls-8 {
    stroke-width: 8px;
  }
  .cls-9 {
    stroke-width: 3px;
  }
  .cls-11 {
    fill: #fff;
  }
  .cls-12 {
    stroke-width: 0.63px;
  }
  .cls-13 {
    stroke-width: 1.79px;
  }
  .cls-14 {
    stroke-width: 2px;
  }
`;
