import { useTransactions } from '../Hooks';
import { Transaction } from '../Model';
import { EditTransactionForm, EditPageTitle, StyledEditDrawer } from './EditPage';

interface Props {
  visible: boolean;
  onClose: () => void;
}

export const MobileAddDrawer: React.FC<Props> = ({ visible, onClose }) => {
  const { addTransaction, isLoadingCreate } = useTransactions();

  const handleOnClose = () => {
    onClose();
  };

  const handleOnSavePast = (transaction: Transaction) => {
    addTransaction(transaction);
    handleOnClose();
  };

  return (
    <StyledEditDrawer
      title={<EditPageTitle title={<>New Transaction</>} />}
      destroyOnClose
      onClose={handleOnClose}
      visible={visible}
      placement='bottom'
      height='90%'
    >
      <EditTransactionForm onSave={handleOnSavePast} mode='create' isLoading={isLoadingCreate} onCancel={handleOnClose} />
    </StyledEditDrawer>
  );
};
