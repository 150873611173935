export const HeaderIcon: React.FC = () => {
  return (
    <svg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.6838 1.29327C24.0726 0.301657 21.919 0.835851 20.8732 2.48745L16.0152 10.159C14.9694 11.8106 15.4269 13.9555 17.0381 14.9471C18.6493 15.9387 20.8029 15.4045 21.8487 13.7529L26.7067 6.08143C27.7525 4.42983 27.295 2.28651 25.6838 1.29327Z'
        fill='white'
      />
      <path
        d='M13.9887 23.2049C15.8884 23.2049 17.4284 21.6865 17.4284 19.8135C17.4284 17.9405 15.8884 16.4221 13.9887 16.4221C12.0891 16.4221 10.5491 17.9405 10.5491 19.8135C10.5491 21.6865 12.0891 23.2049 13.9887 23.2049Z'
        fill='white'
      />
      <path
        d='M9.0478 15.4061C10.9475 15.4061 12.4874 13.8877 12.4874 12.0147C12.4874 10.1417 10.9475 8.62329 9.0478 8.62329C7.14814 8.62329 5.60815 10.1417 5.60815 12.0147C5.60815 13.8877 7.14814 15.4061 9.0478 15.4061Z'
        fill='white'
      />
      <path
        d='M4.10639 7.60533C6.00605 7.60533 7.54603 6.08694 7.54603 4.21392C7.54603 2.34089 6.00605 0.82251 4.10639 0.82251C2.20673 0.82251 0.666748 2.34089 0.666748 4.21392C0.666748 6.08694 2.20673 7.60533 4.10639 7.60533Z'
        fill='white'
      />
    </svg>
  );
};
