import React, { useContext, useEffect, useState } from 'react';

interface ScrollPositionContextProps {
  scrollPosition: number;
  setScrollPosition: (value: number) => void;
  resetScrolling: () => void;
  expandHeader: boolean;
}

const ScrollPositionContext = React.createContext<ScrollPositionContextProps>({} as ScrollPositionContextProps);

export const useScrollPosition = () => useContext(ScrollPositionContext);

export const ScrollProvider = ({ children }: React.PropsWithChildren) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [expandHeader, setExpandHeader] = useState(true);

  const resetScrolling = () => {
    setTimeout(() => {
      setScrollPosition(0);
    }, 300);
  };

  useEffect(() => {
    if (scrollPosition < 15) {
      setExpandHeader(true);
    } else {
      setExpandHeader(false);
    }
  }, [scrollPosition]);

  const value: ScrollPositionContextProps = {
    scrollPosition,
    setScrollPosition,
    resetScrolling,
    expandHeader,
  };

  return <ScrollPositionContext.Provider value={value}>{children}</ScrollPositionContext.Provider>;
};
