import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface Props {
  title?: React.ReactNode;
}

export const EditPageTitle: React.FC<Props> = ({ title }) => {
  return (
    <StyledEditPageTitle>
      <StyledTitle level={2}>{title}</StyledTitle>
    </StyledEditPageTitle>
  );
};

const StyledEditPageTitle = styled.div`
  padding-top: 8px;
  h2 {
    width: max-content;
  }
`;

const StyledTitle = styled(Typography.Title)`
  margin: 0px !important;
  transform: translateX(24px);
`;
