import { Button } from 'antd-mobile';
import styled from 'styled-components';
import { useRouteContext } from '../../Contexts';
import { CommonIcons } from '../../Design';

interface Props {}

export const BackButton: React.FC<Props> = () => {
  const { BackIcon } = CommonIcons;
  const { handleNavigation } = useRouteContext();

  return (
    <StyledBackButton onClick={() => handleNavigation(-1)} fill='none'>
      <BackIcon />
    </StyledBackButton>
  );
};

const StyledBackButton = styled(Button)`
  svg {
    font-size: 24px;
    transform: translateY(2px);
    color: #fff;
  }
  padding: 0px 8px 0px 0px;
`;
