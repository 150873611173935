import styled from 'styled-components';

interface Props {}

export const CashflowUp: React.FC<Props> = () => {
  return (
    <StyledCashflowUp>
      <svg width='61' height='61' viewBox='0 0 61 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.236023' y='0.193848' width='60' height='60' rx='16' fill='#F6FFED' />
        <path d='M52.7318 20.4119L49.044 21.5838' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M47.9048 12.3291L44.8591 15.7646' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M39.0398 9.86963L39.1651 13.7371' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path
          d='M24.0644 20.8498C25.8981 20.8498 27.3859 19.3629 27.3859 17.529C27.3859 15.6953 25.8981 14.2075 24.0644 14.2075C22.2313 14.2075 20.7436 15.695 20.7436 17.529C20.7436 19.3627 22.2312 20.8498 24.0644 20.8498Z'
          fill='#389E0D'
        />
        <path
          d='M34.9366 22.567C29.8489 21.1881 24.6464 20.9364 20.0517 24.1314C17.8907 25.634 16.2356 27.7617 15.5478 30.3189C14.5892 30.4973 13.9821 30.3688 13.7376 30.1131C13.3777 29.7367 13.7468 28.4832 14.809 29.3361L15.9747 27.887C14.9224 27.0418 13.9066 27.0197 13.1186 27.4182C11.6723 28.1507 11.2534 30.2074 12.3974 31.4019C12.9417 31.9704 13.9042 32.3416 15.2252 32.2366C15.05 34.6607 15.7157 37.3332 17.4957 40.0868L16.5943 41.4847L21.9477 45.7064L23.6918 44.388C25.5314 44.8855 27.3915 45.1215 29.2693 45.1261C31.1437 45.1314 33.0333 44.9031 34.9346 44.4776L36.5653 45.7154L41.7705 41.4143L41.1006 40.1607C41.524 39.5568 42.0617 38.6539 42.3148 37.9741H44.5224L44.5169 29.775L42.7173 29.7252C42.1024 28.1155 41.1627 26.7151 39.8561 25.5848L38.6746 21.1232L34.9366 22.567Z'
          fill='#389E0D'
        />
        <path
          d='M31.7731 25.443C29.2889 24.794 26.8499 24.9374 24.4895 25.9669L25.1765 27.52C27.175 26.652 29.2323 26.5431 31.3331 27.0899L31.7731 25.443Z'
          fill='white'
        />
        <circle cx='37.1268' cy='30.8477' r='1.91293' fill='white' />
      </svg>
    </StyledCashflowUp>
  );
};

const StyledCashflowUp = styled.div``;
