import { Col, Progress, Row, Tag, Typography } from 'antd';
import { Card } from 'antd-mobile';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Transaction } from '../../Model/Transaction';
import { getMonthlyOccurencesCount } from '../../Utils/transactions-utils';
import { theme } from '../../Design';
import { MoneyFormatter } from '../../Utils';
import { CardLoader } from '../Loaders';

interface CashflowCardProps {
  recurrentTransactions?: Transaction[];
  pastTransactions: Transaction[];
  isLoading?: boolean;
}
enum DiffType {
  negative = 'negative',
  positive = 'positive',
  flat = 'flat',
  weak = 'weak',
}

const TrendsColors: Record<DiffType, string> = {
  positive: '#389E0D',
  negative: theme.colors.danger,
  flat: theme.colors.primaryGray['primary-gray-10'],
  weak: '#D46B08',
};

export const CashflowCard = ({ pastTransactions, recurrentTransactions, isLoading }: CashflowCardProps) => {
  if (isLoading) return <CardLoader height={165} />;

  const yearMonth = dayjs().format('YYYY-MM');
  const lastDayOfMonth = new Date(Number(yearMonth.slice(0, 4)), Number(yearMonth.slice(5, 7)), 0).getDate();
  const allMonthlyTransactions = [...(recurrentTransactions || []), ...pastTransactions].filter(
    t => t.date <= `${yearMonth}-${lastDayOfMonth}`,
  );

  const totalIncome = allMonthlyTransactions.reduce((acc, curr) => {
    if (curr.type === 'Income') {
      if (curr.recurrent && !curr.paid) {
        return acc + curr.amount * getMonthlyOccurencesCount(curr.date, curr.frequency!);
      }
      return acc + curr.amount;
    }
    return acc;
  }, 0);

  const totalSpendings = allMonthlyTransactions.reduce((acc, curr) => {
    if (curr.type === 'Payment') {
      if (curr.recurrent && !curr.paid) {
        return acc + curr.amount * getMonthlyOccurencesCount(curr.date, curr.frequency!);
      }
      return acc + curr.amount;
    }
    return acc;
  }, 0);

  const cashflow = totalIncome - totalSpendings;
  const cashflowPercentage = (cashflow / totalIncome) * 100;
  const diffType =
    cashflowPercentage > 30
      ? DiffType.positive
      : cashflowPercentage < 0
      ? DiffType.negative
      : cashflowPercentage > 0
      ? DiffType.weak
      : DiffType.flat;

  return (
    <StyledCashflowCard>
      <StyledRow justify='space-between' align='middle'>
        <Col>
          <StyledTypographyTitle level={4}>Monthly Cashflow</StyledTypographyTitle>
        </Col>
        <Col>
          <StyledTag color={TrendsColors[diffType]}>{!totalIncome ? 'No Income' : `${(cashflowPercentage || 0).toFixed(1)}%`}</StyledTag>
        </Col>
      </StyledRow>
      <Row justify='space-between'>
        <Col>
          <Typography.Title style={{ marginBottom: 6 }}>{MoneyFormatter.format(cashflow)}</Typography.Title>
        </Col>
      </Row>
      <Progress strokeColor={TrendsColors[diffType]} percent={cashflowPercentage || 0} showInfo={false} type='line' />
      <Row justify='end'>
        <Col>
          <Typography.Text type='secondary'>Total income: {MoneyFormatter.format(totalIncome)}</Typography.Text>{' '}
        </Col>
      </Row>
    </StyledCashflowCard>
  );
};

const StyledTag = styled(Tag)`
  font-weight: 600;
  border-radius: 32px;
  margin: 0px;
  padding: 4px 8px;
  font-size: 14px;
  transform: translateX(8px);
`;

const StyledCashflowCard = styled(Card)`
  padding: 4px 24px;
  .ant-card-head {
    border: none;
  }
  .ant-card-head-title {
    padding-bottom: 0px;
  }
`;
const StyledTypographyTitle = styled(Typography.Title)`
  margin: 0px !important;
`;

const StyledRow = styled(Row)`
  padding-bottom: 6px;
`;
