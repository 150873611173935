import { Routes, Route, useLocation } from 'react-router';
import { AppRoutes } from '../../Model';
import { MobileLoginCard } from '../Login';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import {
  Dashboard,
  PaymentsListPage,
  IncomePage,
  PaymentsPage,
  SpendingsListPage,
  PaymentsOverviewPage,
  PaymentsOverviewCategoryPage,
  MorePage,
  Playgrounds,
  PlaygroundPage,
  Insights,
} from '../../Pages';

export const AnimatedRoutes: React.FC = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path={AppRoutes.Dashboard} element={<Dashboard />} />
        <Route path={AppRoutes.More} element={<MorePage />} />
        <Route path={AppRoutes.Income} element={<IncomePage />} />
        <Route path={AppRoutes.Payments} element={<PaymentsPage />} />
        <Route path={AppRoutes.UpcomingPaymentsList} element={<PaymentsListPage />} />
        <Route path={AppRoutes.SpendingsList} element={<SpendingsListPage />} />
        <Route path={AppRoutes.PaymentsOverview} element={<PaymentsOverviewPage />} />
        <Route path={AppRoutes.PaymentsOverviewCategory} element={<PaymentsOverviewCategoryPage />} />
        <Route path={AppRoutes.Login} element={<MobileLoginCard />} />
        <Route path={AppRoutes.Playgrounds} element={<Playgrounds />} />
        <Route path={AppRoutes.Insights} element={<Insights />} />
        <Route path={AppRoutes.PlaygroundPage} element={<PlaygroundPage />} />
      </Routes>
    </AnimatePresence>
  );
};
