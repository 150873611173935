import { Spin } from 'antd';
import { Card } from 'antd-mobile';
import styled from 'styled-components';

interface Props {
  height: number;
}

export const CardLoader: React.FC<Props> = ({ height }) => {
  return (
    <StyledCardLoader style={{ height }}>
      <Spin />
    </StyledCardLoader>
  );
};

const StyledCardLoader = styled(Card)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
