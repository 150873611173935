import styled from 'styled-components';
import { useRouteContext } from '../Contexts';
import { useSwipeable } from 'react-swipeable';

interface Props extends React.PropsWithChildren {}

export const SwipeBackContainer: React.FC<Props> = ({ children }) => {
  const { handleNavigation } = useRouteContext();
  const handlers = useSwipeable({
    onSwipedRight: e => {
      return e.initial[0] < 50 ? handleNavigation(-1) : null;
    },
  });
  return <StyledSwipeBackContainer {...handlers}>{children}</StyledSwipeBackContainer>;
};

const StyledSwipeBackContainer = styled.div``;
