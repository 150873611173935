import { Col, Row } from 'antd';
import { Tag } from 'antd-mobile';
import styled from 'styled-components';
import { theme } from '../../Design';

interface Props {
  value: SelectedTag;
  onChange: (option: SelectedTag) => void;
}

export enum SelectedTag {
  All = 'All',
  Income = 'Income',
  Payments = 'Payments',
}

export const TransactionsFiltersSwitch: React.FC<Props> = ({ onChange, value }) => {
  const tagOptions = [SelectedTag.All, SelectedTag.Income, SelectedTag.Payments];

  return (
    <StyledTransactionsFiltersSwitch>
      <Row gutter={[8, 16]}>
        {tagOptions.map(option => (
          <Col key={option}>
            <StyledTag onClick={() => onChange(option)} $isSelected={value === option}>
              {option}
            </StyledTag>
          </Col>
        ))}
      </Row>
    </StyledTransactionsFiltersSwitch>
  );
};

const StyledTransactionsFiltersSwitch = styled.div`
  margin: 12px 0px;
  touch-action: none;
`;

const StyledTag = styled(Tag)<{ $isSelected: boolean }>`
  font-size: 16px;
  font-weight: 600;
  border-radius: 32px;
  padding: 8px 12px;
  width: 100%;
  text-align: center;
  background-color: ${({ $isSelected }) =>
    $isSelected ? theme.colors.primaryShades['primary-9'] : theme.colors.primaryShades['primary-1']};
  color: ${({ $isSelected }) => ($isSelected ? '#fff' : 'inherit')};
  border: none;
`;
