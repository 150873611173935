import { Button } from 'antd-mobile';
import styled from 'styled-components';
import { CommonIcons, theme } from '../Design';

interface Props {
  color?: string;
  backgroundColor?: string;
  small?: boolean;
}

const { ForwardChevron } = CommonIcons;

export const ClickIndicator: React.FC<Props> = ({ color, backgroundColor, small = false }) => {
  return (
    <StyledClickIndicator $small={small} $backgroundColor={backgroundColor}>
      <Button shape='rounded'>
        <ForwardChevron size={16} color={color || theme.colors.primary} />
      </Button>
    </StyledClickIndicator>
  );
};

const StyledClickIndicator = styled.div<{ $backgroundColor?: string; $small?: boolean }>`
  .adm-button {
    padding: 6px;
    width: ${({ $small }) => ($small ? '34px' : '35px')};
    background-color: ${({ $backgroundColor }) => $backgroundColor || theme.colors.primaryShades['primary-1']};
    border: none;
    svg {
      transform: translateX(1px) translateY(3px);
    }
  }
`;
