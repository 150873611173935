import styled from 'styled-components';
import { theme } from '../Theme';

type Color = 'default' | 'primary' | 'active';
interface Props {
  color?: Color;
}

const FillColors: Record<Color, string> = {
  active: theme.colors.primary,
  default: '#F4F5F9',
  primary: theme.colors.primaryShades['primary-1'],
};

const ContrastColors: Record<Color, string> = {
  active: '#fff',
  default: '#020B36',
  primary: theme.colors.primary,
};

export const PaymentsStale: React.FC<Props> = ({ color = 'default' }) => {
  const fillColor = FillColors[color];
  const contrastColor = ContrastColors[color];
  return (
    <StyledPaymentsStale>
      <svg width='61' height='61' viewBox='0 0 61 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.186966' y='0.42041' width='60' height='60' rx='16' fill={fillColor} />
        <path
          d='M14.7229 23.3686C14.7297 21.1595 16.526 19.3742 18.7352 19.381L41.7066 19.4517C43.9157 19.4585 45.7011 21.2548 45.6943 23.464L45.6923 24.109L14.7209 24.0137L14.7229 23.3686Z'
          fill={contrastColor}
        />
        <path
          d='M14.713 26.5493L45.6844 26.6447L45.6511 37.4726C45.6443 39.6817 43.8479 41.4671 41.6388 41.4603L18.6673 41.3895C16.4582 41.3827 14.6729 39.5864 14.6797 37.3772L14.713 26.5493Z'
          fill={contrastColor}
        />
        <rect
          x='16.4543'
          y='28.9092'
          width='6.75299'
          height='4.59788'
          rx='2'
          transform='rotate(0.176383 16.4543 28.9092)'
          fill={fillColor}
        />
      </svg>
    </StyledPaymentsStale>
  );
};

const StyledPaymentsStale = styled.div``;
