import { Col, Row } from 'antd';
import styled from 'styled-components';
import { ScrollableScreenContainer, TransactionsList } from '../Components';
import { CardWithTitle } from '../Components/Cards';
import { useScreenAnimation } from '../Hooks';
import { getPastTransactions, getUpcomingTransactions } from '../Utils';
import { motion } from 'framer-motion';
import { useTransactionsContext } from '../Contexts';

interface Props {}

export const IncomePage: React.FC<Props> = () => {
  const { allTransactions, isLoading } = useTransactionsContext();

  const upcomingIncome = getUpcomingTransactions(allTransactions, 'Income');
  const pastIncome = getPastTransactions(allTransactions, 'Income');

  const animation = useScreenAnimation();

  return (
    <StyledAnimatedContainer {...animation}>
      <ScrollableScreenContainer>
        <StyledIncomePage gutter={[16, 24]}>
          {Boolean(upcomingIncome.length) && (
            <Col span={24}>
              <CardWithTitle title='Upcoming' isLoading={isLoading}>
                <TransactionsList showDue upcoming transactions={upcomingIncome} />
              </CardWithTitle>
            </Col>
          )}
          <Col span={24}>
            <CardWithTitle title='Past' isLoading={isLoading}>
              <TransactionsList transactions={pastIncome} />
            </CardWithTitle>
          </Col>
        </StyledIncomePage>
      </ScrollableScreenContainer>
    </StyledAnimatedContainer>
  );
};

const StyledAnimatedContainer = styled(motion.div)`
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
`;

const StyledIncomePage = styled(Row)`
  margin: 0px !important;
`;

export default IncomePage;
