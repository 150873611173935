import { Button, Col, Row } from 'antd';
import styled from 'styled-components';
import { theme } from '../../Design';

interface Props {
  loading: boolean;
  mode: 'create' | 'edit';
  disabled?: boolean;
  onCancel?: () => void;
}

export const EditPageFooter: React.FC<Props> = ({ loading, mode, disabled, onCancel }) => {
  return (
    <StyledEditPageFooter gutter={[8, 8]} justify='space-between'>
      <Col span={12}>
        <StyledButton onClick={onCancel && onCancel} disabled={loading} size='large' color='primary' htmlType='button'>
          Cancel
        </StyledButton>
      </Col>
      <Col span={12}>
        <StyledButton disabled={disabled} loading={loading} size='large' type='primary' htmlType='submit'>
          {mode === 'create' ? 'Create' : 'Save'}
        </StyledButton>
      </Col>
    </StyledEditPageFooter>
  );
};

const StyledEditPageFooter = styled(Row)`
  .ant-btn-primary:not(:disabled) {
    background-color: ${theme.colors.primary};
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 32px !important;
  font-weight: 500;
  height: 50px;
  font-size: 18px;
`;
