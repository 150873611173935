import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useRecurrentTransactions, useTransactionsList } from '../Hooks';
import { Category, Transaction } from '../Model';

interface TransactionsContextProps {
  allTransactions: Transaction[];
  transactionsList: Transaction[];
  recurrentTransactions: Transaction[];
  flagsRecurrentTransactions: Transaction[];
  isLoading: boolean;
  setCategoryOverview: (category: Category) => void;
  categoryOverview: Category;
  selectedMonth: number;
  setSelectedMonth: (month: number) => void;
  isThisMonth: boolean;
}

const TransactionsContext = React.createContext<TransactionsContextProps>({} as TransactionsContextProps);

export const useTransactionsContext = () => useContext(TransactionsContext);

export const TransactionsProvider = ({ children }: React.PropsWithChildren) => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const yearMonth = dayjs().format('YYYY-MM');
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYearMonth, setSelectedYearMonth] = useState(yearMonth);
  const { transactionsList, isLoading: isLoadingTransactionsList } = useTransactionsList({ type: 'ALL', yearMonth: selectedYearMonth });
  const { recurrentTransactions, isLoading: isLoadingRecurrentTransactions } = useRecurrentTransactions({ type: 'ALL' });
  const [categoryOverview, setCategoryOverview] = useState<Category>(Category.Other);

  const [isThisMonth, setIsThisMonth] = useState(true);

  useEffect(() => {
    if (selectedMonth < currentMonth) {
      setIsThisMonth(false);
    } else {
      !isThisMonth && setIsThisMonth(true);
    }
  }, [selectedMonth, currentMonth, isThisMonth]);

  const handleMonthChange = (number: number) => {
    const month = number + 1;
    if (month < 10) setSelectedYearMonth(`${currentYear}-0${month}`);
    else setSelectedYearMonth(`${currentYear}-${month}`);
  };

  const handleSelectMonth = (number: number) => {
    handleMonthChange(number);
    setSelectedMonth(number);
  };

  const allTransactions = isThisMonth ? [...transactionsList, ...recurrentTransactions] : [...transactionsList];
  const isLoading = isLoadingTransactionsList || isLoadingRecurrentTransactions;

  const value: TransactionsContextProps = {
    allTransactions,
    transactionsList,
    recurrentTransactions: isThisMonth ? recurrentTransactions : [],
    isLoading,
    setCategoryOverview,
    categoryOverview,
    selectedMonth,
    setSelectedMonth: handleSelectMonth,
    isThisMonth,
    flagsRecurrentTransactions: recurrentTransactions,
  };

  return <TransactionsContext.Provider value={value}>{children}</TransactionsContext.Provider>;
};
