import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AppRoutes, RoutesIndex } from '../Model';
import { useScrollPosition } from './ScrollContext';

type PageAnimationDirection = 'right' | 'left';

interface RouteContextProps {
  routesHistory: Array<AppRoutes>;
  setAnimationDirection: (dir: PageAnimationDirection) => void;
  animationDirection?: PageAnimationDirection;
  handleNavigation: (path: AppRoutes | -1, state?: string) => void;
}

const RouteContext = React.createContext<RouteContextProps>({} as RouteContextProps);

export const useRouteContext = () => useContext(RouteContext);

export const RouteProvier = ({ children }: React.PropsWithChildren) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [routesHistory, setRoutesHistory] = useState<Array<AppRoutes>>([location.pathname as AppRoutes]);
  const [animationDirection, setAnimationDirection] = useState<PageAnimationDirection>();
  const { resetScrolling } = useScrollPosition();

  const handleNavigation = (path: AppRoutes | -1, state?: string) => {
    if (path === -1) {
      setAnimationDirection('right');
      navigate(path);
      resetScrolling();
      return;
    }
    const nextRouteIndex = RoutesIndex[path] || 99;
    const currentRouteIndex = RoutesIndex[routesHistory[routesHistory.length - 1] as AppRoutes] || 99;

    setAnimationDirection(nextRouteIndex > currentRouteIndex ? 'left' : 'right');
    resetScrolling();
    navigate(path, { state: state });
  };

  useEffect(() => {
    setRoutesHistory(prev => [...prev, location.pathname as AppRoutes]);
  }, [location]);

  const value: RouteContextProps = {
    routesHistory,
    setAnimationDirection,
    animationDirection,
    handleNavigation,
  };

  return <RouteContext.Provider value={value}>{children}</RouteContext.Provider>;
};
