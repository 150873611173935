import styled from 'styled-components';
import { useScreenAnimation } from '../Hooks';
import { BackButton, FakeHeader, SwipeBackContainer, TransactionsList } from '../Components';
import { getPastTransactions, getTransactionsByCategory, getTransactionsTotal, MoneyFormatter } from '../Utils';
import { motion } from 'framer-motion';
import { useTransactionsContext } from '../Contexts';
import { Categories_Design } from '../Design';
import { app_config } from '../Utils/app_config';
import { Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { Card } from 'antd-mobile';

const { FOOTER_HEIGHT } = app_config;
export const FAKE_HEADER_HEIGHT = 180;
export const PaymentsOverviewCategoryPage: React.FC = () => {
  const { allTransactions, categoryOverview } = useTransactionsContext();
  const animation = useScreenAnimation();
  const pastPayments = getPastTransactions(allTransactions, 'Payment');
  const transactionsByCategory = getTransactionsByCategory(pastPayments);
  const total = getTransactionsTotal(pastPayments);

  const transactions = transactionsByCategory[categoryOverview];
  const percentage = transactions ? ((transactions?.total / total) * 100).toFixed(1) : 0;
  const backgroundColor = Categories_Design[categoryOverview].backgroundColor;

  return (
    <StyledAnimatedContainer style={{ minWidth: '100vw' }} {...animation}>
      <SwipeBackContainer>
        <FakeHeader headerColor={backgroundColor}>
          <div style={{ textAlign: 'left', position: 'absolute' }}>
            <BackButton />
          </div>
          <Row align='middle' justify='center'>
            <Col>
              <Typography.Text style={{ color: '#fff' }}>{categoryOverview} Overview</Typography.Text>
            </Col>
          </Row>
          <StyledAmountContainer>
            <StyledAmount>{MoneyFormatter.format(transactions?.total || 0)}</StyledAmount>
            <StyledMonthYear>{dayjs().format('MMMM YYYY')}</StyledMonthYear>
          </StyledAmountContainer>
          <Row gutter={[12, 8]} wrap={false} justify='center'>
            <Col span={7}>
              <StyledKPICard>
                <Typography.Text type='secondary'>Spendings</Typography.Text>
                <Typography.Text>{transactions?.count || 0}</Typography.Text>
              </StyledKPICard>
            </Col>
            <Col span={7}>
              <StyledKPICard>
                <Typography.Text type='secondary'>Percentage</Typography.Text>
                <Typography.Text>{percentage}%</Typography.Text>
              </StyledKPICard>
            </Col>
            <Col span={7}>
              <StyledKPICard>
                <Typography.Text type='secondary'>Recurrent</Typography.Text>
                <Typography.Text>{transactions?.recurrent || 0}</Typography.Text>
              </StyledKPICard>
            </Col>
          </Row>
        </FakeHeader>
        <StyledPaymentsOverviewCategoryPage>
          <TransactionsList transactions={transactions?.transactions || []} />
        </StyledPaymentsOverviewCategoryPage>
      </SwipeBackContainer>
    </StyledAnimatedContainer>
  );
};

const StyledPaymentsOverviewCategoryPage = styled.div`
  .adm-list-item {
    z-index: -0 !important;
  }
  margin-top: 48px;
  padding: 0px 12px;
  padding-bottom: calc(${FOOTER_HEIGHT}px + env(safe-area-inset-bottom) + 212px);
  overflow: auto;
  max-height: calc(100vh - ${FAKE_HEADER_HEIGHT}px - env(safe-area-inset-top));
`;
const StyledAnimatedContainer = styled(motion.div)`
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
`;

const StyledAmountContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  .ant-typography {
    color: #fff !important;
  }
`;

const StyledMonthYear = styled(Typography.Text)`
  display: block;
  font-size: 12px;
  margin-top: 8px;
`;

const StyledAmount = styled(Typography.Text)`
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 28px;
  padding: 4px 12px;
  border-radius: 24px;
`;

const StyledKPICard = styled(Card)`
  text-align: center;
  .ant-typography {
    display: block;
    margin-bottom: 4px;
  }
  font-weight: 600;
  font-size: 20px;
  .ant-typography:first-of-type {
    font-size: 12px;
  }
`;

export default PaymentsOverviewCategoryPage;
