import { Card, List } from 'antd-mobile';
import styled from 'styled-components';
import { theme } from '../../Design';
import { Category, Transaction } from '../../Model';
import { CategoryListItem } from '../Items';

export interface PaymentByCategory {
  count: number;
  total: number;
}

interface Props {
  pastPayments: Transaction[];
  isLoading?: boolean;
  limit?: number;
}

export const PaymentsOverview: React.FC<Props> = ({ limit, pastPayments, isLoading }) => {
  const totalPayments = pastPayments.reduce((acc, curr) => acc + curr.amount, 0);
  const paymentsByCategory = pastPayments.reduce<Record<Category, PaymentByCategory>>((acc, curr) => {
    return {
      ...acc,
      [curr.category!]: {
        count: acc[curr.category!] ? acc[curr.category!].count + 1 : 1,
        total: acc[curr.category!] ? acc[curr.category!].total + curr.amount : curr.amount,
      },
    };
  }, {} as Record<Category, PaymentByCategory>);

  const categories = Object.keys(paymentsByCategory)
    .sort((category, category2) =>
      paymentsByCategory[category as Category].total > paymentsByCategory[category2 as Category].total ? -1 : 1,
    )
    .slice(0, limit);

  return (
    <StyledPaymentsOverview>
      <List>
        {categories.map(category => {
          const percentage = (paymentsByCategory[category as Category].total / totalPayments) * 100;
          const { count, total } = paymentsByCategory[category as Category];
          return (
            <List.Item key={category}>
              <CategoryListItem percentage={percentage} category={category as Category} count={count} total={total} />
            </List.Item>
          );
        })}
      </List>
    </StyledPaymentsOverview>
  );
};

const StyledPaymentsOverview = styled(Card)`
  .adm-list {
    --border-inner: none;
  }
  .adm-list-item {
    padding: 6px 16px;
    :active {
      background-color: ${theme.colors.primaryShades['primary-1']};
    }
  }
  .adm-list-body {
    border: none;
  }
  padding: 0px;
`;
