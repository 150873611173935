import { Timestamp } from '@firebase/firestore';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DAY_MONTH_ONLY_FORMAT = 'MMMM DD';

export const nbWeeksInYear = 52.143;
export const nbWeeksInMonth = 4.34524;

export interface IsDirty {
  isDirty?: boolean;
  isNewlyCreated?: boolean;
}

export interface TimeStamps {
  lastUpdatedAt?: Timestamp | string;
  createdAt?: Timestamp;
}
