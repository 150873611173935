import { Col, Row } from 'antd';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { CardButton, ScrollableScreenContainer } from '../Components';
import { useRouteContext } from '../Contexts';
import {
  AccountsIllustration,
  // CategoriesIllustration,
  ConnectIllustration,
  CrystalBallIllustration,
  InsightsIllustration,
} from '../Design';
import { useScreenAnimation } from '../Hooks';
import { AppRoutes } from '../Model';

interface Props {}

export const MorePage: React.FC<Props> = () => {
  const animation = useScreenAnimation();
  const { handleNavigation } = useRouteContext();
  return (
    <StyledAnimatedContainer {...animation}>
      <ScrollableScreenContainer smallPadding>
        <StyledMorePage>
          <Row gutter={[8, 12]}>
            <Col span={24}>
              <CardButton
                description='Create and compare budgets without affecting your actual data.'
                onClick={() => handleNavigation(AppRoutes.Playgrounds)}
                icon={<CrystalBallIllustration />}
                title='Playgrounds'
              />
            </Col>
            <Col span={24}>
              <CardButton
                description='See how you have been performing lately and over the past months.'
                onClick={() => handleNavigation(AppRoutes.Insights)}
                icon={<InsightsIllustration />}
                title='Insights'
              />
            </Col>
            <Col span={24}>
              <CardButton
                description='Bank accounts, Credit cards, Lines of Credit etc.. Any accessible cash'
                icon={<AccountsIllustration />}
                title='Accounts'
              />
            </Col>
            <Col span={24}>
              <CardButton
                description='Connect with your friends and family. Manage your shared budget together and better.'
                icon={<ConnectIllustration />}
                title='Vi Connect'
              />
            </Col>
            {/* <Col span={24}>
              <CardButton
                description='Ceci est ton espace pour faire des simulations sur tes dépenses et avoir de la visibilité sur ton cashflow'
                icon={<CategoriesIllustration />}
                title='Categories'
              />
            </Col> */}
          </Row>
        </StyledMorePage>
      </ScrollableScreenContainer>
    </StyledAnimatedContainer>
  );
};

const StyledMorePage = styled.div``;

const StyledAnimatedContainer = styled(motion.div)`
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
`;
