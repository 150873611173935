import styled from 'styled-components';

interface Props {}

export const CashflowStale: React.FC<Props> = () => {
  return (
    <StyledCashflowStale>
      <svg width='61' height='61' viewBox='0 0 61 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.236023' y='0.42041' width='60' height='60' rx='16' fill='#F4F5F9' />
        <path
          d='M37.0119 19.6153C31.9462 18.2424 26.7661 17.9917 22.1914 21.1729C20.0398 22.6691 18.3919 24.7875 17.707 27.3337C16.7526 27.5113 16.1481 27.3833 15.9047 27.1288C15.5463 26.754 15.9138 25.5059 16.9714 26.3551L18.1321 24.9123C17.0843 24.0708 16.073 24.0487 15.2884 24.4455C13.8483 25.1748 13.4312 27.2226 14.5703 28.412C15.1122 28.978 16.0705 29.3476 17.3858 29.243C17.2114 31.6566 17.8742 34.3175 19.6464 37.0593L18.7489 38.4511L24.0792 42.6545L25.8157 41.3418C27.6474 41.8372 29.4994 42.0721 31.3691 42.0767C33.2354 42.0819 35.1167 41.8547 37.0099 41.431L38.6334 42.6635L43.8162 38.381L43.1491 37.1328C43.5707 36.5315 44.106 35.6326 44.358 34.9557H46.5561L46.5506 26.7921L44.7588 26.7425C44.1465 25.1398 43.211 23.7455 41.91 22.62L40.7336 18.1777L37.0119 19.6153Z'
          fill='#020B36'
        />
        <path
          d='M33.8621 22.4786C31.3886 21.8325 28.9602 21.9752 26.6101 23.0002L27.294 24.5467C29.2839 23.6824 31.3323 23.574 33.424 24.1184L33.8621 22.4786Z'
          fill='white'
        />
        <circle cx='39.1925' cy='27.8597' r='1.90464' fill='white' />
      </svg>
    </StyledCashflowStale>
  );
};

const StyledCashflowStale = styled.div``;
