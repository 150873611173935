import { Transaction } from "../Model"

export const calculateSpendingByDate = (transactions:Transaction[]):Record<string,number> => {
    const data = transactions.reduce((acc:Record<string,number>,curr)=>{
      if(acc[curr.date]) {
        return {...acc, [curr.date] : acc[curr.date] + curr.amount }
      } else {
        return {...acc, [curr.date] : curr.amount}
      }
    },{})
    return data
  }
  
  export const getDailySpendingsTrendsData = (spendings:Transaction[]) => {
    const spendingByDate = calculateSpendingByDate(spendings)
    spendings.forEach((spending)=>spendingByDate[spending.date] = spendingByDate[spending.date] + spending.amount )
    return  Object.keys(spendingByDate).sort((keyA,keyB)=>keyA > keyB ? 1 : -1).map((date)=>({date : date,value:spendingByDate[date]}))
    
  }
