import { CommonIcons } from '../../Design';
import { AppRoutes } from '../../Model';

export interface MenuItem {
  title: string;
  url: AppRoutes;
  icon: React.ReactElement;
  activeIcon: React.ReactElement;
  key: string;
}

export type Menu = MenuItem[];

export const useMenuItems = () => {
  const { IncomeIcon, PaymentIcon, Dashboard, MoreIcon } = CommonIcons;
  const menuItems: Menu = [
    {
      title: 'Dashboard',
      url: AppRoutes.Dashboard,
      icon: <Dashboard />,
      activeIcon: <Dashboard />,
      key: AppRoutes.Dashboard,
    },
    {
      title: 'Income',
      url: AppRoutes.Income,
      icon: <IncomeIcon />,
      activeIcon: <IncomeIcon />,
      key: AppRoutes.Income,
    },
    {
      title: 'Payments',
      url: AppRoutes.Payments,
      icon: <PaymentIcon />,
      activeIcon: <PaymentIcon />,
      key: AppRoutes.Payments,
    },
    {
      title: 'More',
      url: AppRoutes.More,
      icon: <MoreIcon />,
      activeIcon: <MoreIcon />,
      key: AppRoutes.More,
    },
  ];
  return { menuItems };
};
