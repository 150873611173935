import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Frequency } from '../Model';

dayjs.extend(utc);

/**
 * Get the current month index
 * January is 0 and December is 11
 * @returns month index
 */
export const getCurrentMonth = () => new Date().getMonth();

/**
 * Get the current year
 * @returns current year
 */
export const getCurrentYear = () => new Date().getFullYear();

/**
 * Returns if the date is today or past today
 * @param date
 * @returns boolean
 */

export const isTodayOrPast = (date: string) => {
  const todaysDate = dayjs().format('YYYY-MM-DD');
  return date <= todaysDate;
};

/**
 * Returns if the date is strictly past today
 * @param date
 * @returns boolean
 */

export const isPastToday = (date: string) => {
  const todaysDate = dayjs().format('YYYY-MM-DD');
  return date < todaysDate;
};

/**
 * Returns if the date is today
 * @param date
 * @returns boolean
 */
export const isToday = (date: string) => {
  const todaysDate = dayjs().format('YYYY-MM-DD');
  return date === todaysDate;
};

/**
 * Get how many days are in the given month
 * @param month
 * @param year
 * @returns number of days
 */
export const getDaysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};

/**
 * Get the next occurence of the given date based on the frequency
 * @param date
 * @param frequency
 * @returns dayjs date object
 */
export const getNextDate = (date: string, frequency: Frequency) => {
  const year = Number(date.slice(0, 4));
  const month = Number(date.slice(5, 7));
  const day = Number(date.slice(8, 10));
  const dateObject = new Date(year, month - 1, day);
  const eventDate = dayjs(dateObject);
  const currentDateOfMonth = eventDate.date();
  if (frequency === Frequency.Monthly) return eventDate.add(1, 'month').date(currentDateOfMonth);
  if (frequency === Frequency.BiWeekly) return eventDate.add(2, 'weeks');
  if (frequency === Frequency.Weekly) return eventDate.add(1, 'weeks');
  if (frequency === Frequency.Annually) return eventDate.add(1, 'years');
  return dayjs();
};

export const getYearMonthDateRange = (currentMonth: number, currentYear: number, range: number) => {
  let startMonth = currentMonth - range + 1;
  let startYear = currentYear;
  if (startMonth < 0) {
    startMonth = startMonth + 12;
    startYear = currentYear - 1;
  }
  return {
    startMonth: `${startYear}-${MonthToMonthString[startMonth]}`,
    endMonth: `${currentYear}-${MonthToMonthString[currentMonth]}`,
  };
};

const MonthToMonthString: Record<number, string> = {
  0: '01',
  1: '02',
  2: '03',
  3: '04',
  4: '05',
  5: '06',
  6: '07',
  7: '08',
  8: '09',
  9: '10',
  10: '11',
  11: '12',
};
