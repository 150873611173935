import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { Transaction } from '../Model/Transaction';
import { auth, db, transactionsListPath } from '../Api';
import { collection, limit, orderBy, query, where } from 'firebase/firestore';
import dayjs from 'dayjs';
import { getDaysInMonth } from '../Utils';

interface QueryConfig {
  type: 'Income' | 'Payment' | 'ALL';
  recurrent?: boolean;
  limit?: number;
  yearMonth: string;
  endYearMonth?: string;
}

export const useTransactionsList = (config: QueryConfig) => {
  const [transactionsList, setTransactionsList] = useState<Transaction[]>([]);
  const [isLoading, setLoading] = useState(true);
  const uid = auth.currentUser?.uid;
  const yearMonth = dayjs().format('YYYY-MM');
  const currentYear = new Date().getFullYear();
  const date = dayjs().date();

  const startMonth = Number(config.yearMonth.split('-')[1]);
  const endMonth = config.endYearMonth ? Number(config.endYearMonth.split('-')[1]) : startMonth;
  const daysInMonth = getDaysInMonth(endMonth, currentYear);
  const queryEndDate = config.endYearMonth ? `${config.endYearMonth}-${daysInMonth}` : `${config.yearMonth}-${daysInMonth}`;
  const queryStartDate = `${config.yearMonth}-01`;

  const tQuery =
    config.type === 'ALL'
      ? query(
          collection(db, transactionsListPath(uid!)),
          where('date', '>=', queryStartDate),
          where('date', '<=', queryEndDate),
          limit(config.limit || 999),
          orderBy('date', 'desc'),
          orderBy('createdAt', 'desc'),
        )
      : query(
          collection(db, transactionsListPath(uid!)),
          where('type', '==', config.type),
          where('date', '>=', queryStartDate),
          where('date', '<=', queryEndDate),
          limit(config.limit || 999),
          orderBy('date', 'desc'),
          orderBy('createdAt', 'desc'),
        );

  useEffect(() => {
    const unsub = onSnapshot(
      tQuery,
      documents => {
        console.log(`%cFetched Past ${config.type}`, 'color:green;font-weight:bold');
        let TransactionsData: Array<Transaction> = [];
        documents.forEach(doc => {
          TransactionsData.push({
            ...(doc.data() as Transaction),
            id: doc.id,
          });
        });
        setTransactionsList(TransactionsData);
        setLoading(false);
      },
      error => {
        console.error(error.message);
      },
    );
    return () => {
      unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.limit, config.type, date, uid, yearMonth, config.yearMonth]);

  return { transactionsList, isLoading };
};
