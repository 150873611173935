import { addDoc, collection, deleteDoc, doc, Timestamp, updateDoc } from "firebase/firestore";
import { Transaction } from "../Model/Transaction";
import { db } from "./firebase";


export const recurrentTransactionsPath = (uid: string) => `accounts/${uid}/transactions/recurrent/list`
export const transactionsListPath = (uid: string) => `accounts/${uid}/transactions/past/list`
const transactionsListRef = (uid: string) => collection(db, transactionsListPath(uid));
const recurrentTransactionsRef = (uid: string) => collection(db, recurrentTransactionsPath(uid))

export const addTransaction = async (transaction: Transaction, uid: string) => {
  const ref = (transaction.recurrent && !transaction.paid) ? recurrentTransactionsRef(uid) : transactionsListRef(uid)
  return await addDoc(ref, {
    ...trimTransaction(transaction),
    createdAt: Timestamp.now(),
    lastUpdatedAt: Timestamp.now(),
  });
};

export const deleteTransaction = async (transaction: Transaction, uid: string) => {
  const path = (transaction.recurrent && !transaction.paid) ? recurrentTransactionsPath(uid) : transactionsListPath(uid)
  return await deleteDoc(doc(db, `${path}/${transaction.id}`));
};

export const updateTransaction = async (transaction: Transaction, uid: string) => {
  const path = (transaction.recurrent && !transaction.paid) ? recurrentTransactionsPath(uid) : transactionsListPath(uid)
  const ref = doc(db, `${path}/${transaction.id}`);
  return await updateDoc(ref, {
    ...trimTransaction(transaction),
    lastUpdatedAt: Timestamp.now(),
  });
};

const trimTransaction = (transaction: Transaction) => {
  const { id, ...baseTransaction } = transaction
  if (!transaction.recurrent) {
    const { frequency, paid, ...final } = baseTransaction
    if (transaction.type === 'Income') {
      const { category, ...incomeTransaction } = final
      return incomeTransaction
    }
    return final
  }
  return baseTransaction
}
