import { useLocation } from 'react-router';
import styled from 'styled-components';
import { HeaderIcon, theme } from '../../Design/';
import { useMenuItems } from './Menu';
import { useAuth } from '../../Contexts/AuthContext';
import { Avatar } from 'antd-mobile';
import otterAvatar from '../../Design/otterAvatar.png';
import { Transition, TransitionStatus } from 'react-transition-group';
import { Row, Typography } from 'antd';
import { useScrollPosition } from '../../Contexts';
import { motion } from 'framer-motion';
import { AppRoutes } from '../../Model';
import { CommonIcons } from '../../Design';
import { useRouteContext } from '../../Contexts';
import { MonthSelector } from '../MonthSelector';

export const MOBILE_HEADER_HEIGHT = 124;
export const MOBILE_HEADER_HEIGHT_SHRINKED = 56;

export const Header: React.FC = () => {
  const { BackIcon } = CommonIcons;
  const location = useLocation();
  const { logout } = useAuth();
  const { expandHeader } = useScrollPosition();
  const { menuItems } = useMenuItems();

  const getSectionName = (pathName: string) => {
    if (pathName === '/') {
      return 'Dashboard';
    }
    if (pathName === AppRoutes.UpcomingPaymentsList) return 'Upcoming Payments';
    if (pathName === AppRoutes.SpendingsList) return 'Spendings';
    if (pathName === AppRoutes.PaymentsOverview) return 'Payments Overview';
    if (pathName === AppRoutes.Playgrounds) return 'Playgrounds';
    if (pathName === AppRoutes.Insights) return 'Insights';
    return menuItems.find(x => x.url.includes(pathName.slice(1, pathName.length - 1).split('/')[0]))?.title;
  };

  const NESTED_SCREENS = [
    AppRoutes.UpcomingPaymentsList,
    AppRoutes.SpendingsList,
    AppRoutes.PaymentsOverview,
    AppRoutes.PlaygroundPage,
    AppRoutes.Playgrounds,
    AppRoutes.Insights,
  ];
  const NON_EXPANDABLE_HEADER_SCREENS = [AppRoutes.More];

  const isNestedScreen = NESTED_SCREENS.includes(location.pathname as AppRoutes);
  const isSecondaryColorHeader = false;
  const isExpandableHeaderScreen = !NON_EXPANDABLE_HEADER_SCREENS.includes(location.pathname as AppRoutes);

  const isExpanded = expandHeader && !isNestedScreen && isExpandableHeaderScreen;

  const { handleNavigation } = useRouteContext();

  const handleNavigateBack = () => handleNavigation(-1);

  const transitionStyles: Record<TransitionStatus, any> = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { display: 'none' },
  };
  const transitionStyles2: Record<TransitionStatus, any> = {
    entering: { height: `calc(${MOBILE_HEADER_HEIGHT_SHRINKED}px + env(safe-area-inset-top))`, borderRadius: '0px 0px 0px 0px' },
    entered: { height: `calc(${MOBILE_HEADER_HEIGHT}px + env(safe-area-inset-top))`, borderRadius: '0px 0px 36px 36px' },
    exiting: { height: `calc(${MOBILE_HEADER_HEIGHT_SHRINKED}px + env(safe-area-inset-top))`, borderRadius: '0px 0px 0px 0px' },
    exited: { height: `calc(${MOBILE_HEADER_HEIGHT_SHRINKED}px + env(safe-area-inset-top))`, borderRadius: 0 },
    unmounted: {},
  };

  const defaultStyle = {
    transition: `opacity 160ms ease-in`,
    width: '100%',
    zIndex: 1,
  };

  const defaultStyle2 = {
    willChange: 'height',
    height: `calc(${MOBILE_HEADER_HEIGHT}px + env(safe-area-inset-top))`,
    borderRadius: '0px',
    backgroundColor: isSecondaryColorHeader ? theme.colors.secondary : '',
  };

  return (
    <Transition in={isExpanded} timeout={{ appear: 0, enter: 80, exit: 300 }}>
      {state => (
        <StyledMobileHeader
          style={{
            ...defaultStyle2,
            ...transitionStyles2[state],
          }}
        >
          <StyledContainer>
            {isNestedScreen ? (
              <div onClick={handleNavigateBack} style={{ height: 24 }}>
                <BackIcon />
              </div>
            ) : (
              <StyledIconContainer style={{ height: 44, width: 44 }}>
                <HeaderIcon />
              </StyledIconContainer>
            )}
            <Typography.Text style={{ opacity: isExpanded ? 0 : 1, transition: 'opacity 0.2s', willChange: 'opacity' }}>
              {getSectionName(location.pathname)}
            </Typography.Text>
            <Avatar onClick={logout} src={otterAvatar} />
          </StyledContainer>
          <Transition in={isExpanded} timeout={{ appear: 0, enter: 50, exit: 50 }}>
            {state => (
              <div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
              >
                <StyledTitleAndDateRow align='middle' justify='space-between'>
                  <h1>{!isNestedScreen && getSectionName(location.pathname)}</h1>
                  <MonthSelector />
                </StyledTitleAndDateRow>
              </div>
            )}
          </Transition>
        </StyledMobileHeader>
      )}
    </Transition>
  );
};

const StyledTitleAndDateRow = styled(Row)`
  > div {
    display: flex;
    align-items: center;
  }
  button {
    color: #fff;
  }
  .ant-typography {
    transform: translateY(2px);
    color: #fff;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px 6px 12px;
  .ant-typography {
    color: #fff !important;
    margin: 0px !important;
  }
  svg {
    font-size: 24px;
    transform: translateY(0px) !important;
  }
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledMobileHeader = styled(motion.div)`
  transform: translate3d(0);
  -webkit-transform: translateZ(0);
  transition: all 130ms linear;
  transition-delay: 0.1s;
  padding-top: calc(env(safe-area-inset-top)) !important;
  padding-left: calc(env(safe-area-inset-left)) !important;
  padding-right: calc(env(safe-area-inset-right)) !important;
  position: fixed;
  top: 0;
  h1 {
    margin: 0px;
    margin-top: 4px;
    margin-left: 24px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
  }
  svg {
    transform: translateY(4px);
  }
  color: white;
  font-weight: 500;
  font-size: 16px;
  background-color: ${theme.colors.primary};
  width: 100%;
  z-index: 2;
`;
