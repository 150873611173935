import { Col, Row, Typography } from 'antd';
import { Card } from 'antd-mobile';
import styled from 'styled-components';
import { PrimaryGradient, theme } from '../../Design';
import { MONTHS_LABEL, Transaction } from '../../Model';
import { MoneyFormatter, getDailySpendingsTrendsData } from '../../Utils';
import Trend from 'react-trend';
import { useTransactionsContext } from '../../Contexts';

interface Props {
  pastPayments: Transaction[];
}

export const TotalSpendings: React.FC<Props> = ({ pastPayments }) => {
  const { selectedMonth } = useTransactionsContext();
  const totalSpendings = pastPayments.reduce((acc, curr) => acc + curr.amount, 0);
  const dailySpendingsTrendsData = getDailySpendingsTrendsData(pastPayments).map(t => t.value);

  return (
    <StyledTotalSpendings>
      <Card>
        <Row align='middle'>
          <Col flex='150px'>
            <Typography.Title level={4}>Total Spent</Typography.Title>
            <Typography.Title level={2}>{MoneyFormatter.format(totalSpendings)}</Typography.Title>
            <Typography.Text style={{ fontSize: 14 }} type='secondary'>
              {MONTHS_LABEL[selectedMonth]}
            </Typography.Text>
          </Col>
          <Col flex='1'>
            <Trend
              height={100}
              smooth
              autoDrawEasing='ease-out'
              data={dailySpendingsTrendsData}
              gradient={PrimaryGradient}
              radius={36}
              strokeWidth={6}
              strokeLinecap={'round'}
              padding={0}
            />
          </Col>
        </Row>
      </Card>
    </StyledTotalSpendings>
  );
};

const StyledTotalSpendings = styled.div`
  span.ant-typography:nth-of-type(1) {
    font-size: 16px;
    font-weight: 500;
  }
  .adm-card {
    padding: 12px 16px;
  }
  .adm-card-body {
    padding: 0px;
  }
  h2 {
    margin: 0px !important;
    color: ${theme.colors.primaryShades['primary-9']};
  }
  h4 {
    margin-bottom: 4px;
  }
`;
