import { Typography } from 'antd';
import { Button } from 'antd-mobile';
import styled from 'styled-components';
import { useTransactionsContext } from '../Contexts';
import { CommonIcons } from '../Design';

interface Props {}

const MONTH_LABELS: Record<number, string> = {
  0: 'Jan',
  1: 'Feb',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

export const MonthSelector: React.FC<Props> = () => {
  const { ChevronLeft, ChevronRight } = CommonIcons;
  const { selectedMonth, setSelectedMonth } = useTransactionsContext();
  const currentMonth = new Date().getMonth();
  const handleIncrement = () => setSelectedMonth(selectedMonth + 1);
  const handleDecrement = () => setSelectedMonth(selectedMonth - 1);
  return (
    <StyledMonthSelector>
      <Button disabled={0 === selectedMonth} onClick={handleDecrement} color='primary'>
        <ChevronLeft />
      </Button>
      <Typography.Text>{MONTH_LABELS[selectedMonth]}</Typography.Text>
      <Button disabled={selectedMonth >= currentMonth} onClick={handleIncrement} color='primary'>
        <ChevronRight />
      </Button>
    </StyledMonthSelector>
  );
};

const StyledMonthSelector = styled.div`
  .ant-typography {
    border: 1px solid white;
    border-radius: 16px;
    padding: 2.5px 12px;
  }
  button {
    background-color: none !important;
  }
  button:nth-of-type(1) {
    padding: 4px 6px;
  }
  button:nth-of-type(2) {
    padding: 4px 15px 4px 6px;
  }
  .adm-button {
    --background-color: none;
    --border-color: none;
  }
`;
