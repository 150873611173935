export enum AppRoutes {
  Dashboard = '/',
  Income = '/income',
  Payments = '/payments',
  Login = '/login',
  More = "/more",
  UpcomingPaymentsList = '/payments/upcoming',
  SpendingsList = '/payments/list',
  PaymentsOverview = '/payments/overview',
  PaymentsOverviewCategory = '/payments/overview/category',
  Playgrounds = "/more/playgrounds",
  PlaygroundPage = "/more/playgrounds/:id",
  Insights = "/more/insights",
}

export const RoutesIndex: Record<AppRoutes, number> = {
  "/login": 0,
  "/": 1,
  "/income": 2,
  "/payments": 3,
  "/more": 4,
  "/more/playgrounds": 5,
  "/more/insights": 5,
  "/payments/upcoming": 5,
  '/payments/list': 5,
  '/payments/overview': 5,
  '/payments/overview/category': 6,
  '/more/playgrounds/:id': 6,
}
