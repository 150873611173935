import { Col, Row, Typography } from 'antd';
import { SpinLoading } from 'antd-mobile';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import {
  BackButton,
  EditPageTitle,
  FakeHeader,
  PlaygroundCashflowCard,
  PlaygroundTransactionsList,
  PlaygroundTrend,
  SelectedTag,
  StyledEditDrawer,
  SwipeBackContainer,
  TransactionEditForm,
  TransactionsFiltersSwitch,
  TrendType,
} from '../Components';
import { PlaygroundProvider, usePlaygroundContext } from '../Contexts';
import { AddButtonIcon } from '../Design';
import { useScreenAnimation } from '../Hooks';
import { PlaygroundTransaction } from '../Model';
import { FAKE_HEADER_HEIGHT } from './PaymentsOverviewCategory';

interface Props {
  pageTitle: string;
}
export const PlaygroundPage = () => {
  const location = useLocation();
  const pathnameArray = location.pathname.split('/');
  const id = pathnameArray[pathnameArray.length - 1];
  const title = String(location.state);
  return (
    <PlaygroundProvider id={id}>
      <PlaygroundPageContent pageTitle={title} />
    </PlaygroundProvider>
  );
};

export const PlaygroundPageContent: React.FC<Props> = ({ pageTitle }) => {
  const animation = useScreenAnimation();
  const { playgroundTransactions, isLoading, actualData, projectedData, actualCashflow, projectedCashflow, addTransaction } =
    usePlaygroundContext();
  const [selectedTag, setSelectedTag] = useState(SelectedTag.All);
  const cashFlowDifference = projectedCashflow - actualCashflow;
  const incomeDifference = projectedData.totalIncome - actualData.totalIncome;
  const paymentsDifference = projectedData.totalPayments - actualData.totalPayments;
  const [addNewTransaction, setAddNewTransaction] = useState(false);

  const handleAddTransactionClick = () => setAddNewTransaction(true);
  const closeAddModal = () => setAddNewTransaction(false);

  const handleAddTransaction = (transaction: PlaygroundTransaction) => {
    addTransaction(transaction);
    closeAddModal();
  };

  const dataRenderer = {
    [SelectedTag.All]: playgroundTransactions,
    [SelectedTag.Payments]: playgroundTransactions.filter(t => t.type === 'Payment'),
    [SelectedTag.Income]: playgroundTransactions.filter(t => t.type === 'Income'),
  };

  if (isLoading) return <SpinLoading />;

  return (
    <SwipeBackContainer>
      <StyledPlaygroundPage {...animation}>
        <FakeHeader headerColor='#041257'>
          <div style={{ textAlign: 'left', position: 'absolute' }}>
            <BackButton />
          </div>
          <Row style={{ marginBottom: 8 }} align='middle' justify='center'>
            <Col>
              <Typography.Text style={{ color: '#fff' }}>{pageTitle}</Typography.Text>
            </Col>
          </Row>
          <Typography.Title level={3} style={{ color: '#fff' }}>
            Monthly Cashflow
          </Typography.Title>
          <Row gutter={[12, 12]} justify='center'>
            <Col>
              <PlaygroundCashflowCard key='actual' disabled title='Actual' data={actualData} />
            </Col>
            <Col>
              <PlaygroundCashflowCard key='New' title='New' data={projectedData} />
            </Col>
          </Row>
        </FakeHeader>
        <PageBodyContainer>
          <Typography.Title level={3}>Trends</Typography.Title>
          <StyledTrendsRow wrap={false} justify='center' gutter={[12, 12]}>
            <Col>
              <PlaygroundTrend amount={paymentsDifference} type={TrendType.Payment} />
            </Col>
            <Col>
              <PlaygroundTrend amount={incomeDifference} type={TrendType.Income} />
            </Col>
            <Col>
              <PlaygroundTrend amount={cashFlowDifference} type={TrendType.Cashflow} />
            </Col>
          </StyledTrendsRow>
          <TransactionsFiltersSwitch value={selectedTag} onChange={setSelectedTag} />
          <StyledListContainer>
            <PlaygroundTransactionsList transactions={dataRenderer[selectedTag]} />
          </StyledListContainer>
          <StyledAddButtonContainer onClick={handleAddTransactionClick}>
            <AddButtonIcon />
          </StyledAddButtonContainer>
        </PageBodyContainer>
        <StyledEditDrawer
          title={<EditPageTitle title='Add Transaction' />}
          destroyOnClose
          placement='bottom'
          visible={addNewTransaction}
          onClose={() => setAddNewTransaction(false)}
          height='90%'
        >
          <TransactionEditForm mode='create' isLoading={false} onSave={handleAddTransaction} onCancel={closeAddModal} />
        </StyledEditDrawer>
      </StyledPlaygroundPage>
    </SwipeBackContainer>
  );
};

const StyledAddButtonContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 41%;
  border-radius: 50%;
  height: 72px;
  width: 72px;
  box-shadow: 1px 4px 10px 2px rgba(0, 0, 0, 0.15);
  overscroll-behavior: none;
  touch-action: none;
`;
const StyledListContainer = styled.div`
  margin-left: -8px;
  margin-right: -8px;
  overflow: auto;
  height: calc(100vh - ${FAKE_HEADER_HEIGHT}px - env(safe-area-inset-top) - 310px);
  ::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }
`;

const StyledTrendsRow = styled(Row)`
  margin-bottom: 20px;
  touch-action: none;
`;

const PageBodyContainer = styled.div`
  position: fixed;
  overscroll-behavior: none;
  .adm-list-item {
    z-index: -0 !important;
  }
  transform: translateY(46px);
  padding: 0px 12px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 76px);
  overflow: auto;
  max-height: calc(100vh - ${FAKE_HEADER_HEIGHT}px - env(safe-area-inset-top));
  width: 100%;
  padding: 0px 16px;
  h3 {
    margin-bottom: 4px;
  }
`;

const StyledPlaygroundPage = styled(motion.div)`
  overscroll-behavior: none;
  position: fixed;
  right: 0;
  left: 0;
`;
