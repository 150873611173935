import { Col, Row, Tag } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { Frequencies, Frequency } from '../../Model';
import { theme } from '../../Design';

interface Props {
  selectedFrequency?: Frequency;
  handleClickFrequency: (frequency: Frequency) => void;
  withOnetime?: boolean;
  primary?: boolean;
}

export const FrequencyRadios: React.FC<Props> = ({ selectedFrequency, handleClickFrequency, withOnetime = false, primary = false }) => {
  const [selectedFrequencyLocal, setSelectedFrequencyLocal] = useState(selectedFrequency);
  const handleSwitchingFrequency = (frequency: Frequency) => {
    setSelectedFrequencyLocal(frequency);
    handleClickFrequency(frequency);
  };
  return (
    <StyledFrequencyRadios gutter={[8, 8]} align='middle'>
      {Frequencies.map(frequency => (
        <Col key={frequency} flex='auto'>
          <StyledTag
            $primary={primary}
            onClick={() => handleSwitchingFrequency(frequency)}
            $isSelected={selectedFrequencyLocal === frequency}
            key={frequency}
          >
            {frequency.toLocaleLowerCase()}
          </StyledTag>
        </Col>
      ))}
      {withOnetime && (
        <Col key={Frequency.Onetime} flex='auto'>
          <StyledTag
            $primary={primary}
            onClick={() => handleSwitchingFrequency(Frequency.Onetime)}
            $isSelected={selectedFrequencyLocal === Frequency.Onetime}
            key={Frequency.Onetime}
          >
            {Frequency.Onetime.toLocaleLowerCase()}
          </StyledTag>
        </Col>
      )}
    </StyledFrequencyRadios>
  );
};

const StyledTag = styled(Tag)<{ $isSelected: boolean; $primary?: boolean }>`
  font-size: 16px;
  border-radius: 32px;
  padding: 8px;
  width: 100%;
  text-align: center;
  background-color: ${({ $isSelected, $primary }) =>
    $isSelected ? ($primary ? theme.colors.primary : theme.colors.primaryShades['primary-9']) : theme.colors.primaryShades['primary-1']};
  color: ${({ $isSelected, $primary }) => ($isSelected ? '#fff' : $primary ? theme.colors.primary : 'inherit')};
  border: none;
`;

const StyledFrequencyRadios = styled(Row)``;
