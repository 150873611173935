import { Col, Row, Typography } from 'antd';
import { Switch } from 'antd-mobile';
import styled from 'styled-components';
import { usePlaygroundContext } from '../../Contexts';
import { Categories_Design, CommonIcons, theme } from '../../Design';
import { Category, PlaygroundTransaction } from '../../Model';
import { MoneyFormatter } from '../../Utils';
import { EditPageTitle, StyledEditDrawer, TransactionEditForm } from '../EditPage';

interface Props {
  transaction: PlaygroundTransaction;
  isInEdit: boolean;
  onEditClose: () => void;
}

const { IncomeIcon } = CommonIcons;

export const PlaygroundTransactionCard: React.FC<Props> = ({ transaction, isInEdit, onEditClose }) => {
  const { addToInvisibleTransactions, removeFromInvisibleTransactions, invisibleTransactionsIds, editTransaction } = usePlaygroundContext();
  const { id } = transaction;
  const handleToggle = (checked: boolean) => (checked ? removeFromInvisibleTransactions(id) : addToInvisibleTransactions(id));
  const checked = !invisibleTransactionsIds.includes(id);
  return (
    <StyledPlaygroundTransactionCard>
      <Col flex='1 150px '>
        <Row style={{ height: 50 }} gutter={[8, 8]} align='middle'>
          <Col>
            {transaction.type === 'Income' ? (
              <StyledIcon $color='#237804'>{<IncomeIcon />}</StyledIcon>
            ) : transaction.category ? (
              <StyledIcon
                color={Categories_Design[transaction.category! as Category].chartColor}
                $color={Categories_Design[transaction.category! as Category].chartColor}
              >
                {Categories_Design[transaction.category! as Category].icon}
              </StyledIcon>
            ) : null}
          </Col>
          <Col>
            <StyledTitle ellipsis>{transaction.name}</StyledTitle>
            {transaction.recurrent && <StyledCategoryTypo type='secondary'>{transaction.frequency}</StyledCategoryTypo>}
          </Col>
        </Row>
      </Col>
      <Col flex='min-content'>
        <Row justify='end' align='middle'>
          <Col style={{ textAlign: 'right', color: theme.colors.primaryShades['primary-9'] }} span={24}>
            <Typography.Text style={{ maxWidth: 110 }} ellipsis>
              {transaction.type === 'Payment' && !transaction.recurrent ? '-' : transaction.type === 'Income' ? '+' : ''}
              {MoneyFormatter.format(transaction.amount)}
            </Typography.Text>
          </Col>
          <Col>
            <Switch checked={checked} onChange={handleToggle} />
          </Col>
        </Row>
      </Col>
      <StyledEditDrawer
        title={<EditPageTitle title='Edit Transaction' />}
        destroyOnClose
        placement='bottom'
        visible={isInEdit}
        onClose={onEditClose}
        height='90%'
      >
        <TransactionEditForm
          mode='edit'
          initialValues={transaction}
          isLoading={false}
          onSave={transaction => {
            editTransaction(transaction);
            onEditClose();
          }}
          onCancel={onEditClose}
        />
      </StyledEditDrawer>
    </StyledPlaygroundTransactionCard>
  );
};

const StyledPlaygroundTransactionCard = styled(Row)`
  .adm-switch.adm-switch-checked .adm-switch-checkbox {
    background: ${theme.colors.primary};
  }
`;

const StyledIcon = styled.div<{ $color?: string }>`
  svg {
    font-size: 28px;
    transform: translateY(3px);
    color: ${({ $color }) => $color || 'white'}!important;
  }
`;

const StyledTitle = styled(Typography.Text)`
  display: block !important;
  max-width: 140px !important;
  margin-bottom: -4px;
`;

const StyledCategoryTypo = styled(Typography.Text)`
  font-size: 14px;
`;
