import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export const useMobileView = () => {
  const [isPWA, setIsPwa] = useState(false);

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsPwa(true);
    }
  }, []);
  const isMobile = useMediaQuery({
    maxWidth: 500,
  });
  const isMobilePortrait = useMediaQuery({
    maxWidth: 900,
    maxHeight: 500,
  });
  const showBackgroundBetaLaunch = useMediaQuery({
    minWidth: 1360,
  });

  const isMobileView = isMobile || isMobilePortrait;

  return { isMobileView, isMobile, isMobilePortrait, isPWA, showBackgroundBetaLaunch };
};
