import styled from 'styled-components';

interface Props {}

export const IncomeUp: React.FC<Props> = () => {
  return (
    <StyledIncomeUp>
      <svg width='61' height='61' viewBox='0 0 61 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.10051' y='0.193848' width='60' height='60' rx='16' fill='#F6FFED' />
        <path
          d='M23.4082 12.8926C18.8424 12.8926 15.1368 16.5955 15.1368 21.158C15.1368 25.7205 18.8424 29.4234 23.4082 29.4234C27.9741 29.4234 31.6797 25.7205 31.6797 21.158C31.6797 16.5955 27.9741 12.8926 23.4082 12.8926ZM23.4082 27.7703C19.7605 27.7703 16.7911 24.803 16.7911 21.158C16.7911 17.5129 19.7605 14.5457 23.4082 14.5457C27.056 14.5457 30.0254 17.5129 30.0254 21.158C30.0254 24.803 27.056 27.7703 23.4082 27.7703ZM23.6647 20.4472C22.2006 20.0752 21.7291 19.6702 21.7291 19.0668C21.7291 18.3725 22.3826 17.8849 23.4661 17.8849C24.6076 17.8849 25.0377 18.4304 25.0708 19.2404H26.4852C26.4439 18.1328 25.7656 17.1162 24.4256 16.7856V15.3722H22.4984V16.769C21.2494 17.0335 20.2486 17.8436 20.2486 19.0916C20.2486 20.5711 21.481 21.315 23.2759 21.7448C24.8888 22.125 25.2114 22.6953 25.2114 23.2904C25.2114 23.7285 24.8888 24.4393 23.4744 24.4393C22.151 24.4393 21.6299 23.8442 21.5554 23.0838H20.1328C20.2155 24.4889 21.2577 25.2824 22.4984 25.5386V26.9438H24.4339V25.5634C25.6912 25.3237 26.6837 24.6046 26.692 23.2739C26.6837 21.4555 25.1204 20.8274 23.6647 20.4472Z'
          fill='#389E0D'
        />
        <path
          d='M41.3014 25.3696C37.6236 23.8081 33.3706 25.5234 31.8079 29.1985C30.2453 32.8735 31.9619 37.1235 35.6396 38.685C39.3174 40.2465 43.5704 38.5311 45.1331 34.8561C46.6957 31.181 44.9791 26.9311 41.3014 25.3696ZM36.2058 37.3534C33.2676 36.1059 31.892 32.7003 33.1404 29.7642C34.3888 26.8282 37.797 25.4536 40.7352 26.7011C43.6734 27.9486 45.049 31.3543 43.8006 34.2903C42.5521 37.2264 39.144 38.6009 36.2058 37.3534ZM38.9205 31.5424C37.8686 30.7421 37.6275 30.2546 37.8342 29.7686C38.072 29.2094 38.7654 29.0401 39.6382 29.4106C40.5576 29.801 40.7172 30.3875 40.4664 31.0513L41.6057 31.535C41.9518 30.6287 41.7536 29.5779 40.7875 28.8533L41.2716 27.7148L39.7192 27.0557L39.2408 28.1809C38.1442 27.9668 37.0606 28.2769 36.6331 29.2822C36.1264 30.474 36.8643 31.4947 38.1629 32.4547C39.3319 33.3126 39.3964 33.8823 39.1926 34.3616C39.0425 34.7145 38.5392 35.1767 37.3999 34.693C36.3339 34.2404 36.118 33.5828 36.3185 32.9449L35.1725 32.4583C34.7579 33.6184 35.3256 34.614 36.2372 35.2447L35.756 36.3765L37.315 37.0384L37.7878 35.9266C38.8826 36.1635 39.9284 35.9237 40.3908 34.8547C41.007 33.3872 39.9629 32.3465 38.9205 31.5424V31.5424Z'
          fill='#389E0D'
        />
        <path
          d='M24.0932 37.3007C20.7467 38.6315 19.1117 42.4257 20.4435 45.7697C21.7754 49.1137 25.5723 50.7476 28.9188 49.4167C32.2652 48.0858 33.9003 44.2917 32.5685 40.9477C31.2366 37.6037 27.4397 35.9698 24.0932 37.3007ZM28.4362 48.2051C25.7627 49.2684 22.7201 47.9591 21.656 45.2875C20.592 42.6159 21.9022 39.5755 24.5758 38.5123C27.2493 37.449 30.2919 38.7583 31.356 41.4299C32.42 44.1015 31.1098 47.1418 28.4362 48.2051ZM26.4864 42.763C25.3048 42.9171 24.841 42.7577 24.6649 42.3154C24.4622 41.8066 24.7988 41.2587 25.593 40.9428C26.4296 40.6101 26.9041 40.8846 27.1648 41.4686L28.2015 41.0563C27.8478 40.2566 27.0539 39.7092 25.9753 39.8574L25.5627 38.8215L24.1502 39.3833L24.5579 40.4071C23.7197 40.965 23.2226 41.8504 23.5869 42.7652C24.0188 43.8496 25.1393 44.0356 26.5803 43.8274C27.8735 43.6359 28.2764 43.9599 28.4501 44.3961C28.578 44.7171 28.549 45.3322 27.5124 45.7444C26.5424 46.1302 25.9867 45.8459 25.7102 45.3103L24.6674 45.725C25.1382 46.7307 26.1337 47.0085 27.1179 46.8346L27.5281 47.8645L28.9467 47.3003L28.5437 46.2886C29.3953 45.7465 29.9129 44.9301 29.5305 43.9524C28.9936 42.622 27.6644 42.6173 26.4864 42.763Z'
          fill='#389E0D'
        />
        <path d='M12.4855 29.4233L14.7255 28.8232' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M15.2982 34.2912L16.6073 31.8735' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M20.4933 34.4487L19.8931 32.2105' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M51.8685 29.1318L48.6817 29.445' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M49.3537 21.7634L46.2967 24.0179' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
        <path d='M42.6017 18.2483L42.0369 21.3979' stroke='#389E0D' strokeWidth='2' strokeLinecap='round' />
      </svg>
    </StyledIncomeUp>
  );
};

const StyledIncomeUp = styled.div``;
