import { Col, Row } from 'antd';
import styled from 'styled-components';
import { ScrollableScreenContainer, TopCategories, TotalSpendings, TransactionsList } from '../Components';
import { CardWithTitle } from '../Components/Cards';
import { CashflowCard } from '../Components/Widgets/CashflowCard';
import { useScreenAnimation } from '../Hooks';
import { AppRoutes } from '../Model';
import { getPastTransactions, getUpcomingTransactions } from '../Utils/transactions-utils';
import { motion } from 'framer-motion';
import { useScrollPosition, useTransactionsContext } from '../Contexts';
import { theme } from '../Design';

export const Dashboard: React.FC = () => {
  const { allTransactions, isLoading, recurrentTransactions, transactionsList } = useTransactionsContext();
  const animation = useScreenAnimation();
  const { scrollPosition } = useScrollPosition();

  const upcomingPayments = getUpcomingTransactions(allTransactions, 'Payment');
  const pastPayments = getPastTransactions(allTransactions, 'Payment');
  const upcomingIncome = getUpcomingTransactions(allTransactions, 'Income');

  return (
    <StyledDashboard initial {...animation}>
      <ScrollableScreenContainer>
        <div
          style={{
            width: '100%',
            height: 250,
            backgroundColor: theme.colors.primary,
            position: 'absolute',
            top: -scrollPosition,
            left: 0,
            borderRadius: '0px 0px 24px 24px',
            transition: 'top 0.1s linear',
            transform: 'translateZ(0)',
          }}
        />
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <CashflowCard isLoading={isLoading} pastTransactions={transactionsList} recurrentTransactions={recurrentTransactions} />
          </Col>
          <Col span={24}>
            <TopCategories />
          </Col>
          <Col style={{ marginTop: -28 }} span={24}>
            <TotalSpendings pastPayments={pastPayments} />
          </Col>
          {Boolean(upcomingPayments.length) && (
            <Col span={24}>
              <CardWithTitle redirectUrl={AppRoutes.UpcomingPaymentsList} title='Upcoming Payments' isLoading={isLoading}>
                <TransactionsList showDue upcoming transactions={upcomingPayments} redirectUrl={`${AppRoutes.Payments}`} limit />
              </CardWithTitle>
            </Col>
          )}
          {Boolean(upcomingIncome.length) && (
            <Col span={24}>
              <CardWithTitle title='Upcoming Income' isLoading={isLoading}>
                <TransactionsList showDue upcoming limit transactions={upcomingIncome} redirectUrl={`${AppRoutes.Income}`} />
              </CardWithTitle>
            </Col>
          )}
          <Col span={24}>
            <CardWithTitle redirectUrl={AppRoutes.SpendingsList} title='Latest Spendings' isLoading={isLoading}>
              <TransactionsList transactions={pastPayments} redirectUrl={`${AppRoutes.Payments}/spendings`} limit />
            </CardWithTitle>
          </Col>
        </Row>
      </ScrollableScreenContainer>
    </StyledDashboard>
  );
};

const StyledDashboard = styled(motion.div)`
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
  > .ant-row {
    margin: 0px !important;
  }
`;

export default Dashboard;
