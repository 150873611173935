import { Col, Row, Typography } from 'antd';
import { Button } from 'antd-mobile';
import styled from 'styled-components';
import { useRouteContext, useTransactionsContext } from '../../Contexts';
import { CommonIcons, theme } from '../../Design';
import { AppRoutes, Category } from '../../Model';
import { getPastTransactions } from '../../Utils';
import { CategoryCard } from '../Items';
import { PaymentByCategory } from './PaymentsOverview';

interface Props {}

const MAX_ITEMS_COUNT = 4;

const { ForwardChevron } = CommonIcons;

export const TopCategories: React.FC<Props> = () => {
  const { handleNavigation } = useRouteContext();
  const { transactionsList } = useTransactionsContext();
  const pastPayments = getPastTransactions(transactionsList, 'Payment');
  const handleShowAll = () => handleNavigation(AppRoutes.PaymentsOverview);
  const { setCategoryOverview } = useTransactionsContext();

  const navigate = (category: Category) => {
    setCategoryOverview(category);
    handleNavigation(AppRoutes.PaymentsOverviewCategory, category);
  };

  const totalPayments = pastPayments.reduce((acc, curr) => acc + curr.amount, 0);
  const paymentsByCategory = pastPayments.reduce<Record<Category, PaymentByCategory>>((acc, curr) => {
    return {
      ...acc,
      [curr.category!]: {
        count: acc[curr.category!] ? acc[curr.category!].count + 1 : 1,
        total: acc[curr.category!] ? acc[curr.category!].total + curr.amount : curr.amount,
      },
    };
  }, {} as Record<Category, PaymentByCategory>);

  const categories = Object.keys(paymentsByCategory).sort((category, category2) =>
    paymentsByCategory[category as Category].total > paymentsByCategory[category2 as Category].total ? -1 : 1,
  );
  const renderedCategories = categories.slice(0, MAX_ITEMS_COUNT);

  if (!renderedCategories.length) return null;

  return (
    <StyledTopCategories>
      <Row align='middle' justify='space-between'>
        <Col>
          <Typography.Title level={3}>Top Categories</Typography.Title>
        </Col>
        <Col>
          <StyledSeeAllButton onClick={handleShowAll} fill='none'>
            see all <ForwardChevron />
          </StyledSeeAllButton>
        </Col>
      </Row>
      <StyledCategoriesRow wrap={false} gutter={[12, 12]}>
        {renderedCategories.map(category => (
          <Col onClick={() => navigate(category as Category)} key={category} span={14}>
            <CategoryCard
              percentage={(paymentsByCategory[category as keyof typeof paymentsByCategory].total / totalPayments) * 100}
              details={paymentsByCategory[category as keyof typeof paymentsByCategory]}
              category={category as Category}
            />
          </Col>
        ))}
      </StyledCategoriesRow>
    </StyledTopCategories>
  );
};

const StyledCategoriesRow = styled(Row)`
  /* margin-left: -12px !important; */
  /* margin-right: -12px !important; */
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  padding-top: 12px;
  padding-bottom: 32px;
`;

const StyledTopCategories = styled.div`
  h3 {
    margin: 0px;
  }
`;

export const StyledSeeAllButton = styled(Button)`
  font-weight: 500;
  --text-color: ${theme.colors.primary};
  display: 'flex';
  span {
    display: flex;
    align-items: center;
    svg {
      margin-left: 1px;
    }
  }
`;
