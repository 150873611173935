import { Col, Progress, Row, Tag, Typography } from 'antd';
import styled from 'styled-components';
import { useRouteContext, useTransactionsContext } from '../../Contexts';
import { Categories_Design, CommonIcons, theme } from '../../Design';
import { AppRoutes, Category } from '../../Model';
import { MoneyFormatter } from '../../Utils';

interface Props {
  count: number;
  total: number;
  percentage: number;
  category: Category;
}

const { ForwardChevron } = CommonIcons;

export const CategoryListItem: React.FC<Props> = ({ category, count, percentage, total }) => {
  const { handleNavigation } = useRouteContext();
  const { setCategoryOverview } = useTransactionsContext();

  const navigate = () => {
    setCategoryOverview(category);
    handleNavigation(AppRoutes.PaymentsOverviewCategory, category);
  };

  const color = Categories_Design[category].chartColor;
  return (
    <StyledCategoryListItem onClick={navigate} $iconColor={color}>
      <Row wrap={false} justify='space-between' align='middle'>
        <Col>
          <Row gutter={[8, 8]} wrap={false} align='middle'>
            <Col className='category-icon' style={{ height: 28 }}>
              {Categories_Design[category! as Category].icon}
            </Col>
            <Col flex='1'>
              <Typography.Text ellipsis style={{ maxWidth: 100, lineHeight: 1.25 }}>
                {category}
              </Typography.Text>{' '}
              <Typography.Text ellipsis style={{ fontSize: 12, maxWidth: 80 }} type='secondary'>
                | {count} {count < 2 ? 'spending' : 'spendings'}
              </Typography.Text>
              <div>
                <Progress style={{ width: window.innerWidth - 210 }} showInfo={false} strokeColor={color} percent={percentage} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col flex='120px'>
          <Row gutter={[12, 0]} justify='end' align='middle'>
            <Col>
              <Row style={{ width: 80 }} align='middle' justify='end'>
                <Col>
                  <Typography.Text ellipsis>{MoneyFormatter.format(total)}</Typography.Text>
                </Col>
                <Col>
                  <Tag style={{ margin: 0 }} color={'#F0F2FF'}>
                    <span style={{ color: theme.colors.primaryShades['primary-9'] }}>{percentage.toFixed(1)}%</span>
                  </Tag>
                </Col>
              </Row>
            </Col>
            <ForwardChevron color='rgba(0,0,0,0.1)' />
          </Row>
        </Col>
      </Row>
    </StyledCategoryListItem>
  );
};

const StyledCategoryListItem = styled.div<{ $iconColor?: string }>`
  .ant-row {
    height: 48px;
  }
  svg {
    font-size: 24px;
  }
  .category-icon svg {
    color: ${({ $iconColor }) => $iconColor};
    font-size: 28px;
  }
  font-size: 16px;
`;
