import { Col, Progress, Row, Typography } from 'antd';
import { Card } from 'antd-mobile';
import styled from 'styled-components';
import { Categories_Design } from '../../Design';
import { Category } from '../../Model';
import { MoneyFormatter } from '../../Utils';
import { ClickIndicator } from '../ClickIndicator';
import { PaymentByCategory } from '../Widgets';

interface Props {
  details: PaymentByCategory;
  percentage: number;
  category: Category;
}

export const CategoryCard: React.FC<Props> = ({ details, category, percentage }) => {
  const label = details.count > 1 ? 'spendings' : 'spending';
  return (
    <StyledCategoryCard $backgroundColor={Categories_Design[category].lightColor} $color={Categories_Design[category].chartColor}>
      <StyledTitleContainer>
        <StyledCategoryName>
          {Categories_Design[category].icon}
          <Typography.Text style={{ maxWidth: 100, color: Categories_Design[category].chartColor, marginLeft: 4 }} ellipsis>
            {category}
          </Typography.Text>
        </StyledCategoryName>
        <div style={{ transform: 'translateY(-6px)' }}>
          <ClickIndicator small backgroundColor={Categories_Design[category].lightColor} color={Categories_Design[category].chartColor} />
        </div>
      </StyledTitleContainer>
      <Row wrap={false} align='middle' gutter={[16, 12]} justify='start'>
        <Col>
          <Progress
            type='dashboard'
            strokeWidth={9}
            gapDegree={120}
            width={60}
            percent={Number(percentage.toFixed(1))}
            strokeColor={Categories_Design[category].chartColor}
            status='active'
          />
        </Col>
        <Col>
          <Typography.Text type='secondary'>
            {details.count} {label}
          </Typography.Text>
          <StyledMoneyText>{MoneyFormatter.format(details.total)}</StyledMoneyText>
        </Col>
      </Row>
    </StyledCategoryCard>
  );
};

const StyledCategoryCard = styled(Card)<{ $color: string; $backgroundColor: string }>`
  :active {
    background-color: ${({ $backgroundColor }) => $backgroundColor};
  }
  padding-top: 8px;
  color: ${({ $color }) => $color};
  .ant-progress-text {
    color: ${({ $color }) => $color};
    font-size: 14px;
  }
`;

const StyledCategoryName = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 28px;
  }
  font-weight: 500;
  margin-bottom: 8px;
`;

const StyledTitleContainer = styled.div`
  font-size: 18px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledMoneyText = styled(Typography.Text)`
  display: block;
  margin-top: 2px;
  font-size: 18px;
`;
