import styled from 'styled-components';

interface Props {}

export const CashflowDown: React.FC<Props> = () => {
  return (
    <StyledCashflowDown>
      <svg width='61' height='61' viewBox='0 0 61 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.0145111' y='0.541016' width='60' height='60' rx='16' fill='#FFF2F0' />
        <path
          d='M34.3858 23.0145C29.3201 21.6416 24.1401 21.3909 19.5653 24.5721C17.4137 26.0682 15.7658 28.1866 15.0809 30.7328C14.1265 30.9104 13.522 30.7825 13.2786 30.5279C12.9203 30.1532 13.2877 28.9051 14.3453 29.7543L15.506 28.3115C14.4582 27.47 13.4469 27.4479 12.6623 27.8447C11.2222 28.574 10.8051 30.6218 11.9442 31.8112C12.4861 32.3772 13.4444 32.7467 14.7597 32.6422C14.5853 35.0558 15.2481 37.7167 17.0204 40.4584L16.1229 41.8503L21.4531 46.0537L23.1896 44.7409C25.0213 45.2363 26.8733 45.4713 28.743 45.4759C30.6093 45.4811 32.4907 45.2539 34.3838 44.8302L36.0074 46.0626L41.1901 41.7801L40.523 40.532C40.9446 39.9307 41.4799 39.0318 41.732 38.3549H43.93L43.9246 30.1913L42.1327 30.1416C41.5205 28.5389 40.5849 27.1447 39.284 26.0192L38.1075 21.5769L34.3858 23.0145Z'
          fill='#C82A14'
        />
        <path
          d='M31.236 25.878C28.7626 25.2319 26.3341 25.3746 23.984 26.3997L24.668 27.9461C26.6578 27.0818 28.7062 26.9734 30.798 27.5178L31.236 25.878Z'
          fill='white'
        />
        <circle cx='36.5664' cy='31.2591' r='1.90464' fill='white' />
        <path
          d='M44.652 21.3273L47.207 13.1109C47.494 12.1879 48.7934 12.1687 49.1076 13.0828L51.8606 21.093C52.3873 22.6257 51.8927 24.3239 50.6254 25.3341L50.5736 25.3754C49.1509 26.5094 47.1197 26.4594 45.7545 25.2567C44.6446 24.2788 44.2128 22.7399 44.652 21.3273Z'
          fill='#C82A14'
        />
      </svg>
    </StyledCashflowDown>
  );
};

const StyledCashflowDown = styled.div``;
