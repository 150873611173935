import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { useScrollPosition } from '../Contexts';
import { Footer, FOOTER_HEIGHT, Header, MOBILE_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT_SHRINKED } from './Navigation';

interface Props extends React.PropsWithChildren {}

export const SCROLLABLE_SCREEN_CONTAINER_ID = 'screen-container';

export const ScreenContainer: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  const playgroundScreensRegex = /\/more\/playgrounds\/./;
  const paymentsOverviewScreenRegex = /\/payments\/overview\/category/;

  const isPlaygroundPage = location.pathname.match(playgroundScreensRegex);
  const isPaymentsOverviewPage = location.pathname.match(paymentsOverviewScreenRegex);

  const hideHeader = isPaymentsOverviewPage || isPlaygroundPage;

  return (
    <StyledScreenContainer>
      <AnimatePresence>
        {!hideHeader && !isPlaygroundPage && (
          <motion.div
            key={hideHeader + ''}
            transition={{ ease: 'easeIn', duration: 0.3, delay: 0 }}
            initial={{ y: -300, WebkitTransform: 'translateZ(0px)', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -300, opacity: 0 }}
          >
            <Header />
          </motion.div>
        )}
      </AnimatePresence>
      {children}
      <Footer />
    </StyledScreenContainer>
  );
};

const StyledScreenContainer = styled.div`
  width: 100%;
`;

const StyledScrollableScreenContainer = styled.div<{ $smallPadding?: boolean; $noTopPadding?: boolean }>`
  padding-top: ${({ $smallPadding, $noTopPadding }) =>
    $noTopPadding
      ? '0px'
      : `calc(${$smallPadding ? MOBILE_HEADER_HEIGHT_SHRINKED : MOBILE_HEADER_HEIGHT}px + env(safe-area-inset-top) + 16px)`};
  padding-bottom: ${() => `calc(${FOOTER_HEIGHT}px + env(safe-area-inset-bottom) + 128px)`};
  padding-right: calc(16px + env(safe-area-inset-right));
  padding-left: calc(16px + env(safe-area-inset-left));
  max-height: 100vh;
  overflow: auto;
  overflow-x: hidden;
`;

interface ScrollabeScreenContainerProps extends React.PropsWithChildren {
  smallPadding?: boolean;
  noTopPadding?: boolean;
}

export const ScrollableScreenContainer = ({ children, smallPadding, noTopPadding }: ScrollabeScreenContainerProps) => {
  const scrollableScreenRef = useRef<HTMLDivElement>(null);
  const { setScrollPosition } = useScrollPosition();
  useEffect(() => {
    const scrollScreen = scrollableScreenRef.current;
    const handleScroll = () => {
      setScrollPosition(scrollableScreenRef.current?.scrollTop || 0);
    };
    scrollScreen?.addEventListener('scroll', handleScroll);
    return () => {
      scrollScreen?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollableScreenRef, setScrollPosition]);

  return (
    <StyledScrollableScreenContainer $noTopPadding={noTopPadding} $smallPadding={smallPadding} ref={scrollableScreenRef}>
      {children}
    </StyledScrollableScreenContainer>
  );
};

export const getScrollContainerId = (pathName: string) => `${SCROLLABLE_SCREEN_CONTAINER_ID}${pathName}`;
