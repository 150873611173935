import { Drawer } from 'antd';
import styled from 'styled-components';

export const StyledEditDrawer = styled(Drawer)`
  .ant-drawer-header {
    display: flex;
    justify-content: center;
    border-bottom: none;
    padding-bottom: 0px;
    padding: 4px !important;
    padding-top: 8px !important;
  }
  .ant-drawer-content {
    border-radius: 32px 32px 0px 0px;
  }
  .ant-drawer-close {
    svg {
      font-size: 20px;
    }
  }
  .ant-drawer-body {
    padding: 0px 40px 0px 40px;
  }
  .ant-drawer-header-title {
    flex-wrap: nowrap;
  }
  .ant-drawer-title > div {
    display: flex;
    justify-content: center;
  }
`;
