import { Col, Row, Spin } from 'antd';
import styled from 'styled-components';
import { useScreenAnimation } from '../Hooks';
import { ScrollableScreenContainer, SwipeBackContainer, TransactionsList } from '../Components';
import { getPastTransactions } from '../Utils';
import { motion } from 'framer-motion';
import { useTransactionsContext } from '../Contexts';

export const SpendingsListPage: React.FC = () => {
  const { allTransactions, isLoading } = useTransactionsContext();
  const animation = useScreenAnimation();

  const pastPayments = getPastTransactions(allTransactions, 'Payment');

  return (
    <StyledAnimatedContainer {...animation}>
      <SwipeBackContainer>
        <ScrollableScreenContainer smallPadding>
          <StyledSpendingsListPage>
            {isLoading ? (
              <Spin />
            ) : (
              <Col span={24}>
                <TransactionsList transactions={pastPayments} />
              </Col>
            )}
          </StyledSpendingsListPage>
        </ScrollableScreenContainer>
      </SwipeBackContainer>
    </StyledAnimatedContainer>
  );
};

const StyledSpendingsListPage = styled(Row)`
  margin: 0px !important;
  padding: 0px 6px;
`;
const StyledAnimatedContainer = styled(motion.div)`
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
`;

export default SpendingsListPage;
