import { Typography } from 'antd';
import { Dialog, List, SwipeAction } from 'antd-mobile';
import { Action } from 'antd-mobile/es/components/swipe-action';
import { useState } from 'react';
import styled from 'styled-components';
import { usePlaygroundContext } from '../../Contexts';
import { CommonIcons, theme } from '../../Design';
import { PlaygroundTransaction } from '../../Model';
import { MoneyFormatter } from '../../Utils';
import { StyledSwipeActionList } from '../SwipeActionList';
import { PlaygroundTransactionCard } from './PlaygroundTransactionCard';

interface Props {
  transactions: PlaygroundTransaction[];
}

const { EditOutlineIcon, DeleteOutlineIcon } = CommonIcons;

export const PlaygroundTransactionsList: React.FC<Props> = ({ transactions }) => {
  const { deleteTransaction } = usePlaygroundContext();
  const [inEditId, setInEditId] = useState<null | string>(null);

  const resetInEditId = () => setInEditId(null);

  const handleOnEdit = (id: string) => setInEditId(id);

  const rightActions: Action[] = [
    {
      key: 'edit',
      text: <StyledIcon $color={theme.colors.primaryShades['primary-9']}>{<EditOutlineIcon />}</StyledIcon>,
      color: theme.colors.neutralPalette['gray-4'],
    },
    {
      key: 'delete',
      text: <StyledIcon>{<DeleteOutlineIcon />}</StyledIcon>,
      color: 'danger',
    },
  ];

  const handleOnDelete = (transaction: PlaygroundTransaction) => {
    const { name, amount } = transaction;
    Dialog.show({
      content: (
        <StyledDeleteModalContent>
          <div>
            <Typography.Title level={5}>Confirm delete</Typography.Title>
          </div>
          <StyledNameAndPrice>
            <Typography.Text>{name} -</Typography.Text>
            <Typography.Text>{MoneyFormatter.format(amount)}</Typography.Text>
          </StyledNameAndPrice>
        </StyledDeleteModalContent>
      ),
      closeOnMaskClick: true,
      closeOnAction: true,
      actions: [
        [
          { key: 'cancel', text: 'Cancel' },
          {
            key: 'delete',
            text: 'Delete',
            danger: true,
            onClick: () => {
              deleteTransaction(transaction);
            },
          },
        ],
      ],
    });
  };

  return (
    <StyledPlaygroundTransactionsList>
      <StyledSwipeActionList>
        {transactions.map(transaction => (
          <SwipeAction
            closeOnTouchOutside
            onAction={action => (action.key === 'delete' ? handleOnDelete(transaction) : handleOnEdit(transaction.id))}
            key={transaction.id}
            rightActions={rightActions}
          >
            <List.Item>
              <PlaygroundTransactionCard onEditClose={resetInEditId} isInEdit={transaction.id === inEditId} transaction={transaction} />
            </List.Item>
          </SwipeAction>
        ))}
      </StyledSwipeActionList>
    </StyledPlaygroundTransactionsList>
  );
};

const StyledPlaygroundTransactionsList = styled.div`
  -webkit-overflow-scrolling: none;
`;

const StyledIcon = styled.div<{ $color?: string }>`
  width: 45px;
  svg {
    font-size: 28px;
    transform: translateY(3px);
    fill: ${({ $color }) => $color || 'white'};
  }
`;

const StyledDeleteModalContent = styled.div`
  text-align: center;
`;

const StyledNameAndPrice = styled.div`
  span:nth-of-type(1) {
    margin-right: 4px;
  }
`;
