import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { PlaygroundTransaction } from '../Model/Transaction';
import {
  auth,
  db,
  playgroundTransactionsPath,
  deletePlaygroundTransaction,
  updatePlaygroundTransaction,
  addPlaygroundTransaction,
} from '../Api';
import { collection, limit, orderBy, query, where } from 'firebase/firestore';
import { useMutation } from 'react-query';
import { Toast } from 'antd-mobile';
import { useIonToast } from '@ionic/react';
import firebase from 'firebase/compat/app';

interface QueryConfig {
  type: 'Income' | 'Payment' | 'ALL';
  recurrent?: boolean;
  limit?: number;
}

export const usePlaygroundTransactions = (projectId: string, config: QueryConfig) => {
  const [playgroundTransactions, setTransactions] = useState<PlaygroundTransaction[]>([]);
  const [isLoading, setLoading] = useState(true);
  const uid = auth.currentUser?.uid;
  const [present] = useIonToast();

  const deleteTransactionMutation = useMutation({
    mutationFn: ({ transaction }: { transaction: PlaygroundTransaction }) => {
      return deletePlaygroundTransaction(transaction, uid!, projectId);
    },
    onMutate: () => {
      Toast.show({
        icon: 'loading',
        content: 'Loading',
      });
    },
    onSuccess: () => {
      Toast.show({
        icon: 'success',
        content: 'Deleted',
      });
    },
    onError: error => {
      present({
        message: `Error while deleting', ${(error as firebase.FirebaseError).message}`,
        duration: 3000,
        position: 'top',
        color: 'danger',
      });
    },
  });

  const deleteTransaction = (transaction: PlaygroundTransaction) => deleteTransactionMutation.mutate({ transaction });

  const editTransactionMutation = useMutation({
    mutationFn: ({ transaction }: { transaction: PlaygroundTransaction }) => {
      return updatePlaygroundTransaction(transaction, uid!, projectId);
    },
    onMutate: () => {
      Toast.show({
        icon: 'loading',
        content: 'Loading',
      });
    },
    onSuccess: () => {
      Toast.show({
        icon: 'success',
        content: 'Saved',
      });
    },
    onError: error => {
      present({
        message: `Error while saving', ${(error as firebase.FirebaseError).message}`,
        duration: 3000,
        position: 'top',
        color: 'danger',
      });
    },
  });

  const editTransaction = (transaction: PlaygroundTransaction) => editTransactionMutation.mutate({ transaction });

  const addTransactionMutation = useMutation({
    mutationFn: ({ transaction }: { transaction: PlaygroundTransaction }) => {
      return addPlaygroundTransaction(transaction, uid!, projectId);
    },
    onMutate: () => {
      Toast.show({
        icon: 'loading',
        content: 'Loading',
      });
    },
    onSuccess: () => {
      Toast.show({
        icon: 'success',
        content: 'Created',
      });
    },
    onError: error => {
      present({
        message: `Error while saving', ${(error as firebase.FirebaseError).message}`,
        duration: 3000,
        position: 'top',
        color: 'danger',
      });
    },
  });

  const addTransaction = (transaction: PlaygroundTransaction) => addTransactionMutation.mutate({ transaction });

  const tQuery =
    config.type === 'ALL'
      ? query(collection(db, playgroundTransactionsPath(uid!, projectId)), limit(config.limit || 999), orderBy('createdAt', 'desc'))
      : query(
          collection(db, playgroundTransactionsPath(uid!, projectId)),
          where('type', '==', config.type),
          limit(config.limit || 999),
          orderBy('createdAt', 'desc'),
        );

  useEffect(() => {
    const unsub = onSnapshot(
      query(tQuery),
      documents => {
        console.log(`%cFetched Playground ${config.type} Transactions`, 'color:green;font-weight:bold');
        let TransactionsData: Array<PlaygroundTransaction> = [];
        documents.forEach(doc => {
          TransactionsData.push({
            ...(doc.data() as PlaygroundTransaction),
            id: doc.id,
          });
        });
        setTransactions(TransactionsData);
        setLoading(false);
      },
      error => {
        console.error(error.message);
      },
    );
    return () => {
      unsub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.limit, config.type, uid]);

  return { playgroundTransactions, isLoading, deleteTransaction, editTransaction, addTransaction };
};
