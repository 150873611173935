import React, { useContext, useState } from 'react';
import { usePlaygroundTransactions } from '../Hooks/usePlaygroundTransactions';
import { Frequency, PlaygroundTransaction, Transaction } from '../Model';
import { useTransactionsContext } from './TransactionsContext';

export interface CashflowData {
  totalIncome: number;
  totalPayments: number;
}

interface PlaygroundContextProps {
  allTransactions: PlaygroundTransaction[];
  actualTransactions: PlaygroundTransaction[];
  playgroundTransactions: PlaygroundTransaction[];
  isLoading: boolean;
  actualData: CashflowData;
  projectedData: CashflowData;
  actualCashflow: number;
  projectedCashflow: number;
  addToInvisibleTransactions: (id: string) => void;
  removeFromInvisibleTransactions: (id: string) => void;
  invisibleTransactionsIds: string[];
  deleteTransaction: (transaction: PlaygroundTransaction) => void;
  editTransaction: (transaction: PlaygroundTransaction) => void;
  addTransaction: (transaction: PlaygroundTransaction) => void;
}

const PlaygroundContext = React.createContext<PlaygroundContextProps>({} as PlaygroundContextProps);

export const usePlaygroundContext = () => useContext(PlaygroundContext);

export const PlaygroundProvider = ({ children, id }: React.PropsWithChildren & { id: string }) => {
  const { recurrentTransactions, isLoading: isLoadingRecurrentTransactions } = useTransactionsContext();
  const {
    playgroundTransactions,
    isLoading: isLoadingPlaygroundTransactions,
    deleteTransaction,
    editTransaction,
    addTransaction,
  } = usePlaygroundTransactions(id, { type: 'ALL' });
  const [invisibleTransactions, setInvisibleTransactions] = useState<string[]>([]);

  const addToInvisibleTransactions = (id: string) => setInvisibleTransactions(prev => [...prev, id]);
  const removeFromInvisibleTransactions = (id: string) =>
    setInvisibleTransactions(prev => prev.filter(transactionId => transactionId !== id));

  const allTransactions = [...playgroundTransactions, ...recurrentTransactions];
  const isLoading = isLoadingPlaygroundTransactions || isLoadingRecurrentTransactions;
  const actualData = getTransactionsData(recurrentTransactions);
  const projectedData = getTransactionsData(playgroundTransactions as any, invisibleTransactions);
  const actualCashflow = getCashflow(actualData);
  const projectedCashflow = getCashflow(projectedData);

  const value: PlaygroundContextProps = {
    allTransactions,
    playgroundTransactions,
    actualTransactions: recurrentTransactions,
    isLoading,
    actualData,
    projectedData,
    actualCashflow,
    projectedCashflow,
    addToInvisibleTransactions,
    removeFromInvisibleTransactions,
    invisibleTransactionsIds: invisibleTransactions,
    deleteTransaction,
    editTransaction,
    addTransaction,
  };

  return <PlaygroundContext.Provider value={value}>{children}</PlaygroundContext.Provider>;
};

const getTransactionsData = (transactions: Transaction[], ignoredTransactions?: string[]) =>
  transactions.reduce(
    (acc, curr) => {
      if (!ignoredTransactions?.includes(curr.id)) {
        if (curr.type === 'Income') {
          if (curr.recurrent) {
            return { ...acc, totalIncome: acc['totalIncome'] + curr.amount * OCCURENCES_MULTIPLIER[curr.frequency!] };
          }
          return { ...acc, totalIncome: acc['totalIncome'] + curr.amount };
        } else {
          if (curr.recurrent) {
            return { ...acc, totalPayments: acc['totalPayments'] + curr.amount * OCCURENCES_MULTIPLIER[curr.frequency!] };
          }
          return { ...acc, totalPayments: acc['totalPayments'] + curr.amount };
        }
      }
      return acc;
    },
    {
      totalIncome: 0,
      totalPayments: 0,
    },
  );

const getCashflow = (data: CashflowData) => data.totalIncome - data.totalPayments;

const OCCURENCES_MULTIPLIER: Record<Frequency, number> = {
  'BI-WEEKLY': 2,
  MONTHLY: 1,
  WEEKLY: 4,
  ANNUALLY: 1,
  'ONE TIME': 1,
};
